/* 假装这是一个数据库 */

// 解决方案模块数据
export const SOLUTIONS = [
  {
    name: 'building',
    // 顶部banner模块数据
    bannerData: {
      label: '智慧建筑解决方案',
      descList: [
        { content: '结合网络控制等方面的优势，融合了捍御者核心技术和产品功能' },
        { content: '提供给客户一整套相对完善的建筑智能化的解决方案。' }
      ],
      bannerImg: require('@/assets/solution/building/banner.png')
    },
    // 解决方案2模块数据 -- 特异性处理
    planData2: {
      planIntro1:
        '捍御者公司面向公共建筑智能化领域，摒弃了多套子系统分别采购集成的传统思路，引入了互联网、通信网、物联网、大数据的技术框架，继承优化了当前主流建筑智能化的功能，提供完整的自主研发生产的软硬件平台一体的智能化解决方案，有机联动，实现对建筑统一监控、维护和自动化控制，力求使整个建筑设备处在最佳的工作状态',
      planIntro2:
        '捍御者建筑智能化系统以Turbo-BOSS智能建筑管理平台为核心，简化了前端硬件设备的种类，功能集中以软件形态的虚拟化方式体现，充分表达了SDB（软件定义建筑）的思想，实现了BFV（建筑功能虚拟化）。解决方案以业务运营驱动为着眼点，提出了“云管端”的先进系统架构；同时关注轻量化的智能办公场景，力争达到整个智能化系统“易用好用”的目标。',
      planIntro3:
        '解决方案提供了软硬件/控制/业务应用架构的稳定性；提供了从网络通信等基础设施、智能化数据、平台控制和业务应用各个层面提供高规格的安全性；深入定制节能场景，实现建筑能效比最大化，从而实现经济节能。捍御者建筑智能化系统全面覆盖暖通、冷源、给排水、配电监控、能源管理、电梯控制、巡查对讲、视频安防、入侵报警、停车及反向引导、出入口控制、信息发布、访客管理、智能照明、远程抄表、网络管理、电梯多方通话、会议系统、广播系统等各专业功能。',
      planName: '智慧建筑解决方案',
      planImage: require('@/assets/solution/building/plan.png')
    },
    // 核心功能模块数据
    functionData: [
      {
        title: '场景一',
        icon: 'iconweibiaoti-24',
        childrenList: [
          {
            title: '空调管理',
            svg: 'parking3',
            content:
              '根据不同空调特点，采用总风量控制+变静压控制的混合控制法，风速、区域温度自动调节，打破国外软件垄断'
          },
          {
            title: '送排风',
            svg: 'building2',
            content:
              '采用定时程序控制，累计运行时间，通过前端传感器按需使用。实施启停、运行状态、故障报警、消防联动监控'
          },
          {
            title: '给排水',
            svg: 'store6',
            content:
              '采用恒压变频给水系统控制，结合PID算法，保证水泵再23-35Hz以上状态上工作；通过液位仪监测实现波峰抑制，波谷用电排水'
          },
          {
            title: '供冷管理',
            svg: 'metro2',
            content:
              '实现冷却水系统的监控，冷冻水系统的监控，冷水机组启停的连锁控制和群控的序列策略，能量调节及水系统控制'
          }
        ]
      },
      {
        title: '场景二',
        icon: 'iconshiliangzhinengduixiang71',
        childrenList: [
          {
            title: '电梯监管',
            svg: 'building7',
            content:
              '根据人员情况，合理投入电梯的运行台数，监控电梯启停/运行状态/电梯门状态/楼层指示/应急报警等，火警时电梯消防可靠手动控制'
          },
          {
            title: '配电监控',
            svg: 'store3',
            content:
              '配电网多维度状态量监测与智能控制，对能耗及设备安全状况进行实时采集及告警，为电路健康状态和节电分析提供实时挖据数据'
          },
          {
            title: '智能网络',
            svg: 'building1',
            content:
              '搭建楼宇智能化网络与物业管理网络，根据物理位置、业务和安全域搭建高速传输有线网络、WIFI无线网络及低速灵活传输的物联网络'
          },
          {
            title: '信息发布',
            svg: 'building8',
            content:
              '结合自主视频发布，采用自研标准化接入终端盒子统一编辑、控制宣传展示信息，适配前端各种形式的显示屏幕、水牌及其它输出设备'
          }
        ]
      },
      {
        title: '场景三',
        icon: 'iconguanli',
        childrenList: [
          {
            title: '入侵警报',
            svg: 'monitor2',
            content:
              '为残疾人卫生间、特殊防护部位、重要保密部位等楼宇特殊场所提供手报告警装置'
          },
          {
            title: '远程抄表',
            svg: 'building4',
            content:
              '根据不同水电热气仪表类型，选择有线、无线（Lora）接入数据采集，统一管控分析'
          },
          {
            title: '智能照明',
            svg: 'metro5',
            content:
              '根据白天/夜晚/无人场景自动设置照明场景，感应照明和调光，灵活设置周期或预约开关照明装置的时间，实现能源节约'
          },
          {
            title: '会议系统',
            svg: 'store8',
            content:
              '对办公会议场景的照明、投影、窗帘、空调等的智能控制，人脸签到，远程视频会议接入，规划实现语音自动转换为会议纪要功能'
          }
        ]
      },
      {
        title: '场景四',
        icon: 'iconweibiaoti-201',
        childrenList: [
          {
            title: '视频安防',
            svg: 'store7',
            content:
              '内置视频处理平台适配多格式视频文件，自产摄像头采用RTMP协议、H.265+编解码，压缩视频带宽（视频子码流）在100Kbps以下，存储容量降低3/4'
          },
          {
            title: '视频分析',
            svg: 'metro1',
            content:
              '基于神经网络“人体骨架”算法实现视频内人体动作识别，实现区域监控/人数统计/人群聚集/人员摔倒/斗殴/电梯逆行/可疑物品遗留等场景识别和动作识别'
          },
          {
            title: '出入口管理',
            svg: 'store1',
            content:
              '人脸识别/动态二维码/IC卡出入通道管理，VIP通道与电梯管理，访客手机邀请码预约，闸口呼梯和电梯预约，视频联动监控出入人员'
          },
          {
            title: '停车管理',
            svg: 'safety3',
            content:
              '无人值守管理，正向引导寻找车位，私有云系统处理车牌和费用，手机在线小程序缴费，支持临时车/无牌车/优惠车/长租车等多种方式'
          }
        ]
      },
      {
        title: '场景五',
        icon: 'iconchangjing',
        childrenList: [
          {
            title: '微信自助',
            svg: 'building5',
            content:
              '手机微信卡包方式实现楼宇出入口通行证、停车缴费、反向寻车、访客邀请等便捷操作，减少住用被打扰，同时减少建筑管理人员人数'
          },
          {
            title: '反向寻车',
            svg: 'building6',
            content:
              '通过自产单、双头车位摄像机拍照，确定反向寻车位置，通过手机扫码起始点快速获取精准寻车路径'
          }
        ]
      }
    ],
    // 交付价值模块数据
    payValueData: [
      {
        title: '一套平台，全网管理',
        img: require('@/assets/solution/building/pay-value1.png'),
        content: {
          text1:
            '园区、建筑群或单体建筑的智能化，改分散为统一，一套平台实现整体管控',
          text2: '软件平台耦合性好，可接入自产设备和第三方系统设备',
          text3:
            '云平台架构使得系统具备很好的扩展性，可实现被管建筑规模的持续扩大和功能范围的拓宽'
        }
      },
      {
        title: '安全可靠，数据保密',
        img: require('@/assets/solution/building/pay-value2.png'),
        content: {
          text1:
            '智能化平台系统采用经过验证的成熟技术开发，多个实际案例验证，运行稳定',
          text2:
            '采用云计算模式的负载均衡、高可用及分布式应用技术，搭建统一服务池，实现系统高健壮性及一致性，消除单点故障',
          text3: '所有设备均经过专业机构认证检测，使用寿命延长，可靠耐用',
          text4:
            '系统独立部署在智能建筑内部专属私有云机房，所有数据不经过第三方云平台保证门禁数据、访客数据、停车数据、控制数据等私有保存，不泄密'
        }
      },
      {
        title: '运行稳定，易用性好',
        img: require('@/assets/solution/building/pay-value3.png'),
        content: {
          text1:
            '所有智能化设备均在后台监视之下，避免出现故障点，保证平台长期稳定运行',
          text2: '统一平台监控操作，分权分域，系统关联性操作和数据分析能力更强',
          text3:
            '提倡自助使用理念，访客办理只需1-2分钟，门禁/道闸实现“0”等待，减少与工作人员打交道时间，对住用人影响更小',
          text4:
            '后端监控平台操作界面菜单统一，功能集中直观；前端使用人手机操作，管理人和住用人均使用微信小程序，便捷易用'
        }
      },
      {
        title: '节省建设投资10%以上',
        img: require('@/assets/solution/building/pay-value4.png'),
        content: {
          text1:
            '大量节省后台服务器数量、存储空间和网络带宽，节省机房耗电和机房空间',
          text2: '压缩前端/接入层设备采购种类、数量',
          text3:
            '建筑管理人、住用人使用自有智能手机操作，无需专用对讲机、巡更棒、访客终端、手持式刷卡器等其他设备',
          text4:
            '设备种类数量减少，同时广泛应用无线化连接，安装需要的辅材辅料明显减少，施工安装调试便捷快速，施工周期降低10%'
        }
      },
      {
        title: '提升效率，节省运维成本',
        img: require('@/assets/solution/building/pay-value5.png'),
        content: {
          text1:
            '统一系统跨专业协作，工作效率提升5%，设备控制效率提升10%，安保响应速度时间降低到原来的30%',
          text2:
            '各个功能子系统相关数据联动，管理效率可提升20%，同时设备寿命因减少不必要启停运行而增加10%',
          text3:
            '将Al分析引入视频监控，保安人员数量可减少15%；自助式访客，迎宾人员数量可减少8%；智能化设备管控，运维人员数量可减少30%'
        }
      },
      {
        title: '绿色节能',
        img: require('@/assets/solution/building/pay-value6.png'),
        content: {
          text1:
            '结合互联网物联网、Al、大数据等技术，构建能源调度、设备运行、环境监测、人流密度等多维分析模型，以数据驱动场景定制实现绿色节能',
          text2:
            '通过节能规划、算法优化、验证迭代多次反复，将系统自动调节到建筑正常运行下的最优节能区域',
          text3:
            '针对空调、电梯、照明等“用电大户”，使用节能装置和储能设备，辅助配电监控，优化设备调控算法，各专业联动，可比传统方案节能10%~15%'
        }
      }
    ],
    // 六大统一模块数据
    unifyData: [
      {
        title: '统一采集',
        img: require('@/assets/solution/building/pay-value7.png'),
        content: '（数据采集平台）'
      },
      {
        title: '统一资源',
        img: require('@/assets/solution/building/pay-value8.png'),
        content: '（资源管理平台）'
      },
      {
        title: '统一告警',
        img: require('@/assets/solution/building/pay-value9.png'),
        content: '（告警管理平台）'
      },
      {
        title: '统一流程调度',
        img: require('@/assets/solution/building/pay-value10.png'),
        content: '（工作调度协同平台）'
      },
      {
        title: '统一鉴权',
        img: require('@/assets/solution/building/pay-value11.png'),
        content: '（分权分域平台）'
      },
      {
        title: '统一呈现',
        img: require('@/assets/solution/building/pay-value12.png'),
        content: '（综合呈现平台）'
      }
    ],
    // 优势特点模块数据
    goodnessData: [
      {
        title: '私有云下的虚拟化平台',
        icon: 'iconbanshou',
        content:
          '云平台引入微服务和容器技术，不同场景中的功能封装成多个独立运行的软件容器以微服务的方式虚拟化运行，实现建筑功能虚拟化（BFV）云平台采用实时数据库和MQ软件队列大数据高并发处理，三台中等配置服务器即可实现每天7000+设备的8000万+条数据的顺畅处理，执行效率提升了数倍'
      },
      {
        title: '架构先进，跨界赋能',
        icon: 'iconloupanshuju',
        content:
          '跨界引入互联网、通信网、物联网框架和技术，改变了智能建筑多年来缓慢变革的节奏，赋予智能建筑新的理念，提升智能建筑技术水平20年改变建筑智能化分别采购集成的传统方式，面向场景，以一体化融合平台统一贯通人、设备、环境、空间、时间、任务各种数据以IP网络为基础，建筑智能化各个子系统融合为一个完整的网络体系，管理容量可平滑扩充'
      },
      {
        title: '专门针对建筑智能化的自研设备',
        icon: 'iconzongheguanli',
        content:
          '“接入采集及控制器DCC”标准化统一接入前端设备，减少设备类型数量“安防摄像机”增加热点功能，实现无线接入能力；采用H.265+码流编码解码，节省传输带宽和存储带宽在50%以上；无线式“反向引导双摄像头”实现“一摄头看六车位”“无线/CAN/485门禁一体机”减少单/双/四门禁控制器设备“4G对讲机”取代传统对讲，去专网“信息发布设备”适配各种发布屏幕'
      },
      {
        title: '先进的数据模型和业务引擎',
        icon: 'icondiannao',
        content:
          '资源动态建模：针对空间、设备、表单、数据、业务等规范化资源数据模型的统一共享和统一分析，方便系统操作联动流程引擎、表单设计器：流程标准化设计，避免了工作遗漏和信息不对称调度引擎：分布式的工作流任务调度规则引擎：业务决策规则化、组件化，可预定义可视化引擎：提供可视化工具，智能建筑数据、状态的统一的直观视图'
      },
      {
        title: '物联网与人工智能技术应用',
        icon: 'iconjiankong1',
        content:
          '物联网接入尽量采用无线化连接，NB-loT、Lora、Wl-Fl、Zigbee分别在不同场景下得到有效应用基于神经网络“人体骨架”算法实现人体动作的实时视频分析，数据处理量压缩到原来的百分之一，可实现区域监控、人数统计、人群聚集/摔倒/斗殴、电梯逆行、可疑物品遗留等场景识别和人员动作识别自有算法研发的车牌识别、车位识别技术自有算法研发的人脸识别技术'
      },
      {
        title: '基于特征的算法引擎',
        icon: 'iconche',
        content:
          '统一管控建筑智能化的业务算法，提供了全新的算法引擎模块，在软件容器中部署多种算法框架和相应的业务算法实现算法的参数输入/结果输出、算法间的统一调度、路由控制、执行等功能算法框架下提供了音频处理算法、视频编解码算法、车牌识别算法、人脸识别算法、人员行为识别算法、二维码生成和解析算法、交通事件算法、交通参数算法、空调群控算法、VAV末端控制算法、配变电遥调算法、PID收敛算法等多种捍御者独有的创新或改良的算法'
      }
    ]
  },
  {
    name: 'safety',
    // 顶部banner模块数据
    bannerData: {
      label: '封闭空间融合安防通信系统解决方案',
      descList: [
        { content: '针对封闭空间的安防、监控、通信管理的特征' },
        { content: '建立统一的服务中间件，标准化接口，融合集成。' }
      ],
      bannerImg: require('@/assets/solution/safety/banner.png')
    },
    // 方案架构模块数据
    planData: {
      planIntro:
        '封闭空间融合安防通信系统，针对封闭空间的安防、监控、通信管理的特征，通过系统弱电和软件建设，将封闭空间内设备运行信息、环境信息、安全防范信息、视频图像、预警报警信号、管理信息等内容及总线进行融合，建立统一的服务中间件，标准化接口，融合集成；结合IP组网、无线自组网、电力自组网、光纤环网等优势技术提供综合的安防通信监控服务。',
      customValue:
        '高效智能，照明控制与人体识别雷达联动，发现进入人员，自动开灯，节能环保；高度融合，融合通信与设备功能融合模式，运营管理成本低，管理水平与管理质量得到有力保障；运维高效，设备通过高集成度的功能，实现设备自管理，自发现，自告警；发现问题及时互通与联动控制，及时解决问题。',
      planName: '封闭空间融合安防通信系统',
      planImage: require('@/assets/solution/safety/plan.png')
    },
    // 核心功能模块数据
    functionData: [
      {
        title: '网络通信',
        icon: 'iconweibiaoti-27',
        childrenList: [
          {
            title: '通信服务',
            svg: 'safety1',
            content:
              '提供封闭空间内网络通信服务，支撑封闭空间内无线网络接入、应急通信、广播系统等业务需求场景，支撑封闭空间内通信、安防和监控数据通过网络上传到后台监控中心。'
          }
        ]
      },
      {
        title: '安全防范',
        icon: 'iconweibiaoti-201',
        childrenList: [
          {
            title: '人脸识别',
            svg: 'safety2',
            content:
              '通过人体感应芯片智能感应人体的接近，通过ZIGBEE识别卡，识别人员身份，通过摄像头，利用AI技术，进行面部识别；判断为入侵的，发出告警信息。'
          },
          {
            title: '实时定位',
            svg: 'safety3',
            content:
              '定位：分为人员入侵的定位和工作人员定位。入侵定位采用微波阵列雷达实现5米精度内准确的舱位定位；工作人员定位采用zigbee三角定位实现米级准确定位。定位数据通过智能灯管上报到ACU及后台，支持进行人员移动轨迹的回放。'
          }
        ]
      },
      {
        title: '环境监控',
        icon: 'iconweibiaoti-261',
        childrenList: [
          {
            title: '监控数据',
            svg: 'safety4',
            content:
              '提供封闭空间内环境监控数据采集能力，统一采集温度、湿度、氧气浓度、甲烷浓度、硫化氢浓度，进行风机、水泵等控制。通过门限设置，对环境相关指标进行预警和告警。'
          }
        ]
      },
      {
        title: '运维管理',
        icon: 'iconguanli',
        childrenList: [
          {
            title: '监控数据',
            svg: 'safety5',
            content:
              '提供综合的运维管理支撑平台，根据运维角色定义，提供相应的运维工具，提供封闭空间内巡检APP，支持人员进行巡检、巡更、日常维修保养任务执行，并在APP中提供协同工作能力，通过实时视频通话、语音、文字等即时通信，支撑封闭空间内工作人员及时得到后台专家的支撑。'
          }
        ]
      }
    ],
    // 优势特点模块数据
    goodnessData: [
      {
        title: '产品高度融合',
        icon: 'iconshiliangzhinengduixiang27',
        content:
          '时间上先后产生、结构上处于不同层次中相互渗透、相互包含、融合发展的产业形态与经济增长方式，实现产业升级的知识运营增长方式、发展模式与企业经营模式。'
      },
      {
        title: '高可靠性网络',
        icon: 'icondiannao',
        content:
          '网络是关联系统的一种特殊情形，网络可靠度主要研究网络可靠度的算法，以及算法的复杂性.网络可靠性成了20世纪80年代以来活跃的研究课题'
      },
      {
        title: '施工简单快捷',
        icon: 'iconshigong',
        content:
          '施工是指工程建设实施阶段的生产活动，是各类建筑物的建造过程，也可以说是把设计图纸上的各种线条，在指定的地点，变成实物的过程。'
      },
      {
        title: '维护简单方便',
        icon: 'iconbanshou',
        content:
          '在网络正常运行的情况下，对网络基础设施的管理主要包括，确保网络传输的正常，掌握公司或者网吧主干设备的配置及配置参数变更情况，备份各个设备的配置文件。'
      },
      {
        title: '终端智能控制',
        icon: 'icontab_zhongduan',
        content:
          '智能控制终端在工矿企业和危险区域.....应用比较广泛，它提高了传输性能，覆盖面广的等特点，为我们安监行业的所有用户提供了更快，更稳定的第一手资料。'
      }
    ],
    // 五位一体模块数据
    musterData: {
      img: require('@/assets/solution/safety/muster.png'),
      text:
        '捍御者自主研发，创造性地基于电力线载波技术，利用综合管廊中的电力线回路搭建有线宽带局域网，通过智能接入点设备实现安防、通信、视频监控、巡更巡检、广播、智能疏散标志标识等与人相关的各种功能，通过协议转换器设备就近接入与环境、设备数据采集和联动控制的各项功能，通过接入点控制器实现防火段内的网络通信控制、数据缓存和数据处理、设备联动等功能，该产品完全遵循住建部相关国家标准，针对管廊的环境特点，将网络、通信、安防、监控、运维五大功能融为一体。'
    },
    // 简而不凡模块数据
    formationData: {
      title: '简而不凡',
      childrenList: [
        {
          title: '专业化',
          img: require('@/assets/solution/safety/simple1.png'),
          content: '完全针对管廊特点专门开发定制生产'
        },
        {
          title: '集约化',
          img: require('@/assets/solution/safety/simple2.png'),
          content: '利用管廊过道顶部空间，简化设备种类，降低安装工时'
        },
        {
          title: '一体化',
          img: require('@/assets/solution/safety/simple3.png'),
          content: '替代传统通用设备，适应管廊特殊环境一体化专用设备'
        },
        {
          title: '自动化',
          img: require('@/assets/solution/safety/simple4.png'),
          content:
            '采用规则引擎、告警关联、相关性分析技术，提升自动化运维能力，减少人工参与'
        },
        {
          title: '数字化',
          img: require('@/assets/solution/safety/simple5.png'),
          content: '改变传统工业控制技术、采用IP化、云计算、微服务技术'
        },
        {
          title: '智能化',
          img: require('@/assets/solution/safety/simple6.png'),
          content:
            '设备状态可管可控，基于人脸识别、机器学习和数据挖掘，智能提升'
        }
      ]
    },
    // 技术特点模块数据
    technologyData: [
      {
        title: '针对管廊，专业设计',
        img: require('@/assets/solution/safety/technology1.png'),
        content: {
          text1: '管廊专用设备，硬件整合，功能融合',
          text2: '考虑管廊特殊环境，设备防尘防水能力强',
          text3: '防护等级IP65或以上，针对防爆舱单独设计'
        }
      },
      {
        title: '自主研发，技术领先',
        img: require('@/assets/solution/safety/technology2.png'),
        content: {
          text1: '自有通信协议栈、路由协议、自组网协议',
          text2: '软件模块基于Docker实现微服务架构',
          text3: '采用Golang、时序数据库，分布式消息总线实时并发',
          text4: '基于DevOps思想，软件云化部署，快速切换，一键升级',
          text5: '入侵检测、身份识别功能结合AI深度学习、智能识别',
          text6:
            '三重网络覆盖（电力线载波1200M有线局域网+300M无线WiFI+150K物联网Zigbee）'
        }
      },
      {
        title: '实施快捷，维护方便',
        img: require('@/assets/solution/safety/technology3.png'),
        content: {
          text1: '安装方式简单，系统调试容易，施工省工省料',
          text2: '设备提供自管理功能，自动实现故障定位，更换方便',
          text3: '提供各种标准纵向，横向接口，可能扩展能力强'
        }
      }
    ]
  },
  {
    name: 'store',
    // 顶部banner模块数据
    bannerData: {
      label: '智能仓储管理解决方案',
      descList: [
        { content: '通过物联网技术对货物进行生命周期管理' },
        { content: '应用3D技术实现库房及货架三维可视化' }
      ],
      bannerImg: require('@/assets/solution/store/banner.png')
    },
    // 方案架构模块数据
    planData: {
      planIntro:
        '贯穿整个仓储管理业务流程，涵盖货物入库、质检、上架、维修保养、移库、出库等业务流程。通过物联网RFID技术对货物进行全生命周期管理，应用3D技术实现库房及货架三维可视化；引入guvnor、planner、jBPM等规则和流程引擎技术，实现任务流程监控可视化以及订单自动拆单、合单；通过可视化界面进行业务规则定制与修改，业务规则维护简单快捷。',
      customValue:
        '智能仓储，通过对货物重量、体积、出入库频度、人员安排、相关资源当前占用情况等因素进行综合评估测算，根据货位占用情况、容积、承重等指标，科学合理的分配货位、人员及其他资源，自动生成生产工单；货物智能管理，实现自动盘点，把库管员从繁重的重复性劳动中解放出来，有更多的时间研究业务，学习业务知识；知识库系统固化库管员的知识，实现知识的积累与传递，将人找人的学习模式变成人找知识，人找系统的高效模式。',
      planName: '智能仓储管理方案',
      planImage: require('@/assets/solution/store/plan.png')
    },
    // 核心功能模块数据
    functionData: [
      {
        title: '可视化管理',
        icon: 'iconshiliangzhinengduixiang1',
        childrenList: [
          {
            title: '信息可视化',
            svg: 'store1',
            content:
              '实现库房三维可视化，以管理信息可视化为目标，以仓库、货位可视化为基础，将不可见的仓储管理信息可视化，以直观的看到仓储管理成效'
          },
          {
            title: '实时统计',
            svg: 'store2',
            content:
              '实时统计库房和货架的空间利用率、承重情况等，更合理的分配库房资源。'
          }
        ]
      },
      {
        title: '待办管理',
        icon: 'iconshiliangzhinengduixiang3',
        childrenList: [
          {
            title: '便捷办理',
            svg: 'store3',
            content:
              '应用大屏技术，将库管员的日常工作、待办任务等实时、清晰展现，便于对库管员日常工作的管理和督办。'
          }
        ]
      },
      {
        title: '自动盘点',
        icon: 'iconshiliangzhinengduixiang5',
        childrenList: [
          {
            title: '自动转变',
            svg: 'store4',
            content:
              '采用轨道机结合RFID标签卡，设置定时任务，实现自动盘点，使盘点工作由定期任务向日常化工作转变'
          },
          {
            title: '提高效率',
            svg: 'store5',
            content:
              '减轻库管员的工作量，又能更准确及时的发现货物丢失、放错位置等问题，从而便于库管员准确找到货物，提高货物的出库效率。'
          }
        ]
      },
      {
        title: '流程监控',
        icon: 'iconshiliangzhinengduixiang7',
        childrenList: [
          {
            title: '监控管理',
            svg: 'store6',
            content:
              '在货物流转关键节点安装监控设备，对货物流向进行监控，对出库、保养、入库等流程进行全程监控，实现货物的全生命周期监控管理。'
          }
        ]
      },
      {
        title: '统计分析',
        icon: 'iconshiliangzhinengduixiang9',
        childrenList: [
          {
            title: '多维度分析',
            svg: 'store7',
            content:
              '多维度统计分析能力，图形化可拖拽的统计工具，自定义条件进行查询统计分析；多种导出文件格式，支持EXCEL、WORD、XML等文件格式。'
          }
        ]
      },
      {
        title: '业务规则',
        icon: 'iconshiliangzhinengduixiang11',
        childrenList: [
          {
            title: '计划引擎',
            svg: 'store8',
            content:
              '引入业务规则引擎和计划引擎，实现业务规则的可视化定制、修改、删除，并根据规则将入库、出库、维护保养等作业。'
          },
          {
            title: '优化调整',
            svg: 'store9',
            content:
              '进行生产工单的自动拆分、合并，实现了将业务逻辑从硬代码中解耦，无需修改和升级程序，业务人员通过图形化界面对业务规则进行优化调整，操作简便快捷。'
          }
        ]
      }
    ],
    // 优势特点模块数据
    goodnessData: [
      {
        title: '出入库自动检测',
        icon: 'iconhuowuguanli',
        content:
          '自动化仓库出/入库管理流程是一种用来完成立体仓库在生产线与平面仓库（或其他供料系统）之间运送物料的任务流程。'
      },
      {
        title: '货物全生命周期管理',
        icon: 'iconshiliangzhinengduixiang27',
        content:
          '长期点检，又称周期管理，是为了解设备磨损情况和劣化倾向对设备进行的详细检查。长期检查周期一般在1个月以上。'
      },
      {
        title: '轨道电机、无轨机器人等多样化自动盘点',
        icon: 'iconweibiaoti-20',
        content:
          '自动盘点，即仓库的全面盘点，是指在一定时间内，一般是每季度、每半年或年终财务结算前进行一次全面的盘点。'
      },
      {
        title: '货位管理智能化',
        icon: 'iconshiliangzhinengduixiang22',
        content:
          '货位管理是指对仓库存放物资的货位进行的规划、分配、使用、调整等项工作。在物流中心依据一定的规则，以每个类别货品在物流中心存放位置为基础进行重新的定义。'
      },
      {
        title: '订单智能管理',
        icon: 'iconshiliangzhinengduixiang23',
        content:
          '客户关系管理的有效延伸，能更好的把个性化、差异化服务有机的融入到客户管理中去，能推动经济效益和客户满意度的提升，货源安排做到公开透明，产品能更加满足消费者的需要。'
      }
    ]
  },
  {
    name: 'metro',
    // 顶部banner模块数据
    bannerData: {
      label: '地铁综合监控系统解决方案',
      descList: [
        { content: '捍御者地铁综合监控系统在继承了当前监控调度功能得基础上' },
        {
          content:
            '结合公司在网络通信上的优势，提供完整得地铁轨道交通的解决方案'
        }
      ],
      bannerImg: require('@/assets/solution/metro/banner.png')
    },
    // 方案架构模块数据
    planData: {
      planIntro:
        '地铁综合监控系统是城市轨道交通自动化调度管理的重要工具，在全世界范围的轨道交通中得到了广泛的应用。捍御者地铁综合监控系统在继承了当前监控调度系统的功能特点的基础上，遵循《城市轨道交通综合监控系统工程技术标准》等国家标准，结合公司在网络通信、监控告警处理和异构设备远程控制等方面的优势，融合了捍御者核心技术和产品功能，提供给客户完整的地铁轨道交通的综合监控解决方案。 捍御者综合监控系统，具备跨平台部署的能力，采用基于多域的分布式结构，扩展和维护方便，提供强大的二次开发工具，场景配置灵活。所包含的子系统多、监控对象多、数据量庞大、涉及的专业面广。综合监控系统深度集成了电力监控、环境与设备监控、时钟系统等关键系统，实现了对通号、门禁、无线通信、站台门、乘客信息、自动售检票、火灾报警、电火监控、闭路电视、广播等子系统的互联，做到了“一个平台、整体维护、全线应用”。',
      customValue:
        '捍御者综合监控系统通过相关各个系统的集成或互联，采用了统一的系统结构、通信协议和软硬件平台，实现统一的人机界面，提供高度资源共享的信息平台，为地铁的运营管理和维护提供了强大的自动化管理手段，增强轨道交通智能调度的统一性、灵活性和系统间的协调运作能力，提高轨道交通运营的整体服务水平。',
      planName: '地铁综合监控系统',
      planImage: require('@/assets/solution/metro/plan.png')
    },
    // 核心功能模块数据
    functionData: [
      {
        title: '自主研发',
        icon: 'iconyanfa',
        childrenList: [
          {
            title: '技术先进',
            svg: 'metro1',
            content:
              '捍御者自主研发的综合监控系统，可以说代表了当今先进的轨道交通监控调度的自动化水平。通过对国内外轨道交通运营需求及其发展趋势的不断跟踪与分析，充分利用近年来涌现的并被证明是稳定可靠的信息技术、开发技术以及捍御者针对监控软件平台的积累，使综合监控系统的先进性、稳定性、开放性和灵活性得到了高度的统一，功能丰富，性能高效，界面易用，系统品质有保证。'
          }
        ]
      },
      {
        title: '联动控制',
        icon: 'iconkongzhitai',
        childrenList: [
          {
            title: '统一管理',
            svg: 'metro2',
            content:
              '轨道交通的线路长，人员活动区域大，相关的控制管理子系统非常多，综合监控系统的统一管控，具备流程与数据的深度学习能力'
          },
          {
            title: '优化设备',
            svg: 'metro3',
            content:
              '设置和优化相关事件或设备的关联关系，可以实现轨道交通针对电力、环境、设备、网络通信、事件信息、运营信息、防火、应急反应等的联动控制与管理，将各个子系统无缝整合联动到一个统一平台上，让地铁智能化水平得到很大提升，为智能地铁迈向智慧化的演进之路打下了良好的基础。'
          }
        ]
      },
      {
        title: '数据分析',
        icon: 'iconshiliangzhinengduixiang9',
        childrenList: [
          {
            title: '收集数据',
            svg: 'metro4',
            content:
              '地铁的数据点包含的数据量很大，数据是地铁自动化、智能化的的基础资源，地铁运营数据的采集与有效存留。通过高频次的运行/运营历史数据分析出其中相关参数的统计学关系。'
          },
          {
            title: '提高效率',
            svg: 'metro5',
            content:
              '根据数据挖掘结果，深层次提供本线路/本车站的运营规律、自动化子系统的运行/维护规律，让轨道交通的数据分析更加智能化，不断优化本线路/本车站的运营/运行水平。'
          }
        ]
      },
      {
        title: '安全可靠',
        icon: 'iconweibiaoti-201',
        childrenList: [
          {
            title: '稳定可靠',
            svg: 'metro6',
            content:
              '通过调度自动化水平的提高，可快速、高效应对火灾、阻塞和其他事故等突发事件，系统可保证长时间安全稳定运行，保证轨道交通运营的安全可靠性。'
          }
        ]
      }
    ],
    // 优势特点模块数据
    goodnessData: [
      {
        title: '中央级综合监控系统',
        icon: 'iconshiliangzhinengduixiang61',
        content:
          '控制中心（OCC）配置的网络交换机，实现OCC所有网络资源的互联。交换机的端口数量和带宽的选择充分考虑ISCS和网络通信设备的要求，网络交换机直接连接到通信传输网络。'
      },
      {
        title: '车站级综合监控系统',
        icon: 'iconhuochezhan1',
        content:
          'FEP处理所有与被集成系统的接口，从FEP采集的数据通过车站交换机送到车站服务器。车站服务器、车站值班站长工作站和FEP等与网络交换机相联。'
      },
      {
        title: '监控系统互联能力',
        icon: 'iconweibiaoti-231',
        content:
          '联网监控中心的所有原始数据和视频图像都来自于各分中心，在监控中心实现资源共享；同时联网监控中心为监控分中心做数据备份，下达交通控制指令或建议。'
      },
      {
        title: '功能子系统',
        icon: 'iconguanli',
        content:
          '电力监控PSCADA、环境与设备监控BAS、门禁ACS、闭路电视监控CCTV、广播PA、乘客信息PAS、自动售票检票AFC、无线通信RC、站台门PSD、信号SIG、火灾自动报警FAS、电气火灾监控EFMS、时钟CLK'
      }
    ]
  },
  {
    name: 'monitor',
    // 顶部banner模块数据
    bannerData: {
      label: '高速公路监控方案',
      descList: [
        { content: '解决高速公路中拥挤与安全的两个主要问题' },
        { content: '保证行车安全和道路畅通' }
      ],
      bannerImg: require('@/assets/solution/monitor/banner.png')
    },
    // 方案架构模块数据
    planData: {
      planIntro: `捍御者高速公路监控系统，满足国家智慧高速规划，以收费台站、出入口、互通区域、服务区（停车区）、长下坡路段、紧急避险车道、隧道等为重点，提供高速公路全程监控的自动化管理工具。`,
      customValue:
        '捍御者高速公路监控系统，全自主产品，实现高速公路的有效信息采集与发布、视频监控与检测分析、广播、站区安防、数字通信、远程会议、服务区智能化，做到“一个平台、整体维护、全路应用”，增强高速公路智能化监控的统一性、灵活性和系统间的协调运作能力，为高速公路的运营管理提供了高集成度的自动化管理手段。',
      planName: '高速公路监控解决方案',
      planImage: require('@/assets/solution/monitor/plan.png')
    },
    // 核心功能模块数据
    functionData: [
      {
        title: '高速监控',
        icon: 'iconsuidao3',
        childrenList: [
          {
            title: '实时监测',
            svg: 'monitor1',
            content:
              '提供监控中心、视频监控、事件告警检测分析、信息采集及发布、IP网络广播、收费站场安防监控等功能'
          }
        ]
      },
      {
        title: '隧道监控',
        icon: 'iconshiliangzhinengduixiang7',
        childrenList: [
          {
            title: '智能操控',
            svg: 'monitor2',
            content:
              '提供视频监控、隧道控制单元、通风控制与智能照明、电力监控与消防控制、应急通信与广播、交通监控与诱导等功能'
          }
        ]
      },
      {
        title: '服务区智能化管理',
        icon: 'iconfuwuquyu1',
        childrenList: [
          {
            title: '同步管理',
            svg: 'monitor3',
            content:
              '提供车辆识别与抓拍、视频监控、信息发布与车位管理、无线信号覆盖等功能'
          }
        ]
      }
    ]
  },
  {
    name: 'park',
    // 顶部banner模块数据
    bannerData: {
      label: '智慧公园综合解决方案',
      descList: [
        { content: '捍御者通过建设智慧化服务、精细化的管理、科学的决策' },
        { content: '为公园快速、健康的发展提供了保障' }
      ],
      bannerImg: require('@/assets/solution/park/banner.png')
    },
    // 方案架构模块数据
    planData: {
      planIntro:
        '智慧公园建设从顶层设计以“服务游客”为中心，围绕公园的建设要求和总体规划，充分运用物联网、云计算、下一代通信网络等先进、成熟的技术，结合国内一流、国际先进的设计理念，对公园的公众服务、公园管理、中心业务的服务要素、管理要素、生产经营要素等进行了重新组合和优化，通过建设智慧化的服务、精细化的管理、科学化的决策，从时间、空间、服务形态三个维度提升了公园的价值，为公园快速、健康发展提供了保障。其主要包含了网络传输系统、智慧综合安防监控系统、公园电子票务系统、公共广播系统、公园多媒体可视化系统、智慧车辆出入管理系统、智慧娱乐服务、公园业务办公系统、公园公众服务门户网站、公园微信小程序服务系统、公园能源管理系统建设等多个模块',
      customValue:
        '打破传统园林管理模式，利用人工智能、物联网、GIS、DCS、SaaS等技术，为城市园林智慧化建设和管理提供，从需求分析、规划设计、实施交付、运维服务等的全过程项目建设管理服务极大地帮助公园管理部门打造智慧公园，提高公园的信息化服务水平，为广大市民和中外游客提供更智能、便捷的服务，真正实现“智”管公园、“慧”及民生，不断提高城市公园管理水平，让游客体验更智慧、更高效、更便利、更美好的旅游生活，增强人们的幸福感和满足感。',
      planName: '智慧公园综合解决方案',
      planImage: require('@/assets/solution/park/plan.png')
    },
    // 核心功能模块数据
    functionData: [
      {
        title: '一核心四体系',
        icon: 'iconhexinxitong',
        childrenList: [
          {
            title: '智能体系',
            svg: 'store7',
            content:
              '智慧公园管理平台为信息展示平台和集成办公平台，以数字智能指挥调度中心为核心，根据职能划分的不同，形成了生态保护体系、管理体系、服务体系、营销体系四大管理体系。'
          },
          {
            title: '协同服务',
            svg: 'park1',
            content:
              '以数据中心、统一网络通讯平台、应用集成平台、地理信息系统GIS平台、安全保障体系、基础数据库、数据仓库以及相关的标准规范为支撑，保证了整个管理体系的整体性，各应用系统之间互连互通、资源共享，从而实现了跨部门的组织间的高度协同服务。'
          }
        ]
      },
      {
        title: '综合安防',
        icon: 'iconweibiaoti-201',
        childrenList: [
          {
            title: '稳定可靠',
            svg: 'safety5',
            content:
              '采用稳定可靠的综合安防监控系统可以实现对各个景点安全、科学、有效的管理，对公园现场及周边实施全天候、全方位24小时监控及人员流动的记录，达到加强现场监督和安全管理，提高服务质量的目的，使工作管理更加规范化、科学化、准确化、智能化、信息化。'
          },
          {
            title: '安全保障',
            svg: 'park2',
            content:
              '为公园安全工作做好有力保障。构建综合安防监控系统，实现公园的物体遗留监测、周界监控、公园禁区防护及人流量统计等功能，更好地实现景区实时监测，数据收集及景区安全保障。'
          }
        ]
      },
      {
        title: '智能电子票务',
        icon: 'icon42piaowudaili',
        childrenList: [
          {
            title: '信息化管理',
            svg: 'monitor3',
            content:
              '实现对进出人数统计和监控的重要功能，是集电子售票、检票管理、数据实时统计分析于一体的信息化管理系统，可方便游客买票入园和及时掌握景区人流情况。'
          },
          {
            title: '数字管理',
            svg: 'metro2',
            content:
              '通过电子票务，把过去粗放静态的人工票务管理变成了精细动态的数字管理，不仅大大方便了游客，而且减少了管理的盲目性，降低了管理成本，使管理更精细化、决策更科学化。'
          }
        ]
      },
      {
        title: '公共广播',
        icon: 'iconguangbo',
        childrenList: [
          {
            title: '智能通讯',
            svg: 'park3',
            content:
              '将数字音频、网络编解码、流媒体等技术有机结合，集“背景音乐、公园管理、寻址播放、紧急疏散、语音导游”于一体，采用新一代数字网络公园智能广播系统。让优美的音乐穿梭于景点之中，点缀古建筑深厚的历史底蕴，体会古人的思想和文化内涵；让游客及时听到旅游注意事项，寻人找物信息；使景区管理变得更加简单，更快的传递景区的管理信息。'
          }
        ]
      },
      {
        title: '停车场管理',
        icon: 'icontingche1',
        childrenList: [
          {
            title: '智慧停车',
            svg: 'park4',
            content:
              '车辆出入管理系统通过采集记录车辆出入记录、场内位置，实现车辆出入和场内车辆的动态和静态的综合管理。结合微信小程序完成收费策略实现，收费账务管理，车道设备控制等功能。车位引导系统实现泊车者方便快捷泊车，并对车位进行监控，使停车场车位管理更加规范、有序，提高车位利用率。'
          }
        ]
      },
      {
        title: 'AI互动娱乐',
        icon: 'iconyule',
        childrenList: [
          {
            title: '休闲娱乐',
            svg: 'safety2',
            content:
              '在基本日常休闲娱乐的功能上，利用智能体感互动服务来改善智慧体育公园的互动娱乐+景色亮化工程，对发展城市的旅游观光业、改善居住环境等产生积极的影响，给城市带来更大的社会效益，同时也提高人们的娱乐体验感。'
          }
        ]
      },
      {
        title: '办公自动化',
        icon: 'iconbangong',
        childrenList: [
          {
            title: '协同办公',
            svg: 'park5',
            content:
              '通过对办公自动化的建设，即在统一的信息平台上实现对公文处理，彻底消除了公园内部存在的信息膨胀，信息孤岛，信息非结构化，信息非个性化等问题。为公园管理者提供一个协同的、集成的办公环境，使所有的办公人员都在同一且个性化的信息门户中一起工作,摆脱时间和地域的限制，实现协同工作与知识管理。'
          }
        ]
      },
      {
        title: '门户网站',
        icon: 'iconmenhuwangzhan',
        childrenList: [
          {
            title: '实时信息',
            svg: 'building8',
            content:
              '基于二维电子地图，创建公园门户网站，为游客提供天气预报、在线旅游咨询等全方位的旅游服务。公园介绍功能包括：公园概况、公园风光、旅游指南、公园动态、游客调查等，为游客提供全程智能导游服务，使之能在最短的时间以最快的速度了解公园的全貌；基于GIS平台，提供公园的信息浏览与查询、旅游路线推荐、旅游地图浏览及下载等功能。'
          }
        ]
      },
      {
        title: '能源管理',
        icon: 'iconzonghenengyuan',
        childrenList: [
          {
            title: '精细化管理',
            svg: 'building4',
            content:
              '公园能源管理系统是面向公园的能耗监控，其包含了公园的动力和环境监控，通过建立客观的能耗评价体系并对公园水、电、气、暖等能耗数据进行采集、统计、分析，以直观的数据图表形式向管理人员或决策层系统展示，便于了解公园的真实能耗数据，及时找出不合理的高能耗点或不合理的耗能习惯，为进一步实施节能改造或设备升级提供准确的数据支撑，实现公园的精细化管理和成本控制。'
          }
        ]
      }
    ],
    // 优势特点模块数据
    goodnessData: [
      {
        title: '集中管理',
        icon: 'iconguanli2',
        content:
          '通过集中平台，实现数据中心的统一呈现统一管理。对整个公园内的所有机电设备进行统一监视，测量和采集数据的集成系统。智慧公园集成管理系统汇集公园内各种相关设备和管理消息，对各种类型的子系统，具有较强的信息采集及数据通信能力。'
      },
      {
        title: '私有云管端',
        icon: 'iconyunduanbangongshi',
        content:
          '基于微服务实现公园服务虚拟化，采用私有云技术方案，保证公园数据安全，用于实现对公园的统一监控、维护、自动化控制，涵盖了所有子系统的上层软件功能，内置了数据采集平台、资源管理平台、告警管理平台、自动化控制平台、综合呈现平台、微信小程序等。'
      },
      {
        title: '超低成本',
        icon: 'iconchengben',
        content:
          '采用H.265+编解码算法，将传统每个摄像头2MBps的带宽占用降低到200KBps以下。引入了Flash Video技术，降低硬盘空间占用70%。采用自研的视频管理平台软件，节约60-70%的视频解码服务器数量。采用自研技术的视频安防及反向引导解决方案，综合采购成本降低20%，实施工作量减少20%，维护成本降低30%。'
      },
      {
        title: '一键式监控',
        icon: 'iconjiankong11',
        content:
          '采用人工智能算法，通过空间分析技术、4G/5G移动网络、物联网技术，打造IBMS智能化集成管理系统，对视频监控、智能照明、环卫监测、停车管理等10余项智能系统进行集成管理，打破传统信息化公园中各子系统之间的“信息孤岛”和“孤立状态”，实现“一键式”监控、管理和调度，打造高端智慧化公园。'
      }
    ]
  },
  {
    name: 'engine-room',
    // 顶部banner模块数据
    bannerData: {
      label: '智慧机房监控系统',
      descList: [
        { content: '通过部署分布式采集微模块、集中监控管理云平台' },
        { content: '实现落地动环监控物联网5G的解决方案' }
      ],
      bannerImg: require('@/assets/solution/engine-room/banner.png')
    },
    // 方案架构模块数据
    planData: {
      planIntro:
        '通过5G拥抱物联网，基于5G+IOT+ESIM新兴技术，实现落地动环监控物联网5G的解决方案。通过部署分布式采集微模块、集中监控管理云平台，建设了数据中心的供配电系统、制冷环境系统、视频门禁系统、安防系统、消防系统等的一体化监控平台，为数据中心基础设施提供7*24*365不间断的系统监控及运行保障。为数据中心智能化行业，工业物联网行业等实现5G物联网，为物联网解决方案打下牢固的根基和行业影响力。',
      customValue:
        '本方案遵循“总体规划、分步实施”和“从上而下设计，从下往上实施”的原则，采用“云，管，端”技术架构，采用统一的标准接口协议，建立数据中心集中监控管理云平台，提高系统维护和管理的智能化水平、提升协调运行能力及全面的管理功能。实现所有监控系统的功能集成、网络集成和软件界面集成，有效降低系统维护人员的日常工作强度，提高系统可用性并节省系统维护成本，实现数据中心基础设施一体化、全面化、智能化、自动化的监控和管理，保障数据中心7*24*365不间断运行，提高运维管理效率及数据中心可用性。',
      planName: '智慧机房监控系统综合解决方案',
      planImage: require('@/assets/solution/engine-room/plan.png')
    },
    // 核心功能模块数据
    functionData: [
      {
        title: '功能虚拟化',
        icon: 'iconshiliangzhinengduixiang81',
        childrenList: [
          {
            title: '全时驱动',
            svg: 'monitor1',
            content:
              '通过软件定义硬件，功能虚拟化和全时驱动的概念，提供一套完整的机房动环监控系统，搭建可持续发展的、拥有完全自主产权的，完整的数据架构+技术架构+应用架构+业务架构的产品体系。'
          },
          {
            title: '子系统',
            svg: 'building7',
            content:
              '盖以下子系统：机房智慧化管理统一平台（3D+BIM可视化）；供配电监控子系统；门禁监控子系统；视频监控子系统；防入侵监控子系统；环境监控子系统（温湿度、漏水、空调等）；微信小程序子系统。'
          }
        ]
      }
    ],
    // 优势特点模块数据
    goodnessData: [
      {
        title: '生命体征可视化',
        icon: 'iconshangpinshengmingzhouqi',
        content:
          '状态展示：可视运维大屏+手机APP，实时展示机房状态；状态分析：多种可视化报表，多维度分析机房运行状态；异常抓拍：机房异常联动摄像头抓拍，助力异常溯源。'
      },
      {
        title: '集中管理',
        icon: 'iconguanli3',
        content:
          '将动环的三层架构改变为集中架构，底层的接入设备不再对数据进行处理，只进行协议的转换，降低硬件成本，降低系统故障点，降低建设与运维成本。同时通过集中平台，实现数据中心的统一呈现统一管理，避免了在各个子系统间切换的麻烦，提高整体运维效率。'
      },
      {
        title: 'AI智能盯防',
        icon: 'iconjiankong11',
        content:
          '采用AI+机房运维平台的场景定制方式，从被动查看，转变为主动预警，实现对楼宇中人、车辆、环境、事件的智能化管理，降低人工管理成本，同时利用安防监控，对人的轨迹进行追踪，对人员身份进行辨认，实现核心机房安全工作。'
      },
      {
        title: '私有云管端',
        icon: 'iconyunduanbangongshi',
        content:
          '不再采用传统动环的烟囱式的单体应用模式，而是基于微服务（Docker）实现机房运维服务虚拟化，将各业务逻辑封装在独立的容器中，同时引入保活和池自管理技术，搭建池化计算资源，实现云端的负载均衡和高可用性，达到消除单点故障、集中计算资源、保障业务不间断持续运行。'
      },
      {
        title: '无线接入',
        icon: 'iconwuxianjierudian',
        content:
          '基于NBIoT网络的MQTT数据处理方法和采集微模块。末端设备就近接入Server-pool服务控制，完美实现任意末端设备无线化接入，将动环信号高精度地转换成数字量，通过授权频段无线远传到云端服务器。同时链路采用全移动网路化，有效降低了纵横向的扩容成本。'
      },
      {
        title: '全面感知',
        icon: 'iconganzhi',
        content:
          '基础设施监控，不仅关注数据中心内相关设备的使用情况以及能耗管理，并融合设备业务内涵，使基础设施可监、可管、可控、可视，通过持续收集和管理机房的资产、资源以及各种设备的运行状态，通过分析、整合提炼成有业务价值的数据，帮助管理者管理并优化性能，提高运营效率，提高运维安全性和可靠性。'
      },
      {
        title: '闭环管理',
        icon: 'iconshiliangzhinengduixiang22',
        content:
          '规范机房设备上架流程，规范机房设备放置和安装规范，结合U位管理标签实现设备的各维度的资产信息准确梳理，机柜容量准确分析，实现设备全生命周期精细管理。规范故障上报处理流程，结合微信小程序，实现运维标准化、规范化、流程化、自动化管理，实现运维闭环，管理闭环。'
      }
    ]
  },
  {
    name: 'parking',
    // 顶部banner模块数据
    bannerData: {
      label: '智慧停车场综合解决方案',
      descList: [
        { content: '交通拥挤给人们带来生活上的不便，捍御者为解决“停车难”问题' },
        { content: '专门研发一套智慧高效的无卡、无人值守停车场解决方案' }
      ],
      bannerImg: require('@/assets/solution/parking/banner.png')
    },
    // 方案架构模块数据
    planData: {
      planIntro:
        '随着社会的进步和发展，城市由于交通工具的增加造成的交通拥挤甚至混乱给人们的生活带来极大的不便，“停车难”已是普遍的城市病。为了解决“停车难”问题，捍御者专门研发一套智慧、高效的无卡、无人值守停车场管理解决方案，目的是打破驾车人和停车位之间的信息不对称、提高停车位利用率、降低人工管理成本、缓解交通拥堵。凭借捍御者自主研发的高清视频采集技术和智能车牌识别算法，系统对出入车辆的车牌识别精准度达到了 99%，使得车辆进出停车场无需等待，无需停车，缓解了高峰期入场拥堵情况。同时，结合最新物联网和移动互联网技术，实现了快速停车、反向寻车、快速缴费等一系列自动化功能，实现停车位的智能化管理，车主可远程查询、停车自助缴费，增强车主便利性，增加停车位的周转率，最终实现信息化，有效化，稳定化管理的目的。',
      customValue:
        '通过计算机、网络设备、车道管理设备搭建一套对停车场车辆出入、场内车流引导、收取停车费进行管理的网络系统，实现对收费人员的有效管理，减少收费流失；通过自主缴费，让收费人员变成督查人员，进而减少整个人工的数量。车场中车位探测采用视频车牌识别技术，对每个车位的占用或空闲状况进行可靠检测。在每个车位上方安装视频探测器即可探测到有无车辆停泊在车位上，系统将所有探测信息实时采集到系统中，系统通过计算机实时将引导信息反馈给每个引导指示等。帮助车场经营方盘活车位资源，提高单个车位的盈利能力，进而实现整体业务营收的提升，最终达到开源创收、优化升级的目的。',
      planName: '智慧停车场综合解决方案',
      planImage: require('@/assets/solution/parking/plan.png')
    },
    // 核心功能模块数据
    functionData: [
      {
        title: '自动车牌识别功能',
        icon: 'iconchepaishibie',
        childrenList: [
          {
            title: '智能体系',
            svg: 'store7',
            content:
              '系统配置集成补光灯设计，采用闪光拍摄方式的高清高速摄像机自动采集出入库车辆的前部特征图像，自动识别车牌号码并记录车辆的前部全景图像，保证车辆识别率不低于99%，作为停车管理、停车收费、安全认证的原始数据并以车牌号码作为数据标识进行信息数据管理。'
          },
          {
            title: '协同服务',
            svg: 'park1',
            content:
              '以数据中心、统一网络通讯平台、应用集成平台、地理信息系统GIS平台、安全保障体系、基础数据库、数据仓库以及相关的标准规范为支撑，保证了整个管理体系的整体性，各应用系统之间互连互通、资源共享，从而实现了跨部门的组织间的高度协同服务。'
          }
        ]
      },
      {
        title: '视频识别正反向引导',
        icon: 'iconshipin2',
        childrenList: [
          {
            title: '视频识别',
            svg: 'parking1',
            content:
              '通过视频识别实现车位引导功能，可有效统计停车场车流、车位使用率，引导车主快速停车。停车场采用视频车位引导的系统，通常都可轻易的实现无卡寻车，无卡寻车：仅输入车牌号即可查询车辆停放位置。'
          }
        ]
      },
      {
        title: '分组收费',
        icon: 'iconshoufeijilu',
        childrenList: [
          {
            title: '智能分组',
            svg: 'parking2',
            content:
              '不同用户组收费：如免费停车用户，免费组车辆不限时免费停车；折扣组用户，折扣组可按比例或固定金额减免停车费用；包时组用户，包时组在包时范围内免收费用，超期正常收取。车位可用情况下进行包时收费。支持家庭组一个停车位对多车免费。可自行设置更多收费分组，分组用户数不限。'
          }
        ]
      },
      {
        title: '进出统计',
        icon: 'iconbaobiao',
        childrenList: [
          {
            title: '进出统计',
            svg: 'parking3',
            content:
              '统计岗亭收费明细报表、进出车明细报表、提前收费明细报表、每日收费统计报表、每日出入口流量统计报表、特殊数据报表、按停车时间统计停车数、每月收费统计报表等。'
          }
        ]
      },
      {
        title: '一码通行',
        icon: 'iconfangketonghang',
        childrenList: [
          {
            title: '便捷查询',
            svg: 'monitor3',
            content:
              '通过二维码定位，通过小程序实现二维码定位人员位置，并且自动计算路径，呈现轨迹路径。用户使用微信小程序自助查询车辆位置，结合路径规划实现快速查找车辆。同时，通过微信小程序可完成收费策略实现，收费账务管理，车道设备控制等功能，实现“无停进出”。'
          }
        ]
      }
    ],
    // 优势特点模块数据
    goodnessData: [
      {
        title: '快速进出，减少拥堵',
        icon: 'iconguanli2',
        content:
          '采用智能车牌识别（不停车收费），车主可以通过微信扫描二维码，银联，支付宝等方式缴纳停车费用，无牌车或未识别车牌可通过扫描系统提供的二维码进行信息登记后进入停车场，离开时可通过扫描系统提供的二维码进行结费后离开停车场。'
      },
      {
        title: '安全防护',
        icon: 'iconyunduanbangongshi',
        content:
          '自动道闸具有防抬杆、全卸荷、光电控制、带准确平衡系统等功能；有感应自控和手动按钮控制等多种控制方式；先进的数字式车辆检测系统和地感感应双重防砸系统（接触灵敏度≤250克），有效避免车辆、行人等被砸伤'
      },
      {
        title: '低成本，高可用',
        icon: 'iconchengben',
        content:
          '车位引导系统采用高清视频车位检测器内置双路300万高清摄像机，可同时监测两侧合计6个车位,可减少设备数量，无线WIFI连接方式，减少施工布线。状态指示灯远程控制，指示灯颜色可根据现场情况进行设定。结合二维码定位功能，小程序扫描固定位置二维码，自动规划寻车路径，方便快捷。结合微信小程序实现车辆反向查询，节约反向寻车终端，解决采购和维护成本。'
      },
      {
        title: '强大的数据分析',
        icon: 'iconjiankong11',
        content:
          '通过前端摄像机照片级别传输到后端平台，统一做视频和图片分析，降低网络传输带宽。视频智能终端车牌识别，精确定位，确保车主快速寻车。场内智能引导，帮助车主快速停车，降低油耗，节约时间。车位状态识别准确率高：每个车位上方的摄像机可以实时抓拍并精准识别当前车位状态，识别准确率超过99%。'
      }
    ]
  },
  {
    name: 'library',
    // 顶部banner模块数据
    bannerData: {
      label: '智慧图书馆解决方案',
      descList: [
        { content: '捍御者充分的实现物与物、物与人、人与人的各种互连' },
        {
          content:
            '从而实现图书馆信息资源服务提供与读者用户知识需求之间的“智能化互动'
        }
      ],
      bannerImg: require('@/assets/solution/library/banner.png')
    },
    // 方案架构模块数据
    planData: {
      planIntro:
        '捍御者利用AI+IOT，采用“私有云”技术架构，通过“云-管-端”的承载方式，构建面向新型的互联网+智慧型医院。 感知层采用多种行业终端、医院终端、个人终端，采集信息，并根据大数据信息进行行为分析；利用RFID、WIFI、移动通信网络、光纤网络等多种网络互连，充分的实现物与物、物与人、人与人的各种互连，从而实现图书馆信息资源服务提供与读者用户知识需求之间的“智能化互动” ，为读者用户提供个性化、 特色化、 以每一位用户为核心的知识信息搜集与呈现、主动推送服务，体现真正意义上物联网“智慧图书馆” 的智能化服务效能。',
      customValue:
        '智慧图书馆（Smart Library）能够提供不受时间和空间限制的、 移动式的、可被感知的图书馆服务，能够帮助读者获取所需查找的图书及有关的信息资源。',
      planName: '智慧建筑方案',
      planImage: require('@/assets/solution/library/plan.png')
    },
    // 核心功能模块数据
    functionData: [
      {
        title: '智慧平台',
        icon: 'iconjiankongshuju1',
        childrenList: [
          {
            title: '智慧管理',
            svg: 'safety5',
            content:
              '通过统一的策略控制，实现图书馆馆藏资源与建筑多场景精细化管理'
          }
        ]
      },
      {
        title: '健壮组网',
        icon: 'iconweibiaoti-271',
        childrenList: [
          {
            title: '数据中心',
            svg: 'building4',
            content:
              '完备组网结合高可靠技术，有线无线高度融合，弹性、虚拟、灵活的数据中心云网络。'
          }
        ]
      },
      {
        title: '安全免疫',
        icon: 'iconanquan',
        childrenList: [
          {
            title: '安全保障',
            svg: 'building5',
            content: '大数据的全网安全分析与主动防御，确保数据万无一失。'
          }
        ]
      },
      {
        title: '智能标签',
        icon: 'iconbiaoqian',
        childrenList: [
          {
            title: '智能高效',
            svg: 'store6',
            content:
              '实现对归还和采购图书的高效分拣与迅速查找，帮助工作人员精准地完成图书分类上架、读者准确定位所需图书的具体架次和位置。'
          }
        ]
      }
    ],
    // 优势特点模块数据
    goodnessData: [
      {
        title: '无时间限制',
        icon: 'iconshijian',
        content:
          '基于物联网环境下的信息通讯网络化、馆藏资源数字化、管理服务智能化、信号传输实时化的虚拟服务平台，能够提供24小时的全天候无障碍式读者信息资源服务。'
      },
      {
        title: '无方式限制',
        icon: 'iconkongjian-01',
        content:
          '基于“物联网” 技术、信息无缝联接与感知、 推送、 传输装置和“无所不在” 、“无所不包” 、“无所不能” 的泛在网络环境，满足用户对馆藏文献信息资源的获取的多种渠道、多种形态、 多种途径的需求。'
      },
      {
        title: '资源集群化',
        icon: 'iconziyuan95',
        content:
          '通过资源建模，将馆藏资源，例如传统的印刷型及现代的数字型、 网络型等包括多媒体文献在内的涉及各种载体门类的文献信息，抽象成概念意义上的“书”，书和书之间通过传输装置，信息感知，推送及“无所不在” 的泛在网络（Ubiquitous Network） 实现跨类型、 跨部门、跨系统跨媒介、跨馆际、跨时空的定位感知响应， 经由此为读者用户提供集群化的典藏管理服务。'
      },
      {
        title: '无空间限制',
        icon: 'icondongtai',
        content:
          '借助物-物相联的智能化技术能够为用户提供不论何地的信息资源服务，用户可以在任意地点（如住宅区域、 地铁、 商场、 酒店、 办公室、 公交车厢等）登陆使用图书馆的虚拟服务平台， 实现真正意义上的随地即时利用， 感知到无处不在的图书馆资源服务及其友好的易接近性。'
      }
    ]
  },
  {
    name: 'school',
    // 顶部banner模块数据
    bannerData: {
      label: '智慧学校解决方案',
      descList: [
        { content: '捍御者利用AI+IOT，采用“私有云”技术架构' },
        {
          content: '通过“云-管-端”的承载方式，构建面向新型的互联网+智慧型学校。'
        }
      ],
      bannerImg: require('@/assets/solution/school/banner.png')
    },
    // 方案架构模块数据
    planData: {
      planIntro:
        '捍御者利用AI+IOT，采用“私有云”技术架构，通过“云-管-端”的承载方式，构建面向新型的互联网+智慧型学校。 感知层采用多种行业终端、学校终端、个人终端，采集信息，并根据大数据信息进行行为分析；利用LoRa、WIFI、移动通信网络、光纤网络等多种网络互连，充分的实现物与物、物与人、人与人的各种互连，从而实现“人类智能”和“人工智能”并行，促进学校安全管理和应急处置的精细化、专业化和及时性。促进校园生活和教学智能化、便捷化。 ',
      customValue:
        '以用户需求为导向，协助客户完成真正适用于各自校园环境的智慧化校园建设。通过无处不在的智能化传感器以及各类应用组成的物联网，实现对物理校园的全面感知随时获取信息，并通过网络技术实现物联网的整合，借助云计算、决策分析优化等技术对感知信息进行智能的分析和处理，对智慧校园中的各种需求做出预测和智能的决策，提高校园教学、管理效率，创造一个高效、智能、幸福的校园环境。从而形成无处不在的网络学习、融合创新的网络科研、透明高效的校务治理、丰富多彩的校园文化、方便周到的校园生活。',
      planName: '智慧学校解决方案',
      planImage: require('@/assets/solution/school/plan.png')
    },
    // 核心功能模块数据
    functionData: [
      {
        title: '统一设备接入',
        icon: 'iconshebei',
        childrenList: [
          {
            title: '应用范围',
            svg: 'metro2',
            content: '支持所有类型终端设备协议接入，简化接入流程。'
          }
        ]
      },
      {
        title: '统一数据分析',
        icon: 'iconKHCFDC_shuju',
        childrenList: [
          {
            title: '数据分析',
            svg: 'store3',
            content:
              '对学校内各个子系统的数据进行大数据分析，实现学校智能，多业务协同管理，为学校管理者提供决策依据。'
          }
        ]
      },
      {
        title: '统一管理',
        icon: 'iconguanli21',
        childrenList: [
          {
            title: '实时效率',
            svg: 'building2',
            content:
              '通过部署在私有云上的运维管理系统，实现对校内建筑，教师，学生，实现时间，空间，人和事物全方位管理，有效降低运营成本，提高运维效率。'
          }
        ]
      }
    ],
    // 优势特点模块数据
    goodnessData: [
      {
        title: '消费管理系统',
        icon: 'iconxiaofei',
        content: '提供一套先进的消费管理系统，满足学校食堂管理需求。'
      },
      {
        title: '视频分析系统',
        icon: 'iconshipinfenxi3x',
        content:
          '视频分析系统是利用人工智能技术，基于神经网络深度学习等算法，对实时视频或视频文件进行智能分析，主动发现异常的系统。'
      },
      {
        title: '图书管理系统',
        icon: 'icontushu',
        content: '提供一套先进的图书管理系统，满足学校图书管理需求。'
      },
      {
        title: '视频监控系统',
        icon: 'iconjiankong3',
        content:
          '依托入侵报警、安防视频监控和出入口控制等子系统组成的安全管理系统，实现了各子系统的有效联动、管理和监控，是智能建筑楼宇安全运行的重要一环。'
      },
      {
        title: '智慧校园建筑管理平台',
        icon: 'iconjiankong2',
        content:
          '智慧校园建筑管理平台是综合管理软件平台，用于校园的智能建筑的智能化统一管理，实现统一平台管理校园所有资源、设备设施、空间，支撑校园不同部门使用需求，以利于节约管理成本和使用成本，统一校园服务，铸造智慧校园。'
      },
      {
        title: '智慧课室系统',
        icon: 'iconxitong',
        content:
          '利用信息化手为基础，建设智慧化的校园工作、学习和生活一体化环境，以各种应用服务系统为载体，实现对校园内各类资源的有效集成整合和优化，实现资源的有效配置和充分利用，将校务管理过程的优化协调。为校园提供数字化教学、数字化学习、数字化科研和数字化管理，将教学、科研、管理和校园生活进行充分融合形成一体化环境。'
      }
    ],
    // 产品组成模块数据
    formationData: {
      title: '产品组成',
      childrenList: [
        {
          title: '核心设备',
          img: require('@/assets/solution/school/product1.png'),
          content: '录播主机/互动评估主机/互动主机等'
        },
        {
          title: '视频采集',
          img: require('@/assets/solution/school/product2.png'),
          content: '学生/老师高清摄像机等'
        },
        {
          title: '音频采集处理',
          img: require('@/assets/solution/school/product3.png'),
          content: '吊麦/拾音器/音频处理器等'
        },
        {
          title: '智能定位',
          img: require('@/assets/solution/school/product4.png'),
          content: '定位摄像机/跟踪主机等'
        },
        {
          title: '班班通设备',
          img: require('@/assets/solution/school/product5.png'),
          content: '教学一体机/投影机等'
        },
        {
          title: '其他设备',
          img: require('@/assets/solution/school/product6.png'),
          content: '如导播键盘、智能控制面板等'
        }
      ]
    },
    // 功能介绍模块数据
    introData: [
      {
        title: '远程互动教学',
        img: require('@/assets/solution/school/intro1.png'),
        content: {
          text1:
            '学校内实现一教师多课堂，增加教师与学生、学生与学生之间的有益互动',
          text2:
            '借助信息化手段对互动教学进行有效管理，在保证良好的教学次序的基础上改善教学效果',
          text3:
            '实现跨学校跨区域远程教学，高效使用优质教学资源，拉动整体教学水平提升'
        }
      },
      {
        title: '教学质量评估',
        img: require('@/assets/solution/school/intro2.png'),
        content: {
          text1:
            '系统将整个课堂的授课情况实时呈现，评课人员可在线对授课教师进行教学点评与指导，实现教研常态化。',
          text2:
            '授课完成后，可基于教师画面、学生画面、电子白板三屏信息留存进行离线和在线的教学质量评估。',
          text3:
            '授课过程中系统实时对课堂互动情况进行统计分析，包括学生举手人次数、回答问题次数及时长等。'
        }
      },
      {
        title: '智能录播直播',
        img: require('@/assets/solution/school/intro3.png'),
        content: {
          text1:
            '系统支持主讲教室和接收教室同步进行课程录制和存储。在课程录制过程中可以对画面进行多视角多镜头的切换调整，如以画中画等形式帮助教师与学生掌握课程要点。基于录播直播功能，教师能够对视频课件进行精细化制作，确保授课质量。'
        }
      }
    ],
    // 特异性模块
    specialData: {
      title: '智慧学校',
      img: require('@/assets/solution/school/special.png'),
      childrenList: [
        {
          title: '3.巡课督导',
          img: require('@/assets/solution/school/special1.png'),
          content:
            '教育主管部门可通过视讯网路对课堂进行远程观摩和评估考核，提升教学管理能力。'
        },
        {
          title: '2.监控安防',
          img: require('@/assets/solution/school/special2.png'),
          content:
            '对教室及校园运行状态的实时远程监管，及时发现事故征兆和安全隐患，实现校园安防。'
        },
        {
          title: '1.设备管理',
          img: require('@/assets/solution/school/special3.png'),
          content:
            '对相关设备使用情况进行数据统计，当设备出现故障时进行线上报修，保障平台持续稳定运行。'
        }
      ]
    }
  },
  {
    name: 'hospital',
    // 顶部banner模块数据
    bannerData: {
      label: '智慧医院解决方案',
      descList: [
        { content: '捍御者利用AI+IOT，采用“私有云”技术架构' },
        { content: '通过“云-管-端”的承载方式，构建面向新型的互联网+智慧型医院' }
      ],
      bannerImg: require('@/assets/solution/hospital/banner.png')
    },
    // 方案架构模块数据
    planData: {
      planIntro:
        '捍御者利用AI+IOT，采用“私有云”技术架构，通过“云-管-端”的承载方式，构建面向新型的互联网+智慧型医院。 感知层采用多种行业终端、医院终端、个人终端，采集信息，并根据大数据信息进行行为分析；利用WIFI、移动通信网络、光纤网络等多种网络互连，充分的实现物与物、物与人、人与人的各种互连，实现医院分级诊疗，资源共享，实现医院内部各个子系统的互联互通，高效运转，提升用户体验，降低差错。',
      customValue:
        '全面融合智能化和信息化，整体规划设计的应用解决方案，彻底告别信息孤岛，使医院决策分析实现“一键式”数据集成展现建立统一的网络平台、控制平台和信息平台，按照业务和管理流程的要求进行数据融合，功能集成，为增强医院竞争力与创新力，医院信息化应用与建筑智能化提供有效驱动力。',
      planName: '智慧医院解决方案',
      planImage: require('@/assets/solution/hospital/plan.png')
    },
    // 核心功能模块数据
    functionData: [
      {
        title: '智慧平台',
        icon: 'iconjiankongshuju1',
        childrenList: [
          {
            title: '智慧管理',
            svg: 'safety5',
            content:
              '通过统一的策略控制，实现图书馆馆藏资源与建筑多场景精细化管理'
          }
        ]
      },
      {
        title: '健壮组网',
        icon: 'iconweibiaoti-271',
        childrenList: [
          {
            title: '数据中心',
            svg: 'building4',
            content:
              '完备组网结合高可靠技术，有线无线高度融合，弹性、虚拟、灵活的数据中心云网络。'
          }
        ]
      },
      {
        title: '安全免疫',
        icon: 'iconanquan',
        childrenList: [
          {
            title: '安全保障',
            svg: 'building5',
            content: '大数据的全网安全分析与主动防御，确保数据万无一失。'
          }
        ]
      }
    ],
    // 优势特点模块数据
    goodnessData: [
      {
        title: '视频监控系统',
        icon: 'iconjiankong3',
        content:
          '依托入侵报警、安防视频监控和出入口控制等子系统组成的安全管理系统，实现了各子系统的有效联动、管理和监控，是智能建筑楼宇安全运行的重要一环。'
      },
      {
        title: '视频分析系统',
        icon: 'iconshipinfenxi3x',
        content:
          '视频分析系统是利用人工智能技术，基于神经网络深度学习等算法，对实时视频或视频文件进行智能分析，主动发现异常的系统。'
      },
      {
        title: '住院管理系统',
        icon: 'iconzhuyuanfuwu',
        content:
          '利用先进的信息管理和智能化技术针对住院业务流程的优化与再造，从整体上提高医院的工作质量和工作效率，使病人获得更为适宜、便捷和质优价廉的医疗服务。'
      },
      {
        title: '医院基础管理平台',
        icon: 'iconyiyuanguanli',
        content:
          '对医院的基础硬件平台建设和现状，从弱电综合管线、综合布线、无线信号集成覆盖、计算机网络、数据与语音整合、信息安全、数据存储等七个方面，建立医院基础平台的系统整体构架, 为医院用户提供端到端品质保证的融合数据、语音、视频等多业务的融合通信平台。'
      },
      {
        title: '门/急诊管理系统',
        icon: 'icon-_menzhen',
        content:
          '充分利用现有计算机和网络，信息和语音导航等手段，设计合理的工作流程、管理流程和数据流程，避免操作的繁琐、数据的重复和信息的冗余，使患者在挂号、候诊、就医、检查等各环节都感受到安心、快捷、有序的一流医疗环境和服务水准。'
      },
      {
        title: '医院节能和设施管理系统',
        icon: 'iconyiyuan',
        content:
          '通过对医院建筑设备如：HVAC设备、供配电及照明设备、给排水设备、电梯等进行统一管理，用能量计量系统对医院内的用电量、水量（冷水、生活热水）、医用气体（氧气、压缩空气、负压空气、笑气）、空调热能实现量化管理，建立自动抄表系统，即时提供系统的能耗数据，建立单床能量消耗的统计数据和节约分析的意见，提高后勤管理效率、降低运行成本，最终创造一个高效、节能、环保、舒适的医院环境，并对医院设施的突发事件做出紧急反应。'
      }
    ]
  },
  {
    name: 'garden',
    // 顶部banner模块数据
    bannerData: {
      label: '智慧园区解决方案',
      descList: [
        { content: '捍御者利用AI+IOT，采用“私有云”技术架构' },
        { content: '通过“云-管-端”的承载方式，构建面向新型的互联网+智慧型医院' }
      ],
      bannerImg: require('@/assets/solution/garden/banner.png')
    },
    // 方案架构模块数据
    planData: {
      planIntro:
        '“捍御者智能化园区管理系统”利用新一代信息与通信技术来感知、监测、分析、控制、整合园区各个关键环节的资源，集成了视频安防监控、出入口控制、停车场等管控场景界面，使各系统之间互联、共享、智慧，实现了多信息协同联动，为园区安全管理、业主便捷生活、物业信息化管理提供了有力保障。有效的降低了企业运营成本，提高工作效率，加强各类园区创新、服务和管理能力。',
      customValue:
        '提升园区的智能化管理能力和商业价值，为客户提供创新科技解决方案，用数据连接人、空间与设施，赋能园区智慧运营，助力智慧升级。',
      planName: '智慧园区解决方案',
      planImage: require('@/assets/solution/garden/plan.png')
    },
    // 核心功能模块数据
    functionData: [
      {
        title: '统一设备接入',
        icon: 'iconshebei',
        childrenList: [
          {
            title: '应用范围',
            svg: 'metro2',
            content: '支持所有类型终端设备协议接入，简化接入流程。'
          }
        ]
      },
      {
        title: '统一数据分析',
        icon: 'iconKHCFDC_shuju',
        childrenList: [
          {
            title: '数据分析',
            svg: 'store3',
            content:
              '对学校内各个子系统的数据进行大数据分析，实现学校智能，多业务协同管理，为学校管理者提供决策依据。'
          }
        ]
      },
      {
        title: '统一管理',
        icon: 'iconguanli21',
        childrenList: [
          {
            title: '实时效率',
            svg: 'building2',
            content:
              '通过部署在私有云上的运维管理系统，实现对校内建筑，教师，学生，实现时间，空间，人和事物全方位管理，有效降低运营成本，提高运维效率。'
          }
        ]
      }
    ],
    // 优势特点模块数据
    goodnessData: [
      {
        title: '视频监控系统',
        icon: 'iconjiankong3',
        content:
          '依托入侵报警、安防视频监控和出入口控制等子系统组成的安全管理系统，实现了各子系统的有效联动、管理和监控，是智能建筑楼宇安全运行的重要一环。'
      },
      {
        title: '视频分析系统',
        icon: 'iconshipinfenxi3x',
        content:
          '视频分析系统是利用人工智能技术，基于神经网络深度学习等算法，对实时视频或视频文件进行智能分析，主动发现异常的系统。'
      },
      {
        title: '统一管理平台',
        icon: 'iconguanli3',
        content:
          '基于BIM+GIS+IFMS的三维可视化智慧园区全景漫游平台。对整个园区内的所有机电设备进行统一监视，测量和采集数据的集成系统。支持图形化的监控、管理界面，具有中文操作环境，界面简练、友好，联机帮助功能丰富。'
      },
      {
        title: '停车场管理系统',
        icon: 'icon1-3tingche',
        content:
          '停车场管理系统是通过计算机、网络设备、车道管理设备搭建的一套对停车场车辆出入、场内车流引导、收取停车费进行管理的网络系统。'
      },
      {
        title: '楼控自动化系统（BAS）',
        icon: 'icondianti',
        content:
          '将建筑物或建筑群内的电力、照明、空调、给排水、门禁、运输（电梯）、保安、车库管理设备或系统，以集中监视、控制和管理为目的构成的综合系统。'
      },
      {
        title: '云访客系统',
        icon: 'iconyonghufangkeshu',
        content:
          '能有效快捷的取代手工登记的管理措施，对外部来访人员进出需要填写进出记录以及内部人员出入等相关信息的进行智能化管理的系统，系统支持微服务架构部署，实现负载均衡和云访客功能。'
      }
    ]
  }
];

// 硬件产品模块数据
export const HARDWARES = [
  {
    name: 'pc',
    module: '管廊',
    sortNum: 3,
    devieceData: {
      img: require('@/assets/hardware/pc.png'),
      zhName: '协议转换器',
      enName: 'TURBO-PC',
      content:
        'TURBO-PC是捍御者公司自主开发的一款工业级协议转换产品， 可以高效地实现以太网接口与AI、AO、DI、DO、RS-485/232/422 接口之间的相互转换，实现了接入智能化、协议标准化、组网IP化'
    },
    introData: {
      label: '产品介绍',
      textObj: {
        text1:
          'TURBO-PC采用模块化设计思路，内置双路RS485总线控制器，产品支持的接口类型多、接口数量多，并且可基于具体使用场景需要的传感器和控制终端，定制化和扩展接口类型和接口数量。TURBO-PC协议转换器采用ARM-cortex A9 平台，高效稳定软件内核，支持后端接口软件自动发现并配置连接设备自动链接。',
        text2:
          'TURBO-PC的软件开发采用Golang、时序数据库和分布式消息总线实现大并发的实时处理，非常适合数量庞大的前端设备的并发数据处理。接入控制器TURBO-PC产品采用多种组网方式，高集成度一体化设计，系统功耗小，系统可靠度高，系统安装维护方便。接入控制器TURBO-PC产品专门防潮设计，防护等级高，具备总线保护、过流保护、抗电磁干扰能力。'
      }
    },
    paramsData: [
      {
        childrenList: [
          { label: '操作系统：', value: '嵌入式Linux' },
          { label: '支持远程软件升级、重启', value: '' },
          { label: '防护等级：', value: 'IP65' },
          {
            label: '接口端子：',
            value: 'AI口*12，DI口*12,DO口*8，AO口*2 RS-485/422/232*4，电源*1'
          },
          { label: '参数配置接口：', value: 'RS232和RS485均可' },
          { label: '额定电压：', value: '交流220V/直流48V' },
          { label: '功耗：', value: '40W' },
          { label: '485/232保护：', value: '过流保护、防雷保护、防静电' },
          { label: '电源保护：', value: '防反接、防静电、防浪涌' },
          { label: 'ESD保护：', value: '8KV接触式放电权威测试通过' },
          { label: '资质：', value: 'CE、FCC、ROHS、WEEE、TELEC认证' },
          { label: '工作温度：', value: '-10~55°C' },
          { label: '湿度：', value: '≤ 95%' },
          { label: '尺寸规格：', value: '400mm×200mm×100mm' },
          { label: '抗冲击：', value: '50g加速度' },
          { label: '安装方式：', value: '挂墙安装、平放支架' }
        ]
      }
    ],
    otherData: {
      descList: [
        {
          label: '产品特性',
          textObj: {
            text1:
              '数据转换方向：AI、AO、DI、DO、4*RS-485、RS232、RS422与TCP/IP 双向数据转换。每台设备可横向扩展到48路DI/DO/AI/AO接入端子。数据转换模式：透明转换、透明带标识转换及Modbus协议转换帧流量400帧 /秒。通信网口：具备交换机和路由功能，4路共享10/100M自适应。WiFi/Zigbee无线通讯距离：实测400米开阔视距，封闭无遮挡环境覆盖200-300米，双向透传不丢包。有线传输方式：电力载波',
            text2:
              'DNS/DHCP：支持动态域名和IP自动获取功能。WiFi模式：支持AP模式、STA模式、STA+AP模式。Httpd Client：支持源端口数据直连网页服务器。双向加密验证：配置和通信支持密码双向验证。TCPB功能：支持2条网络链接发送数据。Websocket：支持内置网页与串口双向透传数据。ID注册：支持TCP 建立链接立刻发送 ID 包。专用航空防水插头，实施方便'
          }
        },
        {
          label: '用户场景',
          textObj: {
            text1:
              '协议转换器TURBO-PC用于地下综合管廊、交通枢纽站台、施工现场、其他封闭空间，作为上层处理层设备与终端设备间的数据采集和指令下发的通道，提供数据缓存、断点续传、协议转换、联动控制等功能，可上联捍御者接入控制器设备或其他上层处理设备。',
            text2:
              'TURBO-PC产品便于运维支撑系统通过以太网接口与应用场景内的各类终端设备（温湿度传感器、有害气体传感器、易燃气体传感器、水位检测仪、风机、水泵、门禁、电子井盖等）进行通信，可大幅度降低施工期间的控制线安装部署的难度。',
            text3:
              'TURBO-PC立足专门的的应用场景设计，解决不同厂商的不同终端设备的接入转换和兼容问题，采用平台的网络标准化接入和资源共享化的开发方式，为标准化、网络化、智能化接入终端设备提供了良好的解决方案。'
          }
        }
      ]
    }
  },
  {
    name: 'sensor',
    module: '管廊',
    sortNum: 4,
    devieceData: {
      img: require('@/assets/hardware/sensor.png'),
      zhName: '传感器系列',
      enName: 'TURBO-Sensor',
      content:
        'TURBO-Sensor是捍御者公司自主开发的传感器系列产品，主要针对隧道、综合管廊、地铁以及其它地下空间的环境参数测量使用，包括气体传感器系列（GAS1000）、光亮度传感器（BN1000）和风测传感器（WDS1000）等。'
    },
    introData: {
      label: '产品介绍',
      textObj: {
        text1:
          'TURBO-Sensor BN1000：专用亮度传感器。TURBO-Sensor WDS1000：专用风速风向传感器。TURBO-Sensor传感器产品，系统功耗小，防护等级高，产品可靠度高，使用寿命长，产品安装与维护方便。TURBO-Sensor采用分体式结构的设计思路，将前端传感器探头和后端数据接收/处理/传输部分分开，',
        text2:
          '仅将探头部分暴露在测试环境中，防潮防腐蚀耐用性更好，使用期满后只需更换前端探头，维护成本低。TURBO-Sensor GAS1000传感器系列：包括温湿度传感器、氧气传感器、二氧化碳传感器、硫化氢传感器、甲烷传感器等多种型号，传感器壳体一致性设计，根据需求更换铝合金气室内的传感器探头，即可打造出不同类型的气体传感器设备。'
      }
    },
    paramsData: [
      {
        title: 'GAS1000气体传感器规格参数',
        childrenList: [
          {
            label: '主体材质：',
            value:
              '铸铝铁氟龙烤漆壳体  气室外螺纹M45*1.5  高强度耐磨耐腐蚀铝型材气室'
          },
          { label: '防护等级：', value: 'IP66，Ex d IIB T6 Gb防爆标志' },
          { label: '供电电源：', value: '12～24VDC，≤10W' },
          {
            label: '使用环境：',
            value: '-20℃～+55℃',
            value2: '10%～95%RH（无凝露）'
          },
          { label: '零点漂移：', value: '≤±1%（FS/年）' },
          { label: '信号传输：', value: 'RS485，标准Modbus RTU协议' },
          { label: '报警方式：', value: '声光报警（90分贝）' },
          { label: '尺寸规格：', value: '210mm×175mm×85mm' },
          { label: '重量：', value: '1.8kg' },
          { label: '安装方式：', value: '挂墙安装' },
          { label: '温度：', value: '热电偶，量程-40～125℃，分辨率±0.2℃' },
          { label: '湿度：', value: '聚合物薄膜，量程0～100%RH，±2%RH' },
          { label: '氧气(O2)：', value: '电化学，量程0～30%Vol，0.1%Vol' },
          { label: '二氧化碳(CO2)：', value: '红外，量程0～5%Vol，5ppm' },
          { label: '硫化氢(H2S)：', value: '电化学，量程0～100ppm，1ppm' },
          { label: '甲烷(CH4)：', value: '红外，量程0～100%LEL，1%LEL' }
        ]
      },
      {
        title: 'BN1000光亮度传感器规格参数',
        childrenList: [
          { label: '主体材质：', value: 'ABS' },
          { label: '防护等级：', value: 'IP65，Exibl本安型' },
          { label: '供电电源：', value: '12～24VDC，≤0.4W' },
          { label: '使用环境：', value: '-25℃～+85℃，0%～80%RH' },
          { label: '量程：', value: '0～65535Lux，稳定性5%/年，精度±5%' },
          { label: '信号传输：', value: 'RS485，标准Modbus协议' },
          { label: '重量： ', value: '200g' },
          { label: '安装方式：', value: '挂墙安装、吸顶安装' }
        ]
      },
      {
        title: 'WDS1000风速风向传感器规格参数',
        childrenList: [
          { label: '测量技术：', value: '超声波' },
          { label: '主体材质：', value: '喷塑铝合金壳体' },
          { label: '防护等级：', value: 'IP67' },
          { label: '供电电源：', value: '220VAC，≤5W' },
          { label: '使用环境：', value: '-50℃～+75℃' },
          { label: '测量范围：', value: '-60米/秒～60米/秒， 精度±0.1米/秒' },
          { label: '信号传输：', value: 'RS232/RS485，标准Modbus协议' },
          { label: '安装方式：', value: '隧道壁3米～4米高度安装' }
        ]
      }
    ],
    otherData: {
      descList: [
        {
          label: '产品特性',
          textObj: {
            text1:
              '针对综合管廊、隧道及其它地下空间的传感器，产品种类齐全，传感器具备匹配使用环境的量程与分辨率（精度）。高性能气体传感器模组：通用的电化学系列或红外系列一体化模组，内置温度传感器进行温度补偿，可精确测量环境中各种气体的浓度一流的结构设计，气室采用高强度铝型材，耐磨耐腐蚀，主机外壳采用压铸铝外壳，可抗电磁干扰，适用于复杂恶劣的环境',
            text2:
              '气体传感器整体隔爆式结构设计，可适用于室外、地下空间或其它危险场所，防爆等级为Exd IIC T6GbRS-485 Modbus（独立）标准串行通信协议，配套运维软件，支持远程监测、远程控制、远程报警一次购买，后续只需更换设备的探头部分，维护成本大幅降低'
          }
        }
      ]
    }
  },
  {
    name: 'ap',
    module: '管廊',
    sortNum: 1,
    devieceData: {
      img: require('@/assets/hardware/ap.png'),
      zhName: '智能接入点',
      enName: 'TURBO-AP',
      content:
        'TURBO-AP是捍御者公司自主开发的一款智能接入点产品，产品以智能灯具形态实现融合创新，集成电力载波、LED冷光源、AP+STA模式WIFI、Zigbee自组网、光感应、广角摄像模组、扬声器、人体微波雷达、镭射灯，产品可以复用原有照明线路设计，不需要重复铺设专用供电线，部署简单、设备美观、智能节能、实施便捷。'
    },
    introData: {
      label: '产品介绍',
      textObj: {
        text1:
          '智能接入点TURBO-AP产品采用多种组网方式，高集成度一体化设计，系统功耗小，系统可靠度高，系统安装非常方便。智能接入点TURBO-AP产品专门防潮设计，防护等级高，防浪涌防冲击，抗电磁干扰能力强，产品分模块拆卸维护，维护方便费用更低。',
        text2:
          '接入点按照固定距离线性安装，每个接入点可以作为一个独立探测单元，互不影响，单个接入点故障，不影响整体监控功能，仍然能形成 有效监控区间。产品分为六种型号：TURBO-AP A型、TURBO-AP B型、TURBO-AP C型、TURBO-AP 防爆A型、TURBO-AP 防爆B型、TURBO-AP 防爆C型，根据不同环境与功能需求配置。'
      }
    },
    paramsData: [
      {
        childrenList: [
          { label: '视频：', value: 'CMOS/P/N制式，H.264/H.265视频压缩' },
          {
            label: '镜头：',
            value:
              '2.7-12mm @ F1.4 镜头驱动控制 水平视场角: 113°-33.8°（4/6/8/12mm 定焦可选）'
          },
          { label: '音频：', value: '压缩格式G.711/G.726/MP2L2，1路输出' },
          { label: '最大图像尺寸：', value: '1920×1080' },
          {
            label: 'WIFI参数：',
            value: '5.8G/2.4G频段 802.11a/b/g/n/ac协议，支持AP和STA模式'
          },
          { label: '网络覆盖：', value: '覆盖半径30米，20米内信号覆盖良好' },
          { label: '人体微波雷达有效范围：', value: '20米，定位精度2-5米' },
          { label: '照明发光效率：', value: '80~90lm/w，有效照射范围：8m' },
          { label: '工作电压：', value: 'AC180V~240V' },
          { label: '系统功耗：', value: '50w' },
          { label: '防护等级：', value: 'IP66' },
          { label: '工作温度：', value: '-10℃~55℃' },
          { label: '工作湿度：', value: '10 ~ 95%' },
          { label: '尺寸规格：', value: '900mm*100mm*60mm' },
          { label: '重量：', value: '3.4kg' },
          { label: '安装方式：', value: '挂墙安装或吸顶安装' }
        ]
      }
    ],
    otherData: {
      descList: [
        {
          label: '产品特性',
          textObj: {
            text1:
              '智能监控：摄像头采用高清全景摄像模组实现监控与抓拍，精准人脸识别，摄像模组实时智能监控和识别人员，图像数据实时上传云端。数据传输：数据通过在电力线缆上加载载波，高速稳定传输500Mbps的速率。人员定位：通过一组智能灯具的布放，精确定位与识别人员、无线标记巡更节点',
            text2:
              '无线接入：通过转换实现WIFI信号接入，通过修改协议，实现密码和数据双重校验和加密。多重网络：电力载波、WIFI、Zigbee三重网络。智能感应：人进入产品探测范围，微波探测器工作点亮灯，当人离开探测范围后，灯自动熄灭。无线广播：设备融合扩音器实现自定义广播通知',
            text3:
              '安防监控：通过人体微波雷达，结合二次分析，实现多点入侵、身份识别、精确定位。镭射标识：不同镭射投影图标，用于人员应急疏散指示，专用航空防水插头，实施方便'
          }
        },
        {
          label: '产品功能',
          textObj: {
            text1:
              '产品灵敏度高、可靠性强、易用性强、高性能、高度融合设计，专门针对用户技术先进、性能可靠、节约成本的需求，实现省空间、省能耗、省维保、省工时、省辅材、省人员的目标。智能接入点TURBO-AP立足专门的的应用场景设计，解决了多种功能分散在不同子系统实现，一体化产品有效节省安装空间和辅材，为地下综合管廊、交通枢纽等场景接入终端设备实现高集成度、网络化、智能化提供了良好的解决方案。',
            text2:
              '智能接入点TURBO-AP应用于地下综合管廊、交通枢纽站台、施工现场及其他封闭空间，具有组网、通信、监控、安防和运维五位一体功能的高融合性产品特征。产品基于电力线载波技术，利用现有电力线回路搭建有线宽带局域网，通过人体感应、人员定位、身份识别、摄像、扬声器、应急照明、疏散指示、无线网络覆盖、巡更巡检等各种功能，实现了硬件整合、功能融合。'
          }
        }
      ]
    }
  },
  {
    name: 'ac',
    module: '管廊',
    sortNum: 2,
    devieceData: {
      img: require('@/assets/hardware/ac.png'),
      zhName: '接入控制器',
      enName: 'TURBO-AC',
      content:
        '接入控制器TURBO-AC，是捍御者公司自主研发的接入控制单元产品，产品可以实现对指定区域内的监控数据接入、处理和通信功能。'
    },
    introData: {
      label: '产品介绍',
      textObj: {
        text1:
          '产品主要负责数据采集、控制信号输出和远程通信等任务，负责区域内网络分配管理、Zigbee互联，具备网络视频 NVR能力，处理区域 内多路视频的转码、存储、实时播放，同时智能化处理接入单元数据，与后台交互，实现区域智能控制功能。产品由嵌入式Linux系统、电力载波网关、PLC系统、工业交换机、电源模块、保护模块、安防报警、CPE模块、IPbox语音网关等部分组成。',
        text2:
          '接入控制器TURBO-AC产品采用高集成度一体化设计，系统功耗小，系统可靠度高。接入控制器TURBO-AC产品组网简单，支持多网合一，系统安装维护方便，系统配置及程序更新可通过远端平台操作，数据日志本地存储，软件开放。接入控制器TURBO-AC产品节能环保，专门防爆防潮设计，防护等级高，具备抗电磁干扰能力。'
      }
    },
    paramsData: [
      {
        childrenList: [
          { label: '操作系统：', value: '嵌入式Linux' },
          { label: '支持远程软件升级、重启', value: '' },
          { label: '防护等级：', value: 'IP65' },
          { label: '防爆形式：', value: '本质安全，ExiII' },
          { label: '接口端子：', value: '光口*2，电口*6，电源*1' },
          { label: '存储能力：', value: '6TB容量数据备份' },
          { label: '额定电压：', value: '交流220V' },
          { label: '工作电流：', value: '≤2A' },
          { label: '功耗：', value: '50W' },
          { label: '工作温度：', value: '-10~55°C' },
          { label: '存储温度：', value: '-20~60°C' },
          { label: '湿度：', value: '≤ 95%' },
          { label: '尺寸规格：', value: '530mm×410mm×150mm' },
          { label: '重量：', value: '20kg' },
          { label: '抗冲击：', value: '50g加速度' },
          { label: '安装方式：', value: '挂墙安装、平放支架' }
        ]
      }
    ],
    otherData: {
      descList: [
        {
          label: '产品特性',
          textObj: {
            text1:
              '内置电力载波网关、路由器、光纤环网交换机、开关电源、WIFI、Zigbee网关、核心控制板、NVR服务器、本地硬盘。网络服务：电力线载波网关作为服务器端，支持多达64个电力载波终端接入，提供DHCP服务，自动组网；WIFI网关作为WIFI级联网络服务器，提供WIFI网络DHCP 服务，自动组网；Zigbee网络基于Zigbee技术的无线自组网',
            text2:
              'NVR服务，通过网络接收网络摄像机设备传输的数字视频码流，进行存储、管理，可以同时观看、浏览、回放、存储多个网络摄像机数据。接口适配集成，协议转换设备接入、第三方采集设备接入，对照明、配电、水泵、风机等多种终端的自动控制功能',
            text3:
              '统一采集探针，提供数据总线功能，针对监控系统提供统一采集能力，对各种传感器、网络摄像头、风机、水泵等指标进行采集，汇聚接入并上传给上层应用系统。高度集成融合，提供网关（电力载波网关、WIFI网络网关）、NVR服务、接口适配和统一采集功能'
          }
        },
        {
          label: '应用场景',
          textObj: {
            text1:
              '接入控制器TURBO-AC用于地下综合管廊、交通枢纽站台、施工现场、其他封闭空间的本地监控、本地通信、安全防护、汇聚通信，通过光纤环网上报数据至后台监控中心，可以作为融合通信网关使用，也可替代传统PLC产品使用。TURBO-AC立足专门的的应用场景设计，解决不同厂商的不同产品的兼容和多个系统相互集成的问题，实现平台的网络标准化接入和资源共享化的开发方式，为连接前端接入点设备和后台监控中心提供了良好的解决方案。',
            text2:
              'TURBO-AC可以很好地满足上述场景下的环境与设备监控、通信联络、地理信息、灾难事故预警、安全防范、图像监控需求，实现报警联动、门禁联动、广播、无线网关、电力联动，解决多维度信息互联与设备联动问题，提供本地网络、通信、视频安防、信息发布、入侵检测、设备环境监控、巡更巡检、人员定位及运动轨迹、区域广播等功能。'
          }
        }
      ]
    }
  },
  {
    name: 'band',
    module: '管廊',
    sortNum: 5,
    devieceData: {
      img: require('@/assets/hardware/band.png'),
      zhName: '手环',
      enName: 'TURBO-Band',
      content:
        '智能手环，用户可以记录日常生活中的锻炼、睡眠、部分还有饮食等实时数据，并将这些数据与手机、平板、ipodtouch同步，起到通过数据指导健康生活的作用'
    },
    propertyData: [
      { label: '产品配件：', value: '内置ZIGBEE定位芯片' },
      { label: '产品特性：', value: '内置蓝牙' },
      { label: '产品属性：', value: '三防产品' },
      { label: '产品功能：', value: '身份识别，人员定位' }
    ]
  },
  {
    name: 'tyre',
    module: '仓储',
    sortNum: 1,
    devieceData: {
      img: require('@/assets/hardware/tyre.png'),
      zhName: '智能重力轮胎仓储货架',
      enName: 'TURBO-Newton',
      content:
        '货架是现代化仓库提高效率的重要工具，企业仓储库房所用到的货架种类越来越趋向于自动化、智能化。随着企业的不断增产扩容，企业仓库标准化、高效化也自然而然地提到日程上来了'
    },
    introData: {
      label: '产品介绍',
      textObj: {
        text1:
          '盘点自动化，提升工作效率，轮胎自动翻转。机械自动化取代人力，保障人员安全作业,优化上下架操作流程，提升出入库效率。出入库遵循先进先出原则，实现智能分拣，库房管理可视化，直观掌握库房状态'
      }
    },
    otherData: {
      descList: [
        {
          label: '产品功能',
          textObj: {
            text1:
              '自动传送：升降机轨道和仓门之间安装传送带，实现轮胎自动传送，取代人工搬运，提升出入库效率。自动盘点：通过规范化的轮胎出入库操作流程，在轮胎上下架必经点位获取轮胎和货位信息，自动完成轮胎盘点工作。自动盘点：通过规范化的轮胎出入库操作流程，在轮胎上下架必经点位获取轮胎和货位信息，自动完成轮胎盘点工作。自动翻转：利用重力学原理，轮胎出入库过程中同时完成自动翻转。 自动控制：升降机自动限位，精确定位到达指定货架位置，自动升降货物托盘。'
          }
        }
      ]
    }
  },
  // {
  //   name: 'bc',
  //   module: '建筑',
  //   sortNum: 1,
  //   devieceData: {
  //     img: require('@/assets/hardware/bc.png'),
  //     zhName: '建筑控制器',
  //     enName: 'TURBO-BC',
  //     content:
  //       '建筑控制器TURBO-BC（Building Controller）是捍御者公司自主研发的建筑控制单元产品，产品具备监控数据接入、处理和通信功能，可实现对现代建筑的自动化控制。'
  //   },
  //   paramsData: [
  //     {
  //       childrenList: [
  //         { label: '操作系统：', value: '企业版Linux' },
  //         { label: '数据库：', value: 'MYSQL-E5' },
  //         { label: 'CPU：', value: '2路 1600M Inter-E5' },
  //         { label: '内存：', value: '64GB' },
  //         { label: '存储能力：', value: '1TB容量企业硬盘' },
  //         { label: '网卡：', value: '1000Mbps*2' },
  //         {
  //           label: '管理端口：',
  //           value: '1 x IPMI 2.0',
  //           value2: '1 x DB9（RS232）'
  //         },
  //         {
  //           label: '前置I/O端口：',
  //           value: '4 x USB',
  //           value2: '1 x DB9（RS232）',
  //           value3: '1 x VGA（Matrox G200）'
  //         },
  //         { label: '额定电压：', value: '交流220V' },
  //         { label: '工作电流：', value: '≤2A' },
  //         { label: '电源：', value: '220V交流，250W' },
  //         { label: '工作温度：', value: '5~35°C' },
  //         { label: '存储温度：', value: '-10~55°C' },
  //         { label: '湿度：', value: '10%~ 95%' },
  //         { label: '尺寸规格：', value: '438mm×380mm×43mm' },
  //         { label: '安装方式：', value: '机架式安装' }
  //       ]
  //     }
  //   ],
  //   introData: {
  //     label: '产品介绍',
  //     textObj: {
  //       text1:
  //         'TURBO-BC采用了云计算的高可用性和负载均衡技术，多TURBO-BC可组成Server Pool，软件模块实现控制功能和控制逻辑，不但横向管理规模扩展无限制，同时消除了传统方案网络控制设备的单点故障，提高了系统的健壮性。建筑控制器TURBO-BC产品采用捍御者自有的BA控制方案，组网简单，采用高集成度一体化设计，系统功耗小，节能明显，系统可靠度高，施工方便，集成维护简单。',
  //       text2:
  //         'TURBO-BC是高性能建筑控制系统的专用设备，产品充分表达了SDB（软件定义建筑）思想，基于容器和微服务技术，实现了BFV（建筑功能虚拟化），将建筑自动化控制（ HVAC、能源管理等）、出入口控制、电梯控制、远程抄表、照明回路控制、停车及正反向引导、权限管理、告警管理等功能集中统一起来，以软件形态和虚拟化方式集中在TURBO-BC中体现。'
  //     }
  //   },
  //   otherData: {
  //     descList: [
  //       {
  //         label: '系统组网',
  //         textObj: {
  //           text1:
  //             'TURBO-BC改变了传统BA实现方式，在终端层有线设备使用RS485协议，无线设备采用Zigbee协议，水电热气仪表统一采用Lora协议，统一通过TURBO RAS-66C64采集控制器汇聚转换，向上接入TURBO-BC单元，实现统一控制与管理。捍御者针对建筑控制提出的新一代解决方案，采用两级架构，将硬件种类简化为TURBO-BC（建筑控制器）和TURBO RAS-66C64（分布式采集及控制器）。'
  //         }
  //       },
  //       {
  //         label: '应用场景',
  //         textObj: {
  //           text1:
  //             '建筑控制器TURBO-BC面向商业综合体等现代化楼宇建筑，作为建筑智能化系统的核心，以“为业主和用户提供可靠、安全、节能、舒适、便捷的工作生活环境”为着眼点，提供高性能建筑控制系统的专用设备，力求使整个建筑设备处在最佳的工作状态。捍御者建筑智能化解决方案将传统楼控系统的三层网络架构简化为两层架构，省去各种单一功能的网关设备，硬件种类与数量简化，通过软件方式定义建筑内的控制功能。',
  //           text2:
  //             'TURBO-BC可通过高频次的建筑运行历史数据挖掘，提供智能控制算法，实现如电梯闲时待命楼层、通风时长与间隔、室内温湿度自动调节、停车场车位优先级调度、照明/视频休眠时间等功能，控制中心提供流程与数据的深度学习能力，设置和优化相关事件或设备的关联关系，实现单体建筑或建筑群安防、环境、设备等的联动与系统管理。'
  //         }
  //       }
  //     ]
  //   }
  // },
  {
    name: 'dcc',
    module: '建筑',
    sortNum: 2,
    devieceData: {
      img: require('@/assets/hardware/dcc.png'),
      zhName: '分布式采集及控制器',
      enName: 'TURBO RAS-66C64',
      content:
        'TURBO RAS-66C64（Distributed coleector&controller），是捍御者公司开发的一款分布式数据采集、协议转换和数据控制器产品， 提供多路接入、高效实现以太网接口与AI、AO、DI、DO、RS-485、RS-232、RS-422 、网口、热敏输入、微压输入接口的协议转换，实现接入智能化、协议标准化、组网IP化。'
    },
    introData: {
      label: '产品介绍',
      textObj: {
        text1:
          '捍御者公司将传统BACNET标准中的FLN、ALN、MLN做了简化与重构，抛弃了基于总线技术的低速率BACNET Over MSTP协议栈，采用TURBO RAS-66C64产品实现对各种终端设备的统一接入，并基于BACNET Over IP协议，实现与TURBO-BC群之间的高速稳定通信，从而将ALN与MLN合二为一。',
        text2:
          'TURBO RAS-66C64产品的软件开发采用Golang、时序数据库和分布式消息总线，实现大并发的实时处理，适合数量庞大的前端设备的并发数据处理。TURBO RAS-66C64产品采用高集成度一体化设计，采集数据统一输出，扩展、配置便利，对于底层传感器和执行器设备适配能力宽泛，系统功耗小，系统可靠度高，系统安装维护方便。'
      }
    },
    paramsData: [
      {
        childrenList: [
          { label: '操作系统：', value: '嵌入式Linux' },
          {
            label: '接口种类：',
            value: 'AI/AO/DI/DO/RS485/RS232 网口/热敏输入/微压输入'
          },
          { label: '接入端子：', value: '支持48路DI/DO/AI/AO' },
          { label: '参数配置接口：', value: 'RS232和RS485' },
          { label: '额定电压：', value: '交流220V/直流48V' },
          { label: '功耗：', value: '60W' },
          { label: '485/232保护：', value: '过流保护、防雷保护、防静电' },
          { label: '电源保护：', value: '防反接、防静电、防浪涌' },
          { label: 'ESD保护：', value: '8KV接触式放电权威测试通过' },
          { label: '资质：', value: 'CE、FCC、ROHS、WEEE、TELEC认证' },
          { label: '工作温度：', value: '-10~55°C' },
          { label: '湿度：', value: '10%~ 95%' },
          { label: '尺寸规格：', value: '600mm×800mm×200mm' },
          { label: '重量：', value: '12kg' },
          { label: '安装方式：', value: '机柜安装或平放支架' },
          { label: '支持远程软件升级、重启', value: '' }
        ]
      }
    ],
    otherData: {
      descList: [
        {
          label: '产品特性',
          textObj: {
            text1:
              '用以替代传统楼控系统中的DDC和IOM产品。数据转换：AI/AO/DI/DO/RS485/RS232/网口/热敏输入/微压输入，实现与TCP/IP 双向数据转换TURBO-DDC采用模块化设计思路，内置双路RS485总线控制器，可基于具体使用场景需要的传感器和控制终端，定制和扩展接口类型和接口数量',
            text2:
              '输入端子数量：单台可达48路。数据转换模式：透明转换、透明带标识转换及Modbus协议转换帧流量400帧 /秒。通信网口：具备交换机和路由功能，多路共享100M自适应。组网架构简单，减少了原有的楼控系统组网层级。端口配置简单，不同类型的前端设备统一接入，无需分别使用专用的转换器、执行器设备。硬件接线标准化，实现各协议转换成IP网络，DCC直接向上透传数据。施工连接简单，分片区就近接入，可实现快速施工和接线'
          }
        },
        {
          label: '应用场景',
          textObj: {
            text1:
              '捍御者分布式采集及控制器TURBO RAS-66C64用于智能建筑智能化系统，作为上层处理层设备（BC）与终端设备之间的数据采集、协议转换和指令下发通道，提供数据缓存、断点续传、协议转换、联动控制等功能，直接上联建筑控制器TURBO-BC设备。TURBO RAS-66C64产品采用先进的电子技术和结构化消息语言，实现了工业网络全以太网化的结构',
            text2:
              'TURBO RAS-66C64产品便于建筑控制器通过以太网接口与应用场景内的各类终端设备（门禁、前端气体液体传感器、风机、水泵、报警控制开关、水表电表、配电柜、空调控制器、照明控制器及其他执行器设备等）进行通信采用“软件定义硬件”的方式，接口横向扩展能力强，可灵活调配各种适配接口的配比数量，以方便应用于各种应用场景。'
          }
        }
      ]
    }
  },
  {
    name: 'monitor',
    module: '建筑',
    sortNum: 6,
    devieceData: {
      img: require('@/assets/hardware/monitor.png'),
      zhName: '玄武系列摄像头',
      enName: 'TURBO-O/O2/XO',
      content:
        'TURBO-O/O2/XO是捍御者公司自主开发的“玄武”系列的前端摄像头，从智能建筑的安防监控、车位引导、水牌、电梯间视频、大屏监控，到地铁与高速公路的安防监控、信息屏、人脸识别与车辆识别，再到综合管廊的安防监控、身份识别，均可采用本系列的摄像头产品。'
    },
    introData: {
      label: '产品介绍',
      textObj: {
        text1:
          'TURBO-O、TURBO-O2、TURBO-XO系列摄像头，模具创意为向“欧几里德”致敬，选用方、圆、椭圆、三角等几何原本基本定义，通过不同模块组合形成产品系列。产品采用结构化设计，一套模具三款产品，体积小功能强，安装设计具备隐秘性，特点鲜明，差异化特征明显，广受用户认可。TURBO-O为单头O型，单头监控。TURBO-O2为双头O2型，对向或L型直角监控，双电机驱动球机，用单处理器处理两路镜头模组',
        text2:
          'TURBO-XO为单头XO型，球机监控,应用于园区、楼宇、管廊、地铁、高速及其它场景的视频监控、视频/图片抓拍，图像清晰、细腻。后台视频管理系统为分布式系统，支持摄头数量的无限水平扩展，支持远程抓拍和本地抓拍，支持实时视频流的18类场景分析，场景事件触发式推流和回放，异常场景及时告警及智能定位。支持FLV、RTSP、RTMP等多种视频流协议，可基于Android、IOS、 Web、微信小程序的流媒体播放。'
      }
    },
    paramsData: [
      {
        childrenList: [
          {
            label: '产品类别：',
            value: '分为单头O型 XO型（球机）',
            value2: '双头双向O2型（对向或L型直角监控）'
          },
          {
            label: '摄像机镜头控制：',
            value: '球机旋转角度80度',
            value2: '云台旋转角度352度'
          },
          {
            label: '视频处理：',
            value:
              'H.265++/H.265+/H.265/H.264视频压缩 超低码率，高清画质，支持双码流'
          },
          {
            label: '镜头：',
            value:
              '2.8-12mm @ F1.4 水平视场角: 113°-33.8°(4mm、6mm、8mm、12mm 定焦可选）'
          },
          { label: '快门：', value: '1/50(1/60)秒至1/10000秒' },
          { label: '图像尺寸：', value: '最大1920×1080，200万像素' },
          { label: '降噪：', value: '支持2D/3D降噪，数字宽动态' },
          { label: '网络接口：', value: 'RJ45网络接口或无线WIFI' },
          { label: '工作电压及功耗：', value: 'DC 12V，7W' },
          { label: '防雷保护：', value: 'GB/T17626.5，国际标准IEC61000-4-5' },
          { label: '工作温度：', value: '-10℃~60℃' },
          { label: '工作湿度：', value: '10 ~ 95%' },
          { label: '尺寸规格：', value: '82.5mm*74.5mm*84mm' },
          { label: '尺寸规格：', value: '900mm*100mm*60mm' },
          { label: '重量：', value: '0.2kg' },
          { label: '安装方式：', value: '墙体、杆体、天花固定安装' }
        ]
      }
    ],
    otherData: {
      descList: [
        {
          label: '产品特性',
          textObj: {
            text1:
              '协议先进，支持RTMP、RTSP、Flash Video，RTMP协议比传统方案使用的RTSP协议占用带宽更低、更稳定，尤其是视频信息发布场景优势显著。其中，FLV视频文件所需存储只占传统方案的1/15～1/3，一部1.4GB的电影使用FLV视频，只需要100MB。便捷易用，支持云台控制、焦距缩放、全网页面配置，存储空间大幅降低，采用H.265编解码，视频存储轻量化改进，使后台视频存储量降低至传统方案的50%以上',
            text2:
              '场景识别多样，支持基于神经网络“人体骨架”算法实现人体动作识别，用于实时视频分析，将数据处理量压缩到传统方案的百分之一，可实现区域监控、人数统计、人群聚集、人员摔倒、斗殴、电梯逆行、可疑物品遗留等场景识别和人员动作识别。H.265码流编码、传输与解码，大大降低带宽，闲时带宽占用小于50KB/S，单个摄像头后台磁盘存储需求，比传统方案降低50%以上，每天低至4-8GB'
          }
        }
      ]
    }
  },
  {
    name: 'access',
    module: '建筑',
    sortNum: 7,
    devieceData: {
      img: require('@/assets/hardware/access.png'),
      zhName: '门禁一体机',
      enName: 'TURBO-Access Control System',
      content:
        'TURBO-Access Control System是捍御者公司自主开发 的出入口控制系统，该产品针对园区、建筑楼宇的 各类出入口(大门、人行道闸、通道门等)的人员 进出实现认证、放行、拒绝、记录、报警、考勤统 计等功能，确保区域安全，实现出入口的智能化管理。'
    },
    introData: {
      label: '产品介绍',
      textObj: {
        text1:
          'TURBO-Access Control System默认支持IC卡、手机二 维码和人脸识别方式的出入认证，通过手机微信小 程序完成与物业管理的联动，同时具备访客授权、 代客放行、访客调梯等必备功能，支持灵活的身份 授权，支持报表统计和考勤记录查询。TURBO-Access Control System是捍御者建筑楼宇 智能一体化系统的一个子系统，既可与捍御者建筑 智能化平台的其他子系统有机结合、智能联动，亦 可独立安装使用',
        text2:
          '出入口控制系统产品采用捍御者一贯的结构化、简约化设计风格，前端设备分为分体式道闸刷卡机、人脸识别终端、通道门一体式刷卡读头，体积小功能强性能优越，设计考虑安装实施的隐秘性要求，与各种装修风格搭配均不违和，广受用户认可。',
        text3:
          '出入口控制系统秉承“软件定义建筑”思想，除了后台管理软件和手机小程序之外，在每种前端设备中均内置了嵌入式软件程序，易于修改和升级，更好地与后台实现出入口一体化控制功能，实现了出入口控制的产业化升级，凸显人员出入管理的安全与效率，为智慧化建筑与园区运营提供了门禁前端与后端的有力支撑条件。'
      }
    },
    paramsData: [
      {
        childrenList: [
          {
            label: '读头容量',
            value: '支持IC卡8.5万张，支持二维码10万次超过容量自动循环覆盖存储'
          },
          { label: '摄开门方式:', value: 'IC卡、条形码、二维码' },
          {
            label: '设备输入:',
            value: '12V直流电源输入、1路门磁输入1路开门按钮输入'
          },
          {
            label: '设备输出:',
            value: '1路250V/3A继电器输出控制磁力锁/开门器/水阀/灯具'
          },
          { label: '工作电流:', value: '<600mA' },
          { label: '读卡速度:', value: '<0.2秒;读二维码速度:<0.5秒' },
          { label: '刷卡间隔:', value: '<0.5秒' },
          { label: '尺寸规格:', value: '88mm*86mm*12mm' },
          { label: '工作电压及功耗：', value: 'DC 12V，7W' },
          { label: '防雷保护：', value: 'GB/T17626.5，国际标准IEC61000-4-5' },
          { label: '工作温度：', value: '-10℃~60℃' },
          { label: '工作湿度：', value: '10 ~ 95%' },
          { label: '尺寸规格：', value: '82.5mm*74.5mm*84mm' },
          { label: '设备规格尺寸:', value: '113mm*113mm*82mm' },
          { label: '安装方式:', value: '墙体、杆体、天花固定安装' },
          { label: '人脸库存储量:', value: '20000人脸库离线存储识别' },
          { label: '供电电源:', value: '5VDC，工作电流0.8A~2A' },
          { label: '图像尺寸:', value: '最大1920×1080，200万像素' },
          { label: '人脸识别速率:', value: '跟踪检测10ms，检测识别180ms' },
          { label: '刷脸时间间隔:', value: '1秒(根据不同可修改时间)' }
        ]
      }
    ],
    otherData: {
      descList: [
        {
          label: '产品特性',
          textObj: {
            text1:
              '自主开发的嵌入式软件前端控制刷卡读头，刷IC卡及二维码速度高于业界平均水平。无电池供电，不需更换电池，符合规约的动态加密二维码，密匙可修改，无后 门漏洞，支持二维码脱机离线开门策略。产品扩展支持WIFI与蓝牙通讯，继电器时延可设置，接口过流过压保护，屏幕识别前端设备和隐蔽式摄像头前端设备可选',
            text2:
              '非接触智能交互，识别速度快，用户接受程度高，双目活体识别，防照片、防视频攻击，算法优化，识别无需脱眼镜，成功率在99%以上。强光条件下准确识别，弱光、夜间环境准确识别，接口支持开关量、韦根、RS232，双重硬件防死机，设备掉电不丢失存储数据'
          }
        }
      ]
    }
  },
  {
    name: 'parking',
    module: '建筑',
    sortNum: 8,
    devieceData: {
      img: require('@/assets/hardware/parking.png'),
      zhName: '道闸人脸识别终端',
      enName: 'TURBO-Parking Guidance System',
      content:
        'TURBO-Access Control System是捍御者公司自主开发 的出入口控制系统，该产品针对园区、建筑楼宇的 各类出入口(大门、人行道闸、通道门等)的人员 进出实现认证、放行、拒绝、记录、报警、考勤统 计等功能，确保区域安全，实现出入口的智能化管理。'
    },
    introData: {
      label: '产品介绍',
      textObj: {
        text1:
          '停车场道闸采用一体化机芯，摆杆离合式设计，使 用寿命长，高清晰摄像机低照度效果好，前端车牌 识别算法识别率接近100%。反向引导采用自主研发的“玄武”摄像头，单头和双头拍照设计，采集停车数据准确，对车辆定位和寻车引导路线清晰明确，成功率高。',
        text2:
          '手机停车小程序与停车场及反向引导管理后台软件 通信，支持多种计费模式(VIP车位、固定车位、临 时停车、优惠停车、储值卡停车、无牌车等)，支持车辆停放位置反向查找、路线引导功能，缴费金 额不经任何中间环节直达业主账户，停车数据只存 留在业主方保证数据私密。',
        text3:
          '捍御者停车场管理及车辆反向引导系统采用的是无人值守、无卡管理模式，停车管理、收费和反向寻车均依靠系统用手机小程序自动完成，避免了人为差错。同时关注停车场用户的使用感知，为系统发生紧急故障提供了可靠的应急处理方式，不会因为无人值守导致车辆拥堵，实现快速通行。'
      }
    },
    paramsData: [
      {
        childrenList: [
          {
            label: '视频处理:',
            value:
              'H.265++/H.265+/H.265/H.264视频压缩超低码率，高清画质，支持双码流'
          },
          {
            label: '道闸摄像最小照度:',
            value: '彩色0.0021Lux@(F1.2,AGCON)',
            value2: '黑白0.00021Lux@(F1.2,AGCON)'
          },
          { label: '图片格式:', value: '采用JPEG编码，图片质量可设' },
          { label: '补光灯:', value: '自动光控、时控可选，常亮或过车闪' },
          { label: '车牌识别处理时间:', value: '小于等于0.1秒' },
          { label: '道闸串口:', value: '1个RS-485+1个RS-232' },
          { label: '继电器输出:', value: '3路继电器,支持道闸开、关、停' },
          { label: '工作温度湿度:', value: '-30°C~70°C,湿度小于90%(无凝结)' },
          { label: '电源:', value: '交流220V，每道闸功耗为22W' },
          { label: '防护等级:', value: 'IP67' },
          { label: '道闸机箱尺寸规格:', value: '309mm*381mm*179mm' },
          { label: '引导摄像机尺寸规格:', value: '82.5mm*74.5mm*84mm' },
          {
            label: '引导摄像机类型:',
            value: '单头O型、双头双向O2型内置嵌入式控制软件'
          },
          { label: '引导摄像机网络接口:', value: 'RJ45网络接口或无线WIFI' },
          { label: '引导摄像机工作电压及功耗:', value: 'DC 12V，7W' },
          { label: '', value: '' }
        ]
      }
    ],
    otherData: {
      descList: [
        {
          label: '产品特性',
          textObj: {
            text1:
              '整个系统可实时监测设备状态，实现各个设备间的闭环控制，杜绝非法开闸及异常开闸，同时解决了车辆跟随 出入场问题。相关摄像机采用H.265编解码，带宽占用小，存储 轻量化改进，后台数据存储量降低50%，车牌识别准确率高，场内外车牌识别率达99.8%',
            text2:
              '道闸采用一体化机芯就，摆杆离合式设计，停电 后手动快速升杆，支持道闸脱机管理(脱机进出和脱机收费)，脱 机状态下显示屏、语音正常工作。支持停车场无人值守模式，使用手机停车小程序 扫描二维码实现费用支付',
            text3:
              '和车辆停靠位置反向查找，所有缴费金额直达业主账户，不经捍御者账户',
            text4:
              '支持车辆黑白名单控制，联动道闸，采用光电隔离和防雷处理，确保了系统的可靠性，抬落杆次数在200万次以上，无牌车通过扫描二维码形成临时车牌，快速通过'
          }
        }
      ]
    }
  },
  // {
  //   name: 'information',
  //   module: '建筑',
  //   sortNum: 6,
  //   devieceData: {
  //     img: require('@/assets/hardware/information.png'),
  //     zhName: '多媒体信息终端',
  //     enName: 'TURBO-Information Issuing System',
  //     content: 'TURBO-Information Issuing System是捍御者公司自主 开发的信息发布系统。在智能建筑领域，从电子水牌、全彩屏、电梯间显示屏、拼接大屏，到地下车 位引导的引导屏，均可采用本产品发布信息。'
  //   },
  //   introData: {
  //     label: '产品介绍',
  //     textObj: {
  //       text1: '素材多样:包括天气预报、日期时间、各类LOGO、 字幕、音频、视频、图片、文字、实时信息、Office 软件、网页信息、直播信息流、广电信息源等。高扩展性:能够根据需求扩展更多的外设设备，触摸屏:支持多点电阻触摸、USB 多点红外触摸、 多点电容触摸、多点纳米膜触摸、多点声波触摸、 多点光学触摸',
  //       text2: '版面设计制作和播放内容设置，均可根据宣传需 要自定义模板，拖拽式设定，可预览效果，信息发布的时间、终端可选择，可调度管理，可 即时发布，也可定时发布。接口设备:支持USB 摄像头，红外遥控器，HDMI， 兼容 HDMI1.4/2.0，eDP 接口显示屏，LVDS 接口显示 屏 6 个 USB HOST/1 个 USB OTG 3 组串口，支持外接 串口设备模块(3/4G 模块、NFC 模块、打印机、刷 卡器、扫描仪、身份证识别等等) 4 组 IO检测口， 最大支持 32GBTF 卡，D class 超大内置喇叭，支持耳 机接口和麦克风',
  //       text3: '多媒体信息发布管理软件平台，实现对多媒体信 息的编辑、管理、控制和播放，可任意组合播放， 主要功能分为用户权限、素材组织、设计制作、发 布管理、播放终端管理等。高清分辨率:视频解码能力为1080P高清，支持 4K，显示效果多彩炫丽，显示片区可定义可划分，如视频区、图片区、滚 动信息区等，可组合播放多种类型信息，位置自由 调整',
  //       text4: '支持所有主流的信息发布载体接口和信息格式，高稳定性: 核心电路板为安卓一体板，在硬件、 软件上，增加捍御者独有技术来保证产品的稳定性， 可使产品做到 7*24 小时无人值守。高集成度:集成了千兆以太网、WiFi、蓝牙、6W 功放、TF 卡扩展、 内置 3G/4G 模块接口、HDMI 输 入、IR 遥控功能、HDMI 输出、LVDS、eDP、麦克风、 重力感应、GPS 等功能，简化了整机设计'
  //     }
  //   },
  //   paramsData: [
  //     {
  //       childrenList: [
  //         { label: '操作系统: ', value: 'Google Android安卓' },
  //         { label: '支持远程升级 和系统崩溃自恢复功能', value: '' },
  //         { label: '尺寸规格:', value: '147.5mm*122.5mm' },
  //         { label: '显示屏接口:', value: 'eDP 接口，LVDS 接口' },
  //         { label: '支持7“-108”显示屏', value: '' },
  //         { label: '内置存储容量:', value: 'eMMC 8GB/16G/32G/64G' },
  //         { label: '视频格式:', value: '1080P多视频解码，4K*2K 输出' },
  //         { label: '支持H.2645/H.264/VP8/MAV/WMV/AVS/MPEG4', value: '' },
  //         { label: '音频格式:', value: 'MP3/WMA/WAV/ APEFLAC/ AAC/OGG /M4A/3GPP' },
  //         { label: '网络接口:', value: 'RJ45网络接口或无线WIFI或蓝牙 具备3G和4G移动通信功能' },
  //         { label: '工作电压及电流: ', value: 'AC100-240V输入，DC12V输出工作电流2A ~ 5A' },
  //         { label: 'CPU:', value: '瑞芯微 RK3288 四核 Cortex-A17+四核 GPU Mail-T764，内存DDR3 8GB' },
  //         { label: '设备外形:', value: '分为播放终端内置主电路板播放盒两种产品形态' },
  //         { label: '支持双屏异显功能', value: '' },
  //         { label: '支持HDMI3840*2160输出', value: '' },
  //         { label: '', value: '' }
  //       ]
  //     }
  //   ],
  //   otherData: {
  //     descList: [
  //       {
  //         label: '产品特性',
  //         textObj: {
  //           text1: '自主研发的信息发布硬件，适配接口齐全，音频视 频格式覆盖度高，数据编解码效率高，占用带宽小， 清晰度高，干扰度极低，可外放多种显示设备，包 括电视机、显示器、大屏幕、拼接屏、LED条形屏、 投影仪及其它输出设备。',
  //           text2: 'TURBO-Information Issuing System产品由信息发布主 板硬件、嵌入式软件和后台信息发布管理软件组成， 主要用于媒体播放、信息展示、广告宣传，它基于 先进的网络传输技术和视频解码能力，将汇聚在中 心服务器的各类信息(如视频、图片、文本、数据、 流媒体等)通过网络按照用户规划的播放区域、播 放时间、显示内容，直观、美观地进行展现。'
  //         }
  //       }
  //     ]
  //   }
  // },
  {
    name: 'gateway',
    module: '建筑',
    sortNum: 5,
    devieceData: {
      img: require('@/assets/hardware/gateway.png'),
      zhName: 'LORA网关',
      enName: 'TURBO-LORA Gateway',
      content:
        'LORA网关是基于物联LORAWAN无线物联技术自主研发，融合无线通讯技术以及常用有线数字通讯技术，物理层加密和数据层加密技术，超低功耗技术，嵌入式边缘计算等核心技术。实现与无线终端的数据采集，和与物联网后台系统的中继传输。是新一代的智能物联网网关。并兼具多信道，兼容性，自适应，多种输出模式，多种中继传输模式为一体。具有灵活的自组网和安全传输无线通讯性能，采用一体化设计，耐高低温。'
    },
    introData: {
      label: '产品介绍',
      textObj: {
        text1:
          '室外型LORAWAN网关是一款基于低功耗广域网LORAWAN协议的室外网关，IP67级防水外壳，支持PoE供电，可连接标准的LORAWAN终端并进行双向通信。网关通过标准的以太网将网关设备连接到NS(网络服务器），支持网络和防火墙功能。  同时此网关也支持4G/LTE,GPRS,2.4Gwifi等多种通信协议，实现数据上传。',
        text2:
          '内置OpenWRT操作系统，用户通过Web管理页面可以灵活配置网络参数和LORAWAN协议参数。支持MQTT Bridge功能，可通过MQTT integration到第三方平台。',
        text3:
          '可连接标准的云端NS(网络服务器）和本地NS,同时也支持内嵌NS,无需用户在云端和本地部署NS.特别适合于行业应用中小型化的场景，节省数千元的服务器成本和研发投入，并且具有执行效率高，延时更短的优点。',
        text4:
          '集成LORA集中器网卡，最多可扩展支持2张LORA集中器网卡，实现16个上行接入通道和2个下行发送通道，对于SX1301集中的运行发热问题，此网管在制作过程中采用石墨烯，硅脂等工艺优化芯片和模块的散热功能，保证传输的稳定性。'
      }
    },
    functionData: {
      label: '产品功能',
      textObj: {
        text1:
          '广覆盖半径，可实现城市5KM,郊区15KM的广域覆盖。支持PoE供电，IEEE 802.3af/at兼容class4，48V。支持全双工上下型，最大可支持16个上行通道，2个下行发送通道。支持节点漫游，节点在网络范围内可自由移、动，支持有效过滤非法节点。具有丰富的企业网软件特性，内嵌标准的LORAWAN协议，支持WebUI配置和管理，网络路由和防火墙功能，支持MQTT Bridge。'
      }
    },
    paramsData: [
      {
        title: '产品规格',
        childrenList: [
          { label: '使用环境：', value: '城市级，户外部署' },
          { label: '安装方式：', value: '抱杆式，附墙式' },
          { label: '户外防护：', value: '防水防尘，防雷保护，防腐蚀' },
          { label: '最大接入点：', value: '10万个' },
          { label: '车牌识别处理时间:', value: '小于等于0.1秒' },
          { label: '道闸串口:', value: '1个RS-485+1个RS-232' },
          { label: '继电器输出:', value: '3路继电器,支持道闸开、关、停' },
          { label: '工作温度湿度:', value: '-30°C~70°C,湿度小于90%(无凝结)' },
          { label: '工作模式：', value: '全双工/半双工' },
          {
            label: '工作半径：',
            value:
              '市区5KM,郊区10KM+，LORAWAN标准协议，SF可配置功能，watchdog管理',
            col: 2
          },
          { label: '多种调试界面：', value: 'COM连接，WIFI连接' },
          {
            label: '软件特性：',
            value:
              '支持内嵌式LORAWAN Server，MOTT connect with LoRa NS,Cellular 4G 模块APN设置，网络诊断与检测，Web页面中英文切换',
            col: 4,
            backgroundColor: '#f8f8f8'
          },
          {
            label: '软件功能:',
            value:
              'LORA功能：支持Class A,B,C，LORA报文转发，发射功率设置，支持统计功能，Data logger，位置设置，Server地址和端口设置。设备管理：WEB管理界面，支持SSH2,支持固件更新，支持NTP网络功能：支持WIFI AP模式，支持LTE APN设置，支持上行链路备份，支持802.1q，支持DHCP服务器端/客户端，支持路由器模块NAT,支持防火墙',
            col: 4,
            backgroundColor: '#fff'
          }
        ]
      },
      {
        title: '产品参数',
        childrenList: [
          { label: 'CPU：', value: 'MT7628,DDR2RAM 128MB' },
          { label: '供电：', value: 'POE(IEEE 802.3af），42-57VDC' },
          { label: '功耗:', value: '12W(典型)' },
          { label: '以太网：', value: 'RJ45(10/100M)' },
          { label: '天线：', value: '5个N型天线接口' },
          { label: '防护：', value: 'IP67' },
          { label: '外壳材料：', value: '铝' },
          { label: '重量：', value: '3.15千克' },
          { label: '尺寸：', value: '220mmx220mmx104mm' },
          {
            label: 'WIFI特性：',
            value:
              '频段：2.400-2.4835GHz（802.1b/g/n） 接收灵敏度：-95dBm（Min） 发射功率：20dBm（Max）',
            col: 3
          },
          {
            label: 'LoRa特性：',
            value:
              'SX1301 Mini PCIe卡（最多可接两个卡） 8通道（可选16通道） 接收灵敏度：-142dBm（Min） 发射功率：27dBm（Max） 支持频段：CN470',
            col: 4,
            backgroundColor: '#f8f8f8'
          }
        ]
      }
    ],
    otherData: {
      descList: [
        {
          label: '产品优势',
          textObj: {
            text1:
              '硬件设计：独特的硬件设计保证LORA特性，特别是在干扰情况下也可以稳定的传输。软件设计：采用图形化的WebUI进行相关的配置及状态查询，配置和查询简单，直观。功能设计：支持MQTT Bridge功能，内置NetWorK Server，用户可根据需求选择云端公共NS或连接本地NS,也可以选择网关内置NS。'
          }
        }
      ]
    }
  },
  {
    name: 'lighting',
    module: '建筑',
    sortNum: 4,
    devieceData: {
      img: require('@/assets/hardware/lighting.png'),
      zhName: '照明控制器',
      enName: 'TURBO RAS-SE-LC8S-DC(AC)',
      content:
        '8路智能照明模块包括一个 32 位的微处理器、并集成1路RS485接口或LORA通信接口。可接入RS485总线或LORAWAN网络。 '
    },
    introData: {
      label: '产品介绍',
      textObj: {
        text1:
          '8路智能照明模块是捍御者公司对智能楼宇开发的智能型照明控制模块，可对现场的照明设备进行实时控制好达到照明控制人性化，改善工作环境，延长灯具使用寿命，节约能源的目的。'
      }
    },
    functionData: {
      label: '产品功能',
      textObj: {
        text1:
          '具有8路受控回路，每路触点切换能力达到20A。每条回路都可手动控制，远程服务器控制。每条回路具有回检功能，此功能主要是检测继电器是否闭合，给控制和执行回路形成一个闭环控制，提高产品的可靠性。带回路状态反馈指示灯，实时指示回路状态、485通信指示灯实时检测设备是否在线，电源指示灯，设备运行指示灯，告警指示灯。',
        text2:
          '具有消防联动，消防到来，模块可强制打开所有回路，切换到应急状态。与后台通信方式RS485或lora无线，后台可实时对设备的状态进行监测，和控制。支持本地全开，全关控制，方便外接设备来控制此设备和设备的现场监测。供电电源DC12V或220V。模块背板上的安装夹和 DIN 导轨，轻松地将控制器安装到水平切面为长 35 毫米的 DIN 导轨 。'
      }
    },
    paramsData: [
      {
        title: '产品参数',
        childrenList: [
          { label: 'CPU：', value: 'Cortex-M3 STM32F103 72MHz运行频率' },
          { label: '内存：', value: '64KB Flash， 20KB的SRAM' },
          { label: '串口通信：', value: '1路 全双工 RS485接口' },
          { label: '无线通信：', value: '1路 LORA 无线通信接口' },
          { label: '车牌识别处理时间:', value: '小于等于0.1秒' },
          { label: '道闸串口:', value: '1个RS-485+1个RS-232' },
          { label: '继电器输出:', value: '3路继电器,支持道闸开、关、停' },
          { label: '工作温度湿度:', value: '-30°C~70°C,湿度小于90%(无凝结)' },
          { label: '电源和功耗规格：', value: 'DC12V 可选配AC220V  4W' },
          { label: '数字输出：', value: '继电器输出常开' },
          { label: '数字输入：', value: '湿节点模式' },
          { label: '消防接口：', value: '1路24V消防接口' },
          { label: '安装方式：', value: '导轨或支架安装' },
          { label: '工作环境：', value: '-10℃～+55℃' },
          { label: '尺寸（长 × 宽 × 高）：', value: '180mm*88mm*65mm', col: 2 },
          {
            label: 'RS485通信协议：',
            value: '自定协议和 Modbus 协议(ASCII、RTU、Modbus TCP)完美支持',
            col: 2,
            backgroundColor: '#fff'
          },
          {
            label: 'LoraWAN 通信协议：',
            value: '标准loraWAN通信协议，可以接入标准的loraWAN网关',
            col: 2
          }
        ]
      }
    ],
    otherData: {
      descList: [
        {
          label: '产品优势',
          textObj: {
            text1:
              '现场运维便捷：每一个输出控制通道都有手动控制功能和状态指示灯，设备运行状态一目了然，故障排查直观便利。同时每个控制回路都具有回检功能，在后台就可以看到设备控制状态。',
            text2:
              '安装部署特点：DN35导轨式安装，方便书快捷，提供可选的无线通信，没有现场布线的麻烦，方便快捷安装和调试',
            text3:
              '数字输入:两路数字输入，方便外部设备的接入和控制。IO端口保护：设备全端口具有完善的过流过压防反接等保护',
            text4:
              '远程维护升级：可通过远程升级及支持专用软件进行本地和远程参数配置及维护'
          }
        }
      ]
    }
  },
  {
    name: 'temperature',
    module: '建筑',
    sortNum: 9,
    devieceData: {
      img: require('@/assets/hardware/temperature.png'),
      zhName: '温控面板',
      enName: 'TURBO-Temperature Control Panel',
      content:
        'LORA温湿度采集器是捍御者公司针对智能楼宇的温湿度采集开发的设备，可以实时监控现场的温度和湿度，然后通过LORA无线的方式把数据提供到后台，后台根据采集回来的温度和湿度，综合管理平台用来对现场的温湿度进行实时调节，同时支持温湿度的前端设置。'
    },
    introData: {
      label: '产品介绍',
      textObj: {
        text1:
          'LORA温湿度采集器包括一个 32 位的微处理器、并集成1路LORA接口。可接入LORA网关，实时把现场数据传送到后台。可以涵盖极其广泛的楼宇应用。'
      }
    },
    functionData: {
      label: '产品功能',
      textObj: {
        text1:
          'LORA温湿度采集器支持多种环境温湿度参数检测。LORA温湿度采集器采用进口传感器，精度高。LORA温湿度采集器支持LoRaWAN协议。LORA温湿度采集器支持配置采样发送间隔。LORA温湿度采集器产品设计精巧，采用220V交流供电，便于安装维护。LORA温湿度采集器内置LORA天线。'
      }
    },
    paramsData: [
      {
        title: '产品参数',
        childrenList: [
          { label: 'CPU：', value: 'Cortex-M3 STM32F103 72MHz运行频率' },
          { label: '内存：', value: '64KB Flash， 20KB的SRAM' },
          { label: '无线通信：', value: '1路 LORA 无线通信接口' },
          { label: '温度采集：', value: '温度范围-40度到+125度，精度土0.2度' },
          { label: '湿度采集：', value: '湿度范围0%RH到100%RH，精度土2%r.H' },
          { label: '电源规格：', value: 'AC220V' },
          { label: '工作环境：', value: '-10℃～+55℃' },
          { label: '尺寸（长 × 宽 × 高）：', value: '70mm*60mm*30mm' },
          {
            label: 'LORAWAN 通信协议：',
            value: '标准LORAWAN通信协议，可以接入标准的LORAWAN网关',
            col: 4
          }
        ]
      }
    ],
    otherData: {
      descList: [
        {
          label: '产品优势',
          textObj: {
            text1:
              '现场运维便捷：模块供电采用220V交流供电，因此不用担心功耗的问题，同时也省去了更换电池的麻烦',
            text2:
              '安装部署特点：可以安装到任何一个两插插座上面，无线连接到后台，部署方便',
            text3:
              '远程维护升级：节点模块使用C模式工作，可通过远程升级及支持专用软件进行远程升级和维护'
          }
        }
      ]
    }
  },
  {
    name: 'RAS-Mini',
    module: '建筑',
    sortNum: 3,
    devieceData: {
      img: require('@/assets/hardware/RAS-Mini.png'),
      zhName: 'RAS Mini版',
      enName: 'TURBO RAS-Mini-LR(NB)',
      content:
        'Mini版的RAS是设计为具备少量的IO接入口和485总线接入的无线分布式采集控制器，上行接口为无线方式，适用于接入分散部署的单个设备，主要包括传感器、执行器、风机、水泵等设备。主要分为Turbo RAS-Mini-LR和Turbo RAS-Mini-NB两个型号，区别在于上行的通讯接口是LoRa还是NB-IoT。'
    },
    functionData: {
      label: '功能介绍',
      textObj: {
        text1:
          '6 路快速 DI 通道。1路 通用UI 通道（采集精度<1%、AI/VI可配置）。1路 通用UO 通道（输出精度<1%、AO/VO可配置）。1 路DO输出通道（NO/NC类型兼容）。支持1路下行 RS485 串口接口。实时上送数据，远程遥调遥控。可通过网页 web 和配套应用软件远程配置设备参数，可远程升级设备软件。提供以LORA、NB_Iot两种上行通讯接口，适应多种组网场合。所有通道带雷击浪涌防护。'
      }
    },
    paramsData: [
      {
        title: 'LORA产品参数',
        childrenList: [
          { label: '睡眠模式功耗：', value: '1.5uA' },
          { label: '输出功率：', value: '19dBm' },
          { label: '模块尺寸：', value: '15 x 15.5 x 2.5 mm' },
          { label: '带宽：', value: '7.8KHz ~ 500KHz' },
          { label: 'MCU：', value: 'ARM 32-bit Cortex–M0' },
          { label: '供电电压：', value: '2.0~3.6V' },
          { label: '最大发射功耗120mA,', value: '接收功耗15mA' },
          { label: '', value: '' }
        ]
      },
      {
        title: 'NB_Iot产品参数',
        childrenList: [
          { label: '供电电压：', value: '3.1~4.2V' },
          { label: '睡眠模式功耗：', value: '3uA' },
          { label: '输出功率：', value: '23dBm±2dB' },
          { label: 'USIM接口:', value: '1.8V/30V外部Class B USIM卡' },
          {
            label: '网络协议特性:',
            value: 'IPv4/IPv6/UDP/CoAP/LwM2M/Non-IP/DTLS/TCP/MQTT 协议',
            col: 2
          },
          { label: '短信：', value: 'PDU模式、点对点MO和MT' },
          { label: '天线接口：', value: '50Ω特性阻抗' },
          {
            label: '数据传输特性：',
            value:
              'Single-tone 传输，15kHz/3.75kHz 子载波间隔：25.2kbps（下行），15.625kbps（上行）Multi-tone 传输，15kHz 子载波间隔：25.2kbps（下行），54kbps（上行）Extended TBS/2 HARQ*传输，15kHz 子载波间隔：125kbps（下行），150kbps（上行）',
            col: 4,
            backgroundColor: '#fff'
          }
        ]
      },
      {
        title: '产品参数',
        childrenList: [
          { label: '端口数量：', value: '3' },
          { label: '端口类型：', value: 'EDG-5.08工业端子' },
          { label: '串口保护：', value: '全信号15 KV ESD保护' },
          { label: 'RS-485数据流向控制：', value: '自动控制' },
          { label: 'RS-485终端拉电阻：', value: '120 Ω' },
          { label: '浪涌防护：', value: '3KA' },
          { label: '数据位：', value: '5，6，7，8' },
          { label: '停止位：', value: '1，1.5，2' },
          { label: '校验位：', value: 'None，Even，Odd' },
          { label: '波特率：', value: '600 bps – 115200 bps' },
          { label: '传感器电源总输出能力：', value: '24V/400mA' },
          { label: '外壳：', value: '冷轧钢板，IP53防护等级' },
          { label: '重量：', value: '400g' },
          { label: '尺寸: ', value: '长133mm×宽83mm×高31mm' },
          { label: '工作温度：', value: '-20 - 80 ℃ (-4 - 167℉)' },
          { label: '工作湿度：', value: '5 - 95% RH' },
          { label: '存储温度：', value: '-40 - 100 ℃(-40 - 212 ℉)' },
          { label: '输入电压：', value: '220 VAC' },
          { label: '电源功耗：', value: '<12W（不带传感器)' },
          { label: '安装方式：', value: '挂耳安装,导轨安装' }
        ]
      }
    ],
    otherData: {
      descList: [
        {
          label: '适用场景',
          textObj: {
            text1:
              'Mini版本的RAS适用于具备如下特点的场景中：需接入设备式分散孤立安装部署的；需接入设备只有少量的IO接口，或是485接口；需接入设备数据传输量小，通信频次较低；数据交互以上行为主，对下行控制类指令的实时性要求不高；',
            text2:
              '基于以上几点我们可以总结出来RAS Mini版设备在楼宇智能化项目中适用的场景包括以下几个：',
            text3:
              '1.地下车库的污水泵、排风机、补风机地下车库的面积一般较大，而且设备分布很分散，很难实施集中式的接入方案，使用有线接入的方式往往需要使用大量的线材。这些设备要么是只监不控（污水泵本地控制方式），要么是对控制指令下发的实时性不敏感且指令下发频度低（排风机和补风机的启停）。水泵和风机需监控的状态较少，一般只有运行状态、故障状态以及手自动状态几种，这种场景下使用RAS Mini版设备是合适的。',
            text4:
              '2.标准层的排风机标准层的排风机安装于排风竖井附近，一般远离风机房这样的设备集中接入点，同时排风机对控制指令下发的实时性不敏感且指令下发频度低（排风机的启停），设备需要监控的状态不多，也是适合安装部署RAS Mini设备的。'
          }
        }
      ]
    }
  },
  {
    name: 'RAS-Pro',
    module: '建筑',
    sortNum: 1,
    devieceData: {
      img: require('@/assets/hardware/RAS-Pro.png'),
      zhName: '边缘网关 RAS PRO ',
      enName: 'Turbo RAS-Pro',
      content:
        '边缘网关 RAS PRO当前使用的主要型号为Turbo RAS-Pro2系列，配套了包括算力板、POE交换模块、IO模块，可选的功能模块包括4G模块、5G模块、交换模块、LoRa网关模块。根据POE端口数量的不同，可分为1U款（16个POE端口）和2U款（48个POE端口）。'
    },
    introData: {
      label: '产品介绍',
      textObj: {
        text1:
          '边缘网关 RAS PRO是设计为具备接入多种类型设备，数据上行方式根据项目情况的不同，可选择千兆以太网、4G、5G中的一种。同时边缘网关 RAS PRO配备专门的算力板，可对区域范围内设备统一管控调度，也可根据算力板的负荷以及运行情况，进行各边缘网关 RAS PRO上管控设备的分配调整，可实现在某边缘网关 RAS PRO出现故障时，其他的边缘网关 RAS PRO能及时接管原来由该边缘网关 RAS PRO管控的设备，以保证相关业务的连续性。'
      }
    },
    functionData: {
      label: '功能介绍',
      textObj: {
        text1:
          '整机设备：内置管理板，实现整机设备包括板卡和模块的配置管理；支持算力板、POE交换板、IO板；支持4G模块、5G模块、LoRa网关模块、交换模块；提供以太网、IIC、USB、SPI等总线接口；提供Wifi热点接入进行设备配置和管理的功能；提供RJ45形式Console配置接口，可实现对设备本身和插入板卡的调试和配置管理；',
        text2:
          '算力板：Ubuntu16.04操作系统；NXP i.MX6Q Cortex-A9 4x1GHz处理器；2GB DDR3内存；8GB eMMC存储；部署ESS服务；',
        text3:
          'POE交换板:提供双千兆电口上联（光口可选）；提供多路POE端口，每个端口支持802.af标准提供15W带载能力，部分端口支持802.at标准提供30W带载能力；通过背板为每个插板提供百兆内联以太口；支持多块交换板的千兆口级联；提供全网管功能；管功能；',
        text4:
          'IO板卡:485设备通信控制；AI设备的信号采集功能；DI设备的信号采集功能；AO设备的控制功能；DO设备的控制功能；Turbo RAS-B-IO-A4623型号IO板卡，具备接口10AI、4AO、6DI、2DO、3路485',
        text5: '无线4G模块:4G功能，系统平台与后台服务器进行通信',
        text6: '无线5G模块：5G功能，系统平台与后台服务器进行通信',
        text7:
          '无线LoRa网关模块:LoRaWAN网关，内置LoRaWAN NS；具备8个并发上行通道，1个下行通道；具备下行组播功能；支持LoRaWAN终端设备接入，按每个节点每分钟上报一次数据的频度，网关的接入终端容量可达1K；',
        text8:
          '交换模块:提供双百兆电口上联；通过背板为每个插板提供百兆内联以太口；'
      }
    },
    otherData: {
      descList: [
        {
          label: '适用场景',
          textObj: {
            text1: '标准层的风机房',
            text2:
              '在标准层中一般可以每3层部署一个Turbo RAS-Pro2，三层的安防摄像头和其他IP设备可以通过POE交换板接入，边缘网关 RAS PRO安装在弱电间或者风机房，如果安装在风机房，则所在风机房内的设备可以通过IO板接入，两层的LoRA终端设备（如LoRa型VAV Box控制器、LoRa型风机盘管控制器、RAS照明控制器特别版等）可以通过LoRa网关模块接入。算力板实现对区域内接入的设备的管控调度。'
          }
        }
      ]
    }
  },
  {
    name: 'S6800E',
    module: '通讯',
    sortNum: 1,
    devieceData: {
      img: require('@/assets/hardware/communication1.png'),
      zhName: '运营级核心交换机',
      enName: 'TURBO-S6800E',
      content:
        'TURBO S6800E系列是北京捍御者信息科技有限公司面向大中型园区网、企业网推出的新一代核心交换机。'
    },
    tabsPane: [
      {
        label: '产品介绍',
        className: 'intro-class',
        dataList: [
          {
            textObj: {
              text1:
                'Turbo S6800E系列是北京捍御者信息科技有限公司面向大中型园区网、企业网推出的新一代核心交换机。该系列产品采用业界先进的分布式多级交换矩阵架构，搭载Turbo自主知识产权的BDROS操作系统，在提供高性能的IPv4/v6线速交换服务基础上，结合不间断升级、不间断转发、优雅重启、冗余保护等多种高可靠性技术',
              text2:
                '从而保证了网络最长时间的不间断通信能力。源于捍御者先进的“GreenTouch”理念及智能芯片节能技术，大幅降低设备的能源消耗，低碳环保，有效降低了运营维护成本，为大中型企业网络提供了完美的解决方案。Turbo S6800E系列核心交换机有S6800-06E、S6800-10E两种产品形态，选配端口组合丰富的各类型业务板卡，能够满足不同规模网络对设备的端口密度及性能要求。'
            }
          }
        ]
      },
      {
        label: '产品特性',
        className: 'property-class',
        dataList: [
          {
            title: '先进的硬件架构设计，业界领先的强大处理能力',
            textObj: {
              text1:
                '面向十万兆平台设计，采用业界先进的分布式多级交换矩阵架构，搭载高性能的ASIC交换芯片和多核处理器，满足运营级核心设备的高性能、高容量、高密度及可拓展的要求。',
              text2:
                '支持高密度的万兆业务板，并且实现板卡间三层全线速无阻塞交换。',
              text3: '支持向40GE和100GE端口的拓展能力。'
            }
          },
          {
            title: '运营级的高可靠性',
            textObj: {
              text1:
                '基于HPS（Hitless Protection System）无中断保护系统，S6800E的关键器件，如系统主控单元、电源系统、风扇系统均采用冗余设计，所有系统模块均可热插拔，支持故障时无缝切换，无需人工干预。',
              text2:
                'S6800E支持STP/RSTP/MSTP协议，支持VRRP协议，同时支持环网保护，双上行主备链路保护，LACP链路聚合等简单高效的冗余保护机制。',
              text3:
                '支持ISSU（In-Service Software Upgrade）业务不中断系统升级，及GR（Graceful Restart）优雅重启for OSPF/BGP等路由协议，保证系统升级及主控切换时用户数据的不间断转发。',
              text4:
                '超高精度的BFD双向链路检测机制，通过和二、三层协议的联动，实现毫秒级的故障检测及业务恢复，极大程度地提高了网络系统的可靠性。',
              text5:
                '完善的以太网OAM机制，支持802.3ah、802.1ag和ITU-Y.1731，通过对网络运行状态的实时监控，实现对故障的快速检测与定位。',
              text6:
                'S6800E软硬件的高可靠性设计，满足电信级业务50ms的故障恢复时间要求，真正做到了运营级核心设备5个9（99.999%）的高可靠性。'
            }
          },
          {
            title: '创新的VSS虚拟化集群交换技术',
            textObj: {
              text1:
                'S6800E支持捍御者创新的BVSS（TURBO Virtual Switch System）虚拟化集群交换技术，可以将多台物理设备虚拟化为逻辑上的一台设备，其虚拟系统的性能、可靠性、灵活性及管理相比于独立的物理设备具有无与伦比的优越性。',
              text2:
                '性能翻倍：虚拟化系统可以充分利用物理设备间的每一条链路，避免传统组网模型生成树协议对链路的阻塞，物尽其用，性能翻倍，最大程度保护原有链路投资。',
              text3:
                '高可靠性：基于先进的分布式处理技术，通过高效的跨物理设备链路聚合功能，实现逻辑控制平面、业务控制平面和业务数据平面的三平面分离，提供不间断的三层路由转发，避免了单点故障所造成的业务中断，极大地提高了虚拟系统的可靠性。',
              text4:
                '易管理：整个虚拟系统实现单一IP统一管理，实际物理设备对用户透明，简化了对网络设备和网络拓扑的管理，大大提高了网络运营效率，从而有效降低运营维护成本。'
            }
          },
          {
            title: '丰富的业务特性',
            textObj: {
              text1:
                '完善的二、三层组播路由协议，满足IPTV、多终端的高清视频监控和高清视频会议的接入需求；',
              text2:
                '齐备的三层路由协议，超大的路由表容量，满足各种类型的网络互联需求，可组建超大型的园区网、企业网和行业用户专网。'
            }
          },
          {
            title: '全面的IPv6解决方案',
            textObj: {
              text1:
                '全面支持IPv6协议族，支持IPv6邻居发现、ICMPv6、Path MTU 发现、DHCPv6等IPv6特性。',
              text2:
                '支持基于IPv6的Ping、Traceroute、Telnet、SSH、ACL等，满足纯IPv6网络设备管理及业务控制的需要。',
              text3:
                '支持MLD、MLD Snooping等IPv6组播特性，支持IPv6静态路由、RIPng、OSPFv3、BGP4+等IPv6三层路由协议，为用户提供完善的IPv6二、三层解决方案。',
              text4:
                '支持丰富的IPv4向IPv6过渡技术，包括：IPv6手工隧道、自动隧道、6to4隧道、ISATAP隧道等隧道技术，保证IPv4网络向IPv6网络的平滑过渡。'
            }
          },
          {
            title: '完善的安全机制',
            textObj: {
              text1:
                '设备级安全防护：先进的硬件架构设计，硬件实现对报文的分级调度及保护，支持防范DoS、TCP的SYN Flood、UDP Flood、广播风暴、大流量等对设备的攻击；支持命令行分级保护，不同级别用户拥有不同管理权限；',
              text2:
                '完备的安全认证机制：支持IEEE 802.1x、Radius、BDTacacs+等，可为用户提供完备的安全认证机制。',
              text3:
                '增强的业务安全机制：支持相关路由协议的明文或MD5认证，支持uRPF逆向路由查找技术，可有效控制非法业务；硬件级报文深度检测和过滤技术，支持对控制报文和数据报文的深度检测，从而有效隔离非法数据报文，提高网络系统的安全性。'
            }
          },
          {
            title: '全面的IPv6解决方案',
            textObj: {
              text1:
                '全面支持IPv6协议族，支持IPv6邻居发现、ICMPv6、Path MTU 发现、DHCPv6等IPv6特性。',
              text2:
                '支持基于IPv6的Ping、Traceroute、Telnet、SSH、ACL等，满足纯IPv6网络设备管理及业务控制的需要。',
              text3:
                '支持MLD、MLD Snooping等IPv6组播特性，支持IPv6静态路由、RIPng、OSPFv3、BGP4+等IPv6三层路由协议，为用户提供完善的IPv6二、三层解决方案。',
              text4:
                '支持丰富的IPv4向IPv6过渡技术，包括：IPv6手工隧道、自动隧道、6to4隧道、ISATAP隧道等隧道技术，保证IPv4网络向IPv6网络的平滑过渡。'
            }
          },
          {
            title: '创新的绿色环保设计',
            textObj: {
              text1: 'S6800E传承捍御者先进的“GreenTouch”理念，节能环保。',
              text2:
                '智能的电源管理系统：S6800E采用先进的电源系统架构设计，实现高效的电源转换，独有的电源监控、缓启动、顺序上电等功能，实时监测整机运行状态，智能调节，深度节能。',
              text3:
                '智能的风扇管理系统：智能风扇设计，支持自动调速及独立分区控制，有效降低转速、减少噪声，同时延长了风扇的使用寿命。',
              text4:
                '支持能效以太网功能，遵循国际标准IEEE 802.3az，有效降低了能源消耗。'
            }
          }
        ]
      },
      {
        label: '产品参数',
        className: 'params-class',
        dataList: [
          {
            title: '项目',
            textObj: {
              text1: 'S6800-06E'
            },
            textObj2: {
              text1: 'S6800-10E'
            }
          },
          {
            title: '交换容量',
            textObj: {
              text1: '19.8Tbps/86.4Tbps'
            },
            textObj2: {
              text1: '39.6Tbps/172.8Tbps'
            }
          },
          {
            title: '包转发率',
            textObj: {
              text1: '2880Mpps/26400Mpps'
            },
            textObj2: {
              text1: '7680Mpps/48600Mpps'
            }
          },
          {
            title: '槽位数量',
            textObj: {
              text1: '6'
            },
            textObj2: {
              text1: '10'
            }
          },
          {
            title: '业务槽位数量',
            textObj: {
              text1: '4'
            },
            textObj2: {
              text1: '8'
            }
          },
          {
            title: 'MAC交换功能',
            textObj: {
              text1: '支持静态配置和动态学习MAC地址',
              text2: '支持查看和清除MAC地址',
              text3: 'MAC地址老化时间可配置',
              text4: '支持MAC地址学习数量限制',
              text5: '支持MAC地址过滤功能',
              text6: '支持黑洞MAC表项'
            }
          },
          {
            title: 'VLAN',
            textObj: {
              text1: '支持4K VLAN表项',
              text2: '支持GVRP',
              text3: '支持1：1和N：1 VLAN Mapping',
              text4: '支持基本QinQ和灵活QinQ功能',
              text5: '支持Private VLAN'
            }
          },
          {
            title: 'STP',
            textObj: {
              text1: '支持802.1D（STP）、802.1W（RSTP）、802.1S（MSTP）',
              text2: '支持BPDU保护、根保护、环路保护'
            }
          },
          {
            title: 'IPv4',
            textObj: {
              text1: '支持静态路由、RIP v1/v2、OSPF、BGP',
              text2: '支持策略路由',
              text3: '支持等价路由实现负载均衡',
              text4: '支持OSPF、BGP的Graceful Restart',
              text5: '支持BFD for OSPF、BGP'
            }
          },
          {
            title: 'IPv6',
            textObj: {
              text1: '支持ICMPv6、DHCPv6、ACLv6、IPv6 Telnet',
              text2: '支持IPv6邻居发现',
              text3: '支持Path MTU发现',
              text4: '支持MLD 、MLD Snooping',
              text5: '支持IPv6静态路由、RIPng、OSPFv3、BGP4+',
              text6: '支持手工隧道、ISATAP隧道、6to4隧道'
            }
          },
          {
            title: 'QoS',
            textObj: {
              text1: '支持基于L2/L3/L4协议头各字段的流量分类',
              text2: '支持CAR流量限制',
              text3: '支持802.1P/DSCP优先级重新标记',
              text4: '支持SP、WRR、SP+WRR等队列调度方式',
              text5: '支持Tail-Drop、WRED等拥塞避免机制',
              text6: '支持流量监管与流量整形',
              text7:
                '支持Ingress和Egress ACL，支持匹配L2、L3、L4和IP五元组，进行复制、转发、丢弃',
              text8: '支持Hash同源同宿负载均衡，保证流量输出的会话完整性'
            }
          },
          {
            title: '分流应用',
            textObj: {
              text1:
                '支持Ingress和Egress ACL，支持匹配L2、L3、L4和IP五元组，进行复制、转发、丢弃',
              text2: '支持Hash同源同宿负载均衡，保证流量输出的会话完整性'
            }
          },
          {
            title: '安全特性',
            textObj: {
              text1: '支持基于L2/L3/L4的ACL流识别与过滤安全机制',
              text2: '支持防DDoS攻击、TCP的SYN Flood攻击、UDP Flood攻击等',
              text3: '支持对组播、广播、未知单播报文的抑制功能',
              text4: '支持端口隔离',
              text5: '支持端口安全、IP+MAC+端口绑定',
              text6: '支持DHCP Snooping、DHCP Option 82',
              text7: '支持IEEE 802.1x认证',
              text8: '支持Radius、BDTacacs+认证',
              text9: '支持uRPF',
              text10: '支持命令行分级保护'
            }
          },
          {
            title: '可靠性',
            textObj: {
              text1: '支持双主控冗余',
              text2: '支持电源1+1备份',
              text3: '支持主控、业务卡热插拔及业务自动恢复',
              text4: '支持静态/LACP方式链路聚合，支持跨业务卡的链路聚合',
              text5: '支持EAPS，ERPS等环网保护',
              text6: '支持VRRP',
              text7: '支持以太网OAM 802.3ah/802.1ag/ITU-Y.1731',
              text8: '支持GR for OSPF、BGP',
              text9: '支持BFD for OSPF、BGP',
              text10: '支持ISSU业务不中断系统升级'
            }
          },
          {
            title: '管理与维护',
            textObj: {
              text1: '支持Console、Telnet、SSH',
              text2: '支持SNMP v1/v2/v3',
              text3: '支持TFTP方式的文件上传、下载管理',
              text4: '支持RMON',
              text5: '支持sFLOW、Netflow流量统计分析'
            }
          },
          {
            title: '增值业务',
            textObj: {
              text1: 'VSS虚拟化功能'
            }
          },
          {
            title: '绿色节能',
            textObj: {
              text1: '支持IEEE 802.3az绿色能效以太网'
            }
          },
          {
            title: '环境要求',
            textObj: {
              text1: '工作温/湿度：0℃-40℃，10%-90%无凝露',
              text2: '存储温/湿度：-20℃-70℃；5%-95%无凝露'
            }
          },
          {
            title: '电源',
            textObj: {
              text1: 'AC：100V-240V，50Hz±10%',
              text2: 'DC：-48V'
            }
          },
          {
            title: '机箱尺寸mm（宽×深×高）',
            textObj: {
              text1: '482×548×399',
              text2: '9U'
            },
            textObj2: {
              text1: '482×548×533',
              text2: '12U'
            }
          },
          {
            title: '整机重量（空配）',
            textObj: {
              text1: '26kg'
            },
            textObj2: {
              text1: '30.5kg'
            }
          }
        ]
      }
    ]
  },
  {
    name: 'S9510',
    module: '通讯',
    sortNum: 2,
    devieceData: {
      img: require('@/assets/hardware/communication2.png'),
      zhName: '高性能核心交换机',
      enName: 'TURBO-S9510',
      content:
        'TURBO S9500系列交换机是北京捍御者信息科技有限公司面向高性能云计算数据中心和高端园区网推出的新一代高性能核心交换机。S9500系列采用先进的硬件架构设计，提供业界最高的交换性能和丰富的数据中心业务特性'
    },
    tabsPane: [
      {
        label: '产品介绍',
        className: 'intro-class',
        dataList: [
          {
            textObj: {
              text1:
                'S9500系列支持高达64Tbps交换容量，整机最大支持768个10G端口，512个40G端口，和128个100G端口，并预留向256个100G端口的演进能力。',
              text2:
                '为了满足云计算数据中心网络资源池化需求，S9500系列支持丰富的BVSS（捍御者虚拟交换系统技术）虚拟化特性以及TRILL、SDN、FCoE/FC等数据中心特性。配合捍御者S5800系列数据中心ToR交换机，可实现15000+台万兆服务器的接入，为超大型数据中心提供了完善的网络解决方案。',
              text3:
                'S9500系列搭载TURBO自主知识产权的BDROS 6代操作系统，在提供高性能的L2/L3/L4线速交换服务基础上，进一步融合了IPv6、MPLS VPN、网络安全、流量分析、虚拟化等多种网络业务，结合不间断升级、不间断转发、优雅重启、冗余保护等多种数据中心高可靠性技术，从而保证了网络最长时间的不间断通信能力。',
              text4:
                '源于捍御者先进的“GreenTouch”理念及业界领先的“Smart@CHIP”智能芯片绿色节能技术，深度节能，大幅降低设备的能源消耗，低碳环保，有效降低了运营维护成本，为网络的绿色可持续发展提供了完美的解决方案。TURBO S9500系列高性能核心交换机有S9506、S9510、S9514、S9518四种产品形态，能够满足不同规模网络对设备的端口密度及性能要求。'
            }
          }
        ]
      },
      {
        label: '产品特性',
        className: 'property-class',
        dataList: [
          {
            title: '先进的硬件架构设计，业界领先的强大处理能力',
            textObj: {
              text1:
                '采用业界先进的硬件架构设计，控制引擎和交换网板分离，提供持续的带宽升级能力。',
              text2:
                '搭载高性能的ASIC交换芯片和多核处理器，支持高达40Tbps的交换容量，满足数据中心核心设备的高性能、高容量、高密度及可拓展的要求。',
              text3:
                '支持高密度的万兆业务板，并且实现板卡间三层全线速无阻塞交换。',
              text4:
                '整机最大支持768个10G端口，256个40G端口，和96个100G端口，并预留向256个100G端口的演进能力。',
              text5: '单业务板卡支持高达512K MAC地址表项、512K三层路由表项。'
            }
          },
          {
            title: '丰富的数据中心业务特性',
            textObj: {
              text1: 'BVSS（TURBO Virtual Switch System）虚拟化集群交换技术',
              text2:
                'S9500支持捍御者创新的BVSS虚拟化集群交换技术，可以将多台物理设备虚拟化为逻辑上的一台设备，其虚拟系统的性能、可靠性、灵活性及管理相比于独立的物理设备具有无与伦比的优越性。',
              text3:
                '性能翻倍：虚拟化系统可以充分利用物理设备间的每一条链路，避免传统组网模型生成树协议对链路的阻塞，物尽其用，性能翻倍，最大程度保护原有链路投资。',
              text4:
                '高可靠性：基于先进的分布式处理技术，通过高效的跨物理设备链路聚合功能，实现逻辑控制平面、业务控制平面和业务数据平面的三平面分离，提供不间断的三层路由转发，避免了单点故障所造成的业务中断，极大地提高了虚拟系统的可靠性。',
              text5:
                '灵活性：通过S9500的虚拟集群业务板，可将虚拟集群系统的距离延伸至80KM，灵活方便，打破了传统集群技术的地域限制性。',
              text6:
                '易管理：整个虚拟系统实现单一IP统一管理，实际物理设备对用户透明，简化了对网络设备和网络拓扑的管理，大大提高了网络运营效率，从而有效降低运营维护成本。',
              text7:
                '大二层技术：支持TRILL/SPB协议，可组构建数据中心大二层网络，使网络结构简单化、扁平化，满足数据中心大规模服务器接入需求。',
              text8:
                '统一交换：支持FCoE（FC over Ethernet）技术，解决LAN网络和FC存储网络异构问题，从而实现计算、数据、存储网络的融合，实现统一交换。',
              text9:
                'SDN：支持SDN（Software Defined Network，软件定义网络）技术，可实现高度网络虚拟化及集中管理。'
            }
          },
          {
            title: '数据中心级的高可靠性',
            textObj: {
              text1:
                '基于HPS（Hitless Protection System）无中断保护系统，S9500的关键器件，如系统主控单元、电源系统、风扇系统均采用冗余设计，所有系统模块均可热插拔，支持故障时无缝切换，无需人工干预。',
              text2:
                'S9500支持STP/RSTP/MSTP协议，支持VRRP协议，同时支持环网保护，双上行主备链路保护，LACP链路聚合等简单高效的冗余保护机制。',
              text3:
                '支持ISSU（In-Service Software Upgrade）业务不中断系统升级，及GR（Graceful Restart）优雅重启for OSPF/BGP等路由协议，保证系统升级及主控切换时用户数据的不间断转发。',
              text4:
                '超高精度的BFD双向链路检测机制，通过和二、三层协议的联动，实现毫秒级的故障检测及业务恢复，极大程度地提高了网络系统的可靠性。',
              text5:
                '完善的以太网OAM机制，支持802.3ah、802.1ag和ITU-Y.1731，通过对网络运行状态的实时监控，实现对故障的快速检测与定位。',
              text6:
                'S9500软硬件的高可靠性设计，满足50ms的故障恢复时间要求，真正做到了核心设备5个9（99.999%）的高可靠性。'
            }
          },
          {
            title: '丰富的业务特性',
            textObj: {
              text1:
                '完善的二、三层组播路由协议，满足IPTV、多终端的高清视频监控和高清视频会议的接入需求；',
              text2:
                '齐备的三层路由协议，超大的路由表容量，满足各种类型的网络互联需求，可组建超大型的园区网、企业网和行业用户专网。',
              text3:
                '全面支持二、三层的MPLS VPN，可组建超大型的MPLS VPN核心网络，满足行业专网VPN用户、企业网VPN用户的接入需求。',
              text4: '提供POE、流量分析等增值业务特性。'
            }
          },
          {
            title: '全面的IPv6解决方案',
            textObj: {
              text1:
                '全面支持IPv6协议族，支持IPv6邻居发现、ICMPv6、Path MTU 发现、DHCPv6等IPv6特性。',
              text2:
                '支持基于IPv6的Ping、Traceroute、Telnet、SSH、ACL等，满足纯IPv6网络设备管理及业务控制的需要。',
              text3:
                '支持MLD、MLD Snooping等IPv6组播特性，支持IPv6静态路由、RIPng、OSPFv3、BGP4+等IPv6三层路由协议，为用户提供完善的IPv6二、三层解决方案。',
              text4:
                '支持丰富的IPv4向IPv6过渡技术，包括：IPv6手工隧道、自动隧道、6to4隧道、ISATAP隧道等隧道技术，保证IPv4网络向IPv6网络的平滑过渡。'
            }
          },
          {
            title: '完善的安全机制',
            textObj: {
              text1:
                '设备级安全防护：先进的硬件架构设计，硬件实现对报文的分级调度及保护，支持防范DoS、TCP的SYN Flood、UDP Flood、广播风暴、大流量等对设备的攻击；支持命令行分级保护，不同级别用户拥有不同管理权限；',
              text2:
                '完备的安全认证机制：支持IEEE 802.1x、Radius、BDTacacs+等，可为用户提供完备的安全认证机制。',
              text3:
                '增强的业务安全机制：支持相关路由协议的明文或MD5认证，支持uRPF逆向路由查找技术，可有效控制非法业务；硬件级报文深度检测和过滤技术，支持对控制报文和数据报文的深度检测，从而有效隔离非法数据报文，提高网络系统的安全性。'
            }
          },
          {
            title: '创新的绿色环保设计',
            textObj: {
              text1: 'S9500传承捍御者先进的“GreenTouch”理念，节能环保。',
              text2:
                '智能的电源管理系统：S9500采用先进的电源系统架构设计，实现高效的电源转换，独有的电源监控、缓启动、顺序上电等功能，实时监测整机运行状态，智能调节，深度节能。',
              text3:
                '智能的风扇管理系统：智能风扇设计，采用对旋风扇提供稳定的强劲风压，高效散热；支持风扇自动调速及独立分区控制，有效降低转速、减少噪声，同时延长了风扇的使用寿命。',
              text4:
                '支持能效以太网功能，遵循国际标准IEEE 802.3az，有效降低了能源消耗。'
            }
          }
        ]
      },
      {
        label: '产品参数',
        className: 'params-class',
        dataList: [
          {
            title: '项目',
            textObj: {
              text1: 'S9506'
            },
            textObj2: {
              text1: 'S9510'
            },
            textObj3: {
              text1: 'S9514'
            },
            textObj5: {
              text1: '*S9518'
            }
          },
          {
            title: '交换容量',
            textObj: {
              text1: '20.74Tbps/128Tbps'
            },
            textObj2: {
              text1: '41.47Tbps/204.8Tbps'
            },
            textObj3: {
              text1: '55.3Tbps/256Tbps'
            },
            textObj5: {
              text1: '69.12Tbps/307.2Tbps'
            }
          },
          {
            title: '包转发率',
            textObj: {
              text1: '5040Mpps/36000 Mpps'
            },
            textObj2: {
              text1: '6720Mpps/48000 Mpps'
            },
            textObj3: {
              text1: '8400Mpps/67500 Mpps'
            },
            textObj5: {
              text1: '10080Mpps/81000 Mpps'
            }
          },
          {
            title: '总槽位数量',
            textObj: {
              text1: '10'
            },
            textObj2: {
              text1: '14'
            },
            textObj3: {
              text1: '18'
            },
            textObj5: {
              text1: '22'
            }
          },
          {
            title: '主控板槽位数',
            textObj: {
              text1: '2'
            },
            textObj2: {
              text1: '2'
            },
            textObj3: {
              text1: '2'
            },
            textObj5: {
              text1: '2'
            }
          },
          {
            title: '交换网板槽位数',
            textObj: {
              text1: '4'
            },
            textObj2: {
              text1: '4'
            },
            textObj3: {
              text1: '4'
            },
            textObj5: {
              text1: '4'
            }
          },
          {
            title: '业务板槽位数',
            textObj: {
              text1: '4'
            },
            textObj2: {
              text1: '8'
            },
            textObj3: {
              text1: '12'
            },
            textObj5: {
              text1: '16'
            }
          },
          {
            title: '数据中心特性',
            textObj: {
              text1: '支持BVSS虚拟化技术',
              text2: '支持TRILL/SPB大二层技术',
              text3: '支持FCoE技术',
              text4: '支持SDN'
            }
          },
          {
            title: 'MAC交换功能',
            textObj: {
              text1: '支持静态配置和动态学习MAC地址',
              text2: '支持查看和清除MAC地址',
              text3: 'MAC地址老化时间可配置',
              text4: '支持MAC地址学习数量限制',
              text5: '支持MAC地址过滤功能',
              text6: '支持黑洞MAC表项',
              text7: '支持IEEE 802.1AE MacSec安全控制'
            }
          },
          {
            title: 'VLAN',
            textObj: {
              text1: '支持4K VLAN表项',
              text2: '支持GVRP',
              text3: '支持1：1和N：1 VLAN Mapping',
              text4: '支持基本QinQ和灵活QinQ功能',
              text5: '支持Private VLAN'
            }
          },
          {
            title: 'STP',
            textObj: {
              text1: '支持802.1D（STP）、802.1W（RSTP）、802.1S（MSTP）',
              text2: '支持BPDU保护、根保护、环路保护'
            }
          },
          {
            title: '组播',
            textObj: {
              text1: '支持IGMP v1/v2/v3',
              text2: '支持IGMP Snooping',
              text3: '支持IGMP Fast Leave',
              text4: '支持组播组策略及组播组数量限制',
              text5: '支持组播流量跨VLAN复制',
              text6: '支持PIM-SM、PIM-DM'
            }
          },
          {
            title: 'IPv4',
            textObj: {
              text1: '支持静态路由、RIP v1/v2、OSPF、BGP',
              text2: '支持策略路由',
              text3: '支持等价路由实现负载均衡',
              text4: '支持OSPF、BGP的Graceful Restart',
              text5: '支持BFD for OSPF、BGP'
            }
          },
          {
            title: 'IPv6',
            textObj: {
              text1: '支持ICMPv6、DHCPv6、ACLv6、IPv6 Telnet',
              text2: '支持IPv6邻居发现',
              text3: '支持Path MTU发现',
              text4: '支持MLD 、MLD Snooping',
              text5: '支持IPv6静态路由、RIPng、OSPFv3、BGP4+',
              text6: '支持手工隧道、ISATAP隧道、6to4隧道'
            }
          },
          {
            title: 'MPLS VPN',
            textObj: {
              text1: '支持LDP协议',
              text2: '支持MCE',
              text3: '支持MPLS VPN的P/PE功能要求',
              text4: '支持MPLS TE',
              text5: '支持MPLS OAM'
            }
          },
          {
            title: 'QoS',
            textObj: {
              text1: '支持基于L2/L3/L4协议头各字段的流量分类',
              text2: '支持CAR流量限制',
              text3: '支持802.1P/DSCP优先级重新标记',
              text4: '支持SP、WRR、SP+WRR等队列调度方式',
              text5: '支持Tail-Drop、WRED等拥塞避免机制',
              text6: '支持流量监管与流量整形',
              text7:
                '支持Ingress和Egress ACL，支持匹配L2、L3、L4和IP五元组，进行复制、转发、丢弃',
              text8: '支持Hash同源同宿负载均衡，保证流量输出的会话完整性'
            }
          },
          {
            title: '安全特性',
            textObj: {
              text1: '支持基于L2/L3/L4的ACL流识别与过滤安全机制',
              text2: '支持防DDoS攻击、TCP的SYN Flood攻击、UDP Flood攻击等',
              text3: '支持对组播、广播、未知单播报文的抑制功能',
              text4: '支持端口隔离',
              text5: '支持端口安全、IP+MAC+端口绑定',
              text6: '支持DHCP Snooping、DHCP Option 82',
              text7: '支持IEEE 802.1x认证',
              text8: '支持Radius、BDTacacs+认证',
              text9: '支持uRPF',
              text10: '支持命令行分级保护'
            }
          },
          {
            title: '可靠性',
            textObj: {
              text1: '支持双主控冗余',
              text2: '支持多电源备份',
              text3: '支持主控、业务卡热插拔及业务自动恢复',
              text4: '支持静态/LACP方式链路聚合，支持跨业务卡的链路聚合',
              text5: '支持EAPS等环网保护',
              text6: '支持VRRP',
              text7: '支持以太网OAM 802.3ah/802.1ag/ITU-Y.1731',
              text8: '支持GR for OSPF、BGP',
              text9: '支持BFD for OSPF、BGP',
              text10: '支持ISSU业务不中断系统升级'
            }
          },
          {
            title: '管理与维护',
            textObj: {
              text1: '支持Console、Telnet、SSH',
              text2: '支持SNMP v1/v2/v3',
              text3: '支持TFTP方式的文件上传、下载管理',
              text4: '支持RMON',
              text5: '支持sFLOW、Netflow流量统计分析'
            }
          },
          {
            title: '增值业务',
            textObj: {
              text1: '支持POE功能'
            }
          },
          {
            title: '绿色节能',
            textObj: {
              text1: '支持IEEE 802.3az绿色能效以太网'
            }
          },
          {
            title: '环境要求',
            textObj: {
              text1: '工作温/湿度：0℃-40℃，10%-90%无凝露',
              text2: '存储温/湿度：-20℃-70℃；5%-95%无凝露'
            }
          },
          {
            title: '电源',
            textObj: {
              text1: 'AC：100V-240V，50Hz±10%',
              text2: 'DC：-48V'
            }
          },
          {
            title: '机箱尺寸mm（宽×深×高）',
            textObj: {
              text1: '482×564×48611U'
            },
            textObj2: {
              text1: '482×564×62014U'
            },
            textObj3: {
              text1: '482×564×79818U'
            },
            textObj5: {
              text1: '482×564×97522U'
            }
          }
        ]
      }
    ]
  },
  {
    name: 'S9506',
    module: '通讯',
    sortNum: 3,
    devieceData: {
      img: require('@/assets/hardware/communication3.png'),
      zhName: '高性能核心交换机',
      enName: 'TURBO S9506',
      content:
        'TURBO S9500系列交换机是北京捍御者信息科技有限公司面向高性能云计算数据中心和高端园区网推出的新一代高性能核心交换机。S9500系列采用先进的硬件架构设计，提供业界最高的交换性能和丰富的数据中心业务特性'
    },
    tabsPane: [
      {
        label: '产品介绍',
        className: 'intro-class',
        dataList: [
          {
            textObj: {
              text1:
                'S9500系列支持高达64Tbps交换容量，整机最大支持768个10G端口，512个40G端口，和128个100G端口，并预留向256个100G端口的演进能力。',
              text2:
                '为了满足云计算数据中心网络资源池化需求，S9500系列支持丰富的BVSS（捍御者虚拟交换系统技术）虚拟化特性以及TRILL、SDN、FCoE/FC等数据中心特性。配合捍御者S5800系列数据中心ToR交换机，可实现15000+台万兆服务器的接入，为超大型数据中心提供了完善的网络解决方案。',
              text3:
                'S9500系列搭载TURBO自主知识产权的BDROS 6代操作系统，在提供高性能的L2/L3/L4线速交换服务基础上，进一步融合了IPv6、MPLS VPN、网络安全、流量分析、虚拟化等多种网络业务，结合不间断升级、不间断转发、优雅重启、冗余保护等多种数据中心高可靠性技术，从而保证了网络最长时间的不间断通信能力。',
              text4:
                '源于捍御者先进的“GreenTouch”理念及业界领先的“Smart@CHIP”智能芯片绿色节能技术，深度节能，大幅降低设备的能源消耗，低碳环保，有效降低了运营维护成本，为网络的绿色可持续发展提供了完美的解决方案。TURBO S9500系列高性能核心交换机有S9506、S9510、S9514、S9518四种产品形态，能够满足不同规模网络对设备的端口密度及性能要求。'
            }
          }
        ]
      },
      {
        label: '产品特性',
        className: 'property-class',
        dataList: [
          {
            title: '先进的硬件架构设计，业界领先的强大处理能力',
            textObj: {
              text1:
                '采用业界先进的硬件架构设计，控制引擎和交换网板分离，提供持续的带宽升级能力。',
              text2:
                '搭载高性能的ASIC交换芯片和多核处理器，支持高达40Tbps的交换容量，满足数据中心核心设备的高性能、高容量、高密度及可拓展的要求。',
              text3:
                '支持高密度的万兆业务板，并且实现板卡间三层全线速无阻塞交换。',
              text4:
                '整机最大支持768个10G端口，256个40G端口，和96个100G端口，并预留向256个100G端口的演进能力。',
              text5: '单业务板卡支持高达512K MAC地址表项、512K三层路由表项。'
            }
          },
          {
            title: '丰富的数据中心业务特性',
            textObj: {
              text1: 'BVSS（TURBO Virtual Switch System）虚拟化集群交换技术',
              text2:
                'S9500支持捍御者创新的BVSS虚拟化集群交换技术，可以将多台物理设备虚拟化为逻辑上的一台设备，其虚拟系统的性能、可靠性、灵活性及管理相比于独立的物理设备具有无与伦比的优越性。',
              text3:
                '性能翻倍：虚拟化系统可以充分利用物理设备间的每一条链路，避免传统组网模型生成树协议对链路的阻塞，物尽其用，性能翻倍，最大程度保护原有链路投资。',
              text4:
                '高可靠性：基于先进的分布式处理技术，通过高效的跨物理设备链路聚合功能，实现逻辑控制平面、业务控制平面和业务数据平面的三平面分离，提供不间断的三层路由转发，避免了单点故障所造成的业务中断，极大地提高了虚拟系统的可靠性。',
              text5:
                '灵活性：通过S9500的虚拟集群业务板，可将虚拟集群系统的距离延伸至80KM，灵活方便，打破了传统集群技术的地域限制性。',
              text6:
                '易管理：整个虚拟系统实现单一IP统一管理，实际物理设备对用户透明，简化了对网络设备和网络拓扑的管理，大大提高了网络运营效率，从而有效降低运营维护成本。',
              text7:
                '大二层技术：支持TRILL/SPB协议，可组构建数据中心大二层网络，使网络结构简单化、扁平化，满足数据中心大规模服务器接入需求。',
              text8:
                '统一交换：支持FCoE（FC over Ethernet）技术，解决LAN网络和FC存储网络异构问题，从而实现计算、数据、存储网络的融合，实现统一交换。',
              text9:
                'SDN：支持SDN（Software Defined Network，软件定义网络）技术，可实现高度网络虚拟化及集中管理。'
            }
          },
          {
            title: '数据中心级的高可靠性',
            textObj: {
              text1:
                '基于HPS（Hitless Protection System）无中断保护系统，S9500的关键器件，如系统主控单元、电源系统、风扇系统均采用冗余设计，所有系统模块均可热插拔，支持故障时无缝切换，无需人工干预。',
              text2:
                'S9500支持STP/RSTP/MSTP协议，支持VRRP协议，同时支持环网保护，双上行主备链路保护，LACP链路聚合等简单高效的冗余保护机制。',
              text3:
                '支持ISSU（In-Service Software Upgrade）业务不中断系统升级，及GR（Graceful Restart）优雅重启for OSPF/BGP等路由协议，保证系统升级及主控切换时用户数据的不间断转发。',
              text4:
                '超高精度的BFD双向链路检测机制，通过和二、三层协议的联动，实现毫秒级的故障检测及业务恢复，极大程度地提高了网络系统的可靠性。',
              text5:
                '完善的以太网OAM机制，支持802.3ah、802.1ag和ITU-Y.1731，通过对网络运行状态的实时监控，实现对故障的快速检测与定位。',
              text6:
                'S9500软硬件的高可靠性设计，满足50ms的故障恢复时间要求，真正做到了核心设备5个9（99.999%）的高可靠性。'
            }
          },
          {
            title: '丰富的业务特性',
            textObj: {
              text1:
                '完善的二、三层组播路由协议，满足IPTV、多终端的高清视频监控和高清视频会议的接入需求；',
              text2:
                '齐备的三层路由协议，超大的路由表容量，满足各种类型的网络互联需求，可组建超大型的园区网、企业网和行业用户专网。',
              text3:
                '全面支持二、三层的MPLS VPN，可组建超大型的MPLS VPN核心网络，满足行业专网VPN用户、企业网VPN用户的接入需求。',
              text4: '提供POE、流量分析等增值业务特性。'
            }
          },
          {
            title: '全面的IPv6解决方案',
            textObj: {
              text1:
                '全面支持IPv6协议族，支持IPv6邻居发现、ICMPv6、Path MTU 发现、DHCPv6等IPv6特性。',
              text2:
                '支持基于IPv6的Ping、Traceroute、Telnet、SSH、ACL等，满足纯IPv6网络设备管理及业务控制的需要。',
              text3:
                '支持MLD、MLD Snooping等IPv6组播特性，支持IPv6静态路由、RIPng、OSPFv3、BGP4+等IPv6三层路由协议，为用户提供完善的IPv6二、三层解决方案。',
              text4:
                '支持丰富的IPv4向IPv6过渡技术，包括：IPv6手工隧道、自动隧道、6to4隧道、ISATAP隧道等隧道技术，保证IPv4网络向IPv6网络的平滑过渡。'
            }
          },
          {
            title: '完善的安全机制',
            textObj: {
              text1:
                '设备级安全防护：先进的硬件架构设计，硬件实现对报文的分级调度及保护，支持防范DoS、TCP的SYN Flood、UDP Flood、广播风暴、大流量等对设备的攻击；支持命令行分级保护，不同级别用户拥有不同管理权限；',
              text2:
                '完备的安全认证机制：支持IEEE 802.1x、Radius、BDTacacs+等，可为用户提供完备的安全认证机制。',
              text3:
                '增强的业务安全机制：支持相关路由协议的明文或MD5认证，支持uRPF逆向路由查找技术，可有效控制非法业务；硬件级报文深度检测和过滤技术，支持对控制报文和数据报文的深度检测，从而有效隔离非法数据报文，提高网络系统的安全性。'
            }
          },
          {
            title: '创新的绿色环保设计',
            textObj: {
              text1: 'S9500传承捍御者先进的“GreenTouch”理念，节能环保。',
              text2:
                '智能的电源管理系统：S9500采用先进的电源系统架构设计，实现高效的电源转换，独有的电源监控、缓启动、顺序上电等功能，实时监测整机运行状态，智能调节，深度节能。',
              text3:
                '智能的风扇管理系统：智能风扇设计，采用对旋风扇提供稳定的强劲风压，高效散热；支持风扇自动调速及独立分区控制，有效降低转速、减少噪声，同时延长了风扇的使用寿命。',
              text4:
                '支持能效以太网功能，遵循国际标准IEEE 802.3az，有效降低了能源消耗。'
            }
          }
        ]
      },
      {
        label: '产品参数',
        className: 'params-class',
        dataList: [
          {
            title: '项目',
            textObj: {
              text1: 'S9506'
            },
            textObj2: {
              text1: 'S9510'
            },
            textObj3: {
              text1: 'S9514'
            },
            textObj5: {
              text1: '*S9518'
            }
          },
          {
            title: '交换容量',
            textObj: {
              text1: '20.74Tbps/128Tbps'
            },
            textObj2: {
              text1: '41.47Tbps/204.8Tbps'
            },
            textObj3: {
              text1: '55.3Tbps/256Tbps'
            },
            textObj5: {
              text1: '69.12Tbps/307.2Tbps'
            }
          },
          {
            title: '包转发率',
            textObj: {
              text1: '5040Mpps/36000 Mpps'
            },
            textObj2: {
              text1: '6720Mpps/48000 Mpps'
            },
            textObj3: {
              text1: '8400Mpps/67500 Mpps'
            },
            textObj5: {
              text1: '10080Mpps/81000 Mpps'
            }
          },
          {
            title: '总槽位数量',
            textObj: {
              text1: '10'
            },
            textObj2: {
              text1: '14'
            },
            textObj3: {
              text1: '18'
            },
            textObj5: {
              text1: '22'
            }
          },
          {
            title: '主控板槽位数',
            textObj: {
              text1: '2'
            },
            textObj2: {
              text1: '2'
            },
            textObj3: {
              text1: '2'
            },
            textObj5: {
              text1: '2'
            }
          },
          {
            title: '交换网板槽位数',
            textObj: {
              text1: '4'
            },
            textObj2: {
              text1: '4'
            },
            textObj3: {
              text1: '4'
            },
            textObj5: {
              text1: '4'
            }
          },
          {
            title: '业务板槽位数',
            textObj: {
              text1: '4'
            },
            textObj2: {
              text1: '8'
            },
            textObj3: {
              text1: '12'
            },
            textObj5: {
              text1: '16'
            }
          },
          {
            title: '数据中心特性',
            textObj: {
              text1: '支持BVSS虚拟化技术',
              text2: '支持TRILL/SPB大二层技术',
              text3: '支持FCoE技术',
              text4: '支持SDN'
            }
          },
          {
            title: 'MAC交换功能',
            textObj: {
              text1: '支持静态配置和动态学习MAC地址',
              text2: '支持查看和清除MAC地址',
              text3: 'MAC地址老化时间可配置',
              text4: '支持MAC地址学习数量限制',
              text5: '支持MAC地址过滤功能',
              text6: '支持黑洞MAC表项',
              text7: '支持IEEE 802.1AE MacSec安全控制'
            }
          },
          {
            title: 'VLAN',
            textObj: {
              text1: '支持4K VLAN表项',
              text2: '支持GVRP',
              text3: '支持1：1和N：1 VLAN Mapping',
              text4: '支持基本QinQ和灵活QinQ功能',
              text5: '支持Private VLAN'
            }
          },
          {
            title: 'STP',
            textObj: {
              text1: '支持802.1D（STP）、802.1W（RSTP）、802.1S（MSTP）',
              text2: '支持BPDU保护、根保护、环路保护'
            }
          },
          {
            title: '组播',
            textObj: {
              text1: '支持IGMP v1/v2/v3',
              text2: '支持IGMP Snooping',
              text3: '支持IGMP Fast Leave',
              text4: '支持组播组策略及组播组数量限制',
              text5: '支持组播流量跨VLAN复制',
              text6: '支持PIM-SM、PIM-DM'
            }
          },
          {
            title: 'IPv4',
            textObj: {
              text1: '支持静态路由、RIP v1/v2、OSPF、BGP',
              text2: '支持策略路由',
              text3: '支持等价路由实现负载均衡',
              text4: '支持OSPF、BGP的Graceful Restart',
              text5: '支持BFD for OSPF、BGP'
            }
          },
          {
            title: 'IPv6',
            textObj: {
              text1: '支持ICMPv6、DHCPv6、ACLv6、IPv6 Telnet',
              text2: '支持IPv6邻居发现',
              text3: '支持Path MTU发现',
              text4: '支持MLD 、MLD Snooping',
              text5: '支持IPv6静态路由、RIPng、OSPFv3、BGP4+',
              text6: '支持手工隧道、ISATAP隧道、6to4隧道'
            }
          },
          {
            title: 'MPLS VPN',
            textObj: {
              text1: '支持LDP协议',
              text2: '支持MCE',
              text3: '支持MPLS VPN的P/PE功能要求',
              text4: '支持MPLS TE',
              text5: '支持MPLS OAM'
            }
          },
          {
            title: 'QoS',
            textObj: {
              text1: '支持基于L2/L3/L4协议头各字段的流量分类',
              text2: '支持CAR流量限制',
              text3: '支持802.1P/DSCP优先级重新标记',
              text4: '支持SP、WRR、SP+WRR等队列调度方式',
              text5: '支持Tail-Drop、WRED等拥塞避免机制',
              text6: '支持流量监管与流量整形',
              text7:
                '支持Ingress和Egress ACL，支持匹配L2、L3、L4和IP五元组，进行复制、转发、丢弃',
              text8: '支持Hash同源同宿负载均衡，保证流量输出的会话完整性'
            }
          },
          {
            title: '安全特性',
            textObj: {
              text1: '支持基于L2/L3/L4的ACL流识别与过滤安全机制',
              text2: '支持防DDoS攻击、TCP的SYN Flood攻击、UDP Flood攻击等',
              text3: '支持对组播、广播、未知单播报文的抑制功能',
              text4: '支持端口隔离',
              text5: '支持端口安全、IP+MAC+端口绑定',
              text6: '支持DHCP Snooping、DHCP Option 82',
              text7: '支持IEEE 802.1x认证',
              text8: '支持Radius、BDTacacs+认证',
              text9: '支持uRPF',
              text10: '支持命令行分级保护'
            }
          },
          {
            title: '可靠性',
            textObj: {
              text1: '支持双主控冗余',
              text2: '支持多电源备份',
              text3: '支持主控、业务卡热插拔及业务自动恢复',
              text4: '支持静态/LACP方式链路聚合，支持跨业务卡的链路聚合',
              text5: '支持EAPS等环网保护',
              text6: '支持VRRP',
              text7: '支持以太网OAM 802.3ah/802.1ag/ITU-Y.1731',
              text8: '支持GR for OSPF、BGP',
              text9: '支持BFD for OSPF、BGP',
              text10: '支持ISSU业务不中断系统升级'
            }
          },
          {
            title: '管理与维护',
            textObj: {
              text1: '支持Console、Telnet、SSH',
              text2: '支持SNMP v1/v2/v3',
              text3: '支持TFTP方式的文件上传、下载管理',
              text4: '支持RMON',
              text5: '支持sFLOW、Netflow流量统计分析'
            }
          },
          {
            title: '增值业务',
            textObj: {
              text1: '支持POE功能'
            }
          },
          {
            title: '绿色节能',
            textObj: {
              text1: '支持IEEE 802.3az绿色能效以太网'
            }
          },
          {
            title: '环境要求',
            textObj: {
              text1: '工作温/湿度：0℃-40℃，10%-90%无凝露',
              text2: '存储温/湿度：-20℃-70℃；5%-95%无凝露'
            }
          },
          {
            title: '电源',
            textObj: {
              text1: 'AC：100V-240V，50Hz±10%',
              text2: 'DC：-48V'
            }
          },
          {
            title: '机箱尺寸mm（宽×深×高）',
            textObj: {
              text1: '482×564×48611U'
            },
            textObj2: {
              text1: '482×564×62014U'
            },
            textObj3: {
              text1: '482×564×79818U'
            },
            textObj5: {
              text1: '482×564×97522U'
            }
          }
        ]
      }
    ]
  },
  {
    name: 'S6803',
    module: '通讯',
    sortNum: 4,
    devieceData: {
      img: require('@/assets/hardware/communication4.png'),
      zhName: '核心交换机',
      enName: 'TURBO S6803',
      content:
        'TURBO S6800系列是北京捍御者信息科技有限公司面向大中型园区网、企业网推出的新一代核心交换机。'
    },
    tabsPane: [
      {
        label: '产品介绍',
        className: 'intro-class',
        dataList: [
          {
            textObj: {
              text1:
                '该系列产品采用业界先进的分布式多级交换矩阵架构，搭载TURBO自主知识产权的BDROS操作系统，在提供高性能的IPv4/v6线速交换服务基础上，结合不间断升级、不间断转发、优雅重启、冗余保护等多种高可靠性技术，从而保证了网络最长时间的不间断通信能力。源于捍御者先进的“GreenTouch”理念及智能芯片节能技术，大幅降低设备的能源消耗，低碳环保，有效降低了运营维护成本，为大中型企业网络提供了完美的解决方案。',
              text2:
                'TURBO S6800系列核心交换机有S6803、S6806、S6810三种产品形态，能够满足不同规模网络对设备的端口密度及性能要求。'
            }
          }
        ]
      },
      {
        label: '产品特性',
        className: 'property-class',
        dataList: [
          {
            title: '先进的硬件架构设计，业界领先的强大处理能力',
            textObj: {
              text1:
                '采用业界先进的分布式多级交换矩阵架构，搭载高性能的ASIC交换芯片和多核处理器，满足核心设备的高性能、高容量、高密度及企业未来可拓展的要求。',
              text2:
                '支持高密度的万兆业务板，并且实现板卡间三层全线速无阻塞交换。',
              text3: '支持向40GE和100GE端口的拓展能力。',
              text4: '支持向40GE和100GE端口的拓展能力。'
            }
          },
          {
            title: '高可靠性的设计',
            textObj: {
              text1:
                '基于HPS（Hitless Protection System）无中断保护系统，S6800的关键器件，如系统主控单元、电源系统、风扇系统均采用冗余设计，所有系统模块均可热插拔，支持故障时无缝切换，无需人工干预。',
              text2:
                'S6800支持STP/RSTP/MSTP协议，支持VRRP协议，同时支持环网保护，双上行主备链路保护，LACP链路聚合等简单高效的冗余保护机制。',
              text3:
                '支持ISSU（In-Service Software Upgrade）业务不中断系统升级，及GR（Graceful Restart）优雅重启for OSPF/BGP等路由协议，保证系统升级及主控切换时用户数据的不间断转发。',
              text4:
                '超高精度的BFD双向链路检测机制，通过和二、三层协议的联动，实现毫秒级的故障检测及业务恢复，极大程度地提高了网络系统的可靠性。'
            }
          },
          {
            title: '丰富的业务特性',
            textObj: {
              text1:
                '完善的二、三层组播路由协议，满足IPTV、多终端的高清视频监控和高清视频会议的接入需求',
              text2:
                '齐备的三层路由协议，超大的路由表容量，满足各种类型的网络互联需求，可组建超大型的园区网、企业网和行业用户专网。'
            }
          },
          {
            title: '全面的IPv6解决方案',
            textObj: {
              text1:
                '全面支持IPv6协议族，支持IPv6邻居发现、ICMPv6、Path MTU 发现、DHCPv6等IPv6特性。',
              text2:
                '支持基于IPv6的Ping、Traceroute、Telnet、SSH、ACL等，满足纯IPv6网络设备管理及业务控制的需要。',
              text3:
                '支持MLD、MLD Snooping等IPv6组播特性，支持IPv6静态路由、RIPng、OSPFv3、BGP4+等IPv6三层路由协议，为用户提供完善的IPv6二、三层解决方案。',
              text4:
                '支持丰富的IPv4向IPv6过渡技术，包括：IPv6手工隧道、自动隧道、6to4隧道、ISATAP隧道等隧道技术，保证IPv4网络向IPv6网络的平滑过渡。'
            }
          },
          {
            title: '完善的安全机制',
            textObj: {
              text1:
                '设备级安全防护：先进的硬件架构设计，硬件实现对报文的分级调度及保护，支持防范DoS、TCP的SYN Flood、UDP Flood、广播风暴、大流量等对设备的攻击；支持命令行分级保护，不同级别用户拥有不同管理权限；',
              text2:
                '完备的安全认证机制：支持IEEE 802.1x、Radius、BDTacacs+等，可为用户提供完备的安全认证机制。',
              text3:
                '增强的业务安全机制：支持相关路由协议的明文或MD5认证，支持uRPF逆向路由查找技术，可有效控制非法业务；硬件级报文深度检测和过滤技术，支持对控制报文和数据报文的深度检测，从而有效隔离非法数据报文，提高网络系统的安全性。'
            }
          },
          {
            title: '创新的绿色环保设计',
            textObj: {
              text1:
                'S6800传承捍御者先进的“GreenTouch”理念，整机功率最大仅1000W，节能环保。',
              text2:
                '智能的电源管理系统：S6800采用先进的电源系统架构设计，实现高效的电源转换，独有的电源监控、缓启动、顺序上电等功能，实时监测整机运行状态，智能调节，深度节能。',
              text3:
                '智能的风扇管理系统：智能风扇设计，支持自动调速及独立分区控制，有效降低转速、减少噪声，同时延长了风扇的使用寿命。',
              text4:
                '支持能效以太网功能，遵循国际标准IEEE 802.3az，有效降低了能源消耗。'
            }
          }
        ]
      },
      {
        label: '产品参数',
        className: 'params-class',
        dataList: [
          {
            title: '项目',
            textObj: {
              text1: 'S6803'
            },
            textObj2: {
              text1: 'S6806'
            },
            textObj3: {
              text1: 'S6810'
            }
          },
          {
            title: '交换容量',
            textObj: {
              text1: '7.2Tbps/45.6Tbps'
            },
            textObj2: {
              text1: '17.92Tbps/81.6Tbps'
            },
            textObj3: {
              text1: '23.68Tbps/153.6Tbps'
            }
          },
          {
            title: '包转发率',
            textObj: {
              text1: '2160Mpps/15120Mpps'
            },
            textObj2: {
              text1: '2880Mpps/25920Mpps'
            },
            textObj3: {
              text1: '3840Mpps/47520Mpps'
            }
          },
          {
            title: '业务槽位数量',
            textObj: {
              text1: '2'
            },
            textObj2: {
              text1: '4'
            },
            textObj3: {
              text1: '8'
            }
          },
          {
            title: 'MAC交换功能',
            textObj: {
              text1: '支持静态配置和动态学习MAC地址',
              text2: '支持查看和清除MAC地址',
              text3: 'MAC地址老化时间可配置',
              text4: '支持MAC地址学习数量限制',
              text5: '支持MAC地址过滤功能',
              text6: '支持黑洞MAC表项',
              text7: '支持IEEE 802.1AE MacSec安全控制'
            }
          },
          {
            title: 'VLAN',
            textObj: {
              text1: '支持4K VLAN表项',
              text2: '支持GVRP',
              text3: '支持1：1和N：1 VLAN Mapping',
              text4: '支持基本QinQ和灵活QinQ功能',
              text5: '支持Private VLAN'
            }
          },
          {
            title: 'STP',
            textObj: {
              text1: '支持802.1D（STP）、802.1W（RSTP）、802.1S（MSTP）',
              text2: '支持BPDU保护、根保护、环路保护'
            }
          },
          {
            title: '组播',
            textObj: {
              text1: '支持IGMP v1/v2/v3',
              text2: '支持IGMP Snooping',
              text3: '支持IGMP Fast Leave',
              text4: '支持组播组策略及组播组数量限制',
              text5: '支持组播流量跨VLAN复制',
              text6: '支持PIM-SM、PIM-DM'
            }
          },
          {
            title: 'IPV4',
            textObj: {
              text1: '支持ICMPv6、DHCPv6、ACLv6、IPv6 Telnet',
              text2: '支持IPv6邻居发现',
              text3: '支持Path MTU发现',
              text4: '支持MLD 、MLD Snooping',
              text5: '支持IPv6静态路由、RIPng、OSPFv3、BGP4+',
              text6: '支持手工隧道、ISATAP隧道、6to4隧道'
            }
          },
          {
            title: 'IPV4',
            textObj: {
              text1: '支持基于L2/L3/L4协议头各字段的流量分类',
              text2: '支持CAR流量限制',
              text3: '支持802.1P/DSCP优先级重新标记',
              text4: '支持SP、WRR、SP+WRR等队列调度方式',
              text5: '支持Tail-Drop、WRED等拥塞避免机制',
              text6: '支持流量监管与流量整形',
              text7:
                '支持Ingress和Egress ACL，支持匹配L2、L3、L4和IP五元组，进行复制、转发、丢弃',
              text8: '支持Hash同源同宿负载均衡，保证流量输出的会话完整性'
            }
          },
          {
            title: '安全特性',
            textObj: {
              text1: '支持基于L2/L3/L4的ACL流识别与过滤安全机制',
              text2: '支持防DDoS攻击、TCP的SYN Flood攻击、UDP Flood攻击等',
              text3: '支持对组播、广播、未知单播报文的抑制功能',
              text4: '支持端口隔离',
              text5: '支持端口安全、IP+MAC+端口绑定',
              text6: '支持DHCP Snooping、DHCP Option 82',
              text7: '支持IEEE 802.1x认证',
              text8: '支持Radius、BDTacacs+认证',
              text9: '支持uRPF',
              text10: '支持命令行分级保护'
            }
          },
          {
            title: '可靠性',
            textObj: {
              text1: '支持双主控冗余（S6803除外）',
              text2: '支持电源1+1备份',
              text3: '支持主控、业务卡热插拔及业务自动恢复',
              text4: '支持静态/LACP方式链路聚合，支持跨业务卡的链路聚合',
              text5: '支持EAPS等环网保护',
              text6: '支持VRRP',
              text7: '支持GR for OSPF、BGP',
              text8: '支持BFD for OSPF、BGP',
              text9: '支持ISSU业务不中断系统升级'
            }
          },
          {
            title: '维护与管理',
            textObj: {
              text1: '支持Console、Telnet、SSH',
              text2: '支持SNMP v1/v2/v3',
              text3: '支持TFTP方式的文件上传、下载管理',
              text4: '支持RMON',
              text5: '支持sFLOW、Netflow流量统计分析'
            }
          },
          {
            title: '绿色节能',
            textObj: {
              text1: '支持IEEE 802.3az绿色能效以太网'
            }
          },
          {
            title: '环境要求',
            textObj: {
              text1: '工作温/湿度：0℃-40℃，10%-90%无凝露',
              text2: '存储温/湿度：-20℃-70℃；5%-95%无凝露'
            }
          },
          {
            title: '电源',
            textObj: {
              text1: 'AC：100V-240V，50Hz±10%',
              text2: 'DC：-48V'
            }
          },
          {
            title: '机箱尺寸mm（宽×深×高）',
            textObj: {
              text1: '482×548×266',
              text2: '6U'
            },
            textObj2: {
              text1: '482×548×399',
              text2: '9U'
            },
            textObj3: {
              text1: '482×548×533',
              text2: '12U'
            }
          },
          {
            title: '整机重量（空配）',
            textObj: {
              text1: '21.5kg'
            },
            textObj2: {
              text1: '26kg'
            },
            textObj3: {
              text1: '30.5kg'
            }
          }
        ]
      }
    ]
  },
  {
    name: 'S6806',
    module: '通讯',
    sortNum: 5,
    devieceData: {
      img: require('@/assets/hardware/communication6.png'),
      zhName: '核心交换机',
      enName: 'TURBO-S6806',
      content:
        'TURBO S6800系列是北京捍御者信息科技有限公司面向大中型园区网、企业网推出的新一代核心交换机。'
    },
    tabsPane: [
      {
        label: '产品介绍',
        className: 'intro-class',
        dataList: [
          {
            textObj: {
              text1:
                '该系列产品采用业界先进的分布式多级交换矩阵架构，搭载TURBO自主知识产权的BDROS操作系统，在提供高性能的IPv4/v6线速交换服务基础上，结合不间断升级、不间断转发、优雅重启、冗余保护等多种高可靠性技术，从而保证了网络最长时间的不间断通信能力。源于捍御者先进的“GreenTouch”理念及智能芯片节能技术，大幅降低设备的能源消耗，低碳环保，有效降低了运营维护成本，为大中型企业网络提供了完美的解决方案。',
              text2:
                'TURBO S6800系列核心交换机有S6803、S6806、S6810三种产品形态，能够满足不同规模网络对设备的端口密度及性能要求。'
            }
          }
        ]
      },
      {
        label: '产品特性',
        className: 'property-class',
        dataList: [
          {
            title: '先进的硬件架构设计，业界领先的强大处理能力',
            textObj: {
              text1:
                '采用业界先进的分布式多级交换矩阵架构，搭载高性能的ASIC交换芯片和多核处理器，满足核心设备的高性能、高容量、高密度及企业未来可拓展的要求。',
              text2:
                '支持高密度的万兆业务板，并且实现板卡间三层全线速无阻塞交换。',
              text3: '支持向40GE和100GE端口的拓展能力。',
              text4: '支持向40GE和100GE端口的拓展能力。'
            }
          },
          {
            title: '高可靠性的设计',
            textObj: {
              text1:
                '基于HPS（Hitless Protection System）无中断保护系统，S6800的关键器件，如系统主控单元、电源系统、风扇系统均采用冗余设计，所有系统模块均可热插拔，支持故障时无缝切换，无需人工干预。',
              text2:
                'S6800支持STP/RSTP/MSTP协议，支持VRRP协议，同时支持环网保护，双上行主备链路保护，LACP链路聚合等简单高效的冗余保护机制。',
              text3:
                '支持ISSU（In-Service Software Upgrade）业务不中断系统升级，及GR（Graceful Restart）优雅重启for OSPF/BGP等路由协议，保证系统升级及主控切换时用户数据的不间断转发。',
              text4:
                '超高精度的BFD双向链路检测机制，通过和二、三层协议的联动，实现毫秒级的故障检测及业务恢复，极大程度地提高了网络系统的可靠性。'
            }
          },
          {
            title: '丰富的业务特性',
            textObj: {
              text1:
                '完善的二、三层组播路由协议，满足IPTV、多终端的高清视频监控和高清视频会议的接入需求',
              text2:
                '齐备的三层路由协议，超大的路由表容量，满足各种类型的网络互联需求，可组建超大型的园区网、企业网和行业用户专网。'
            }
          },
          {
            title: '全面的IPv6解决方案',
            textObj: {
              text1:
                '全面支持IPv6协议族，支持IPv6邻居发现、ICMPv6、Path MTU 发现、DHCPv6等IPv6特性。',
              text2:
                '支持基于IPv6的Ping、Traceroute、Telnet、SSH、ACL等，满足纯IPv6网络设备管理及业务控制的需要。',
              text3:
                '支持MLD、MLD Snooping等IPv6组播特性，支持IPv6静态路由、RIPng、OSPFv3、BGP4+等IPv6三层路由协议，为用户提供完善的IPv6二、三层解决方案。',
              text4:
                '支持丰富的IPv4向IPv6过渡技术，包括：IPv6手工隧道、自动隧道、6to4隧道、ISATAP隧道等隧道技术，保证IPv4网络向IPv6网络的平滑过渡。'
            }
          },
          {
            title: '完善的安全机制',
            textObj: {
              text1:
                '设备级安全防护：先进的硬件架构设计，硬件实现对报文的分级调度及保护，支持防范DoS、TCP的SYN Flood、UDP Flood、广播风暴、大流量等对设备的攻击；支持命令行分级保护，不同级别用户拥有不同管理权限；',
              text2:
                '完备的安全认证机制：支持IEEE 802.1x、Radius、BDTacacs+等，可为用户提供完备的安全认证机制。',
              text3:
                '增强的业务安全机制：支持相关路由协议的明文或MD5认证，支持uRPF逆向路由查找技术，可有效控制非法业务；硬件级报文深度检测和过滤技术，支持对控制报文和数据报文的深度检测，从而有效隔离非法数据报文，提高网络系统的安全性。'
            }
          },
          {
            title: '创新的绿色环保设计',
            textObj: {
              text1:
                'S6800传承捍御者先进的“GreenTouch”理念，整机功率最大仅1000W，节能环保。',
              text2:
                '智能的电源管理系统：S6800采用先进的电源系统架构设计，实现高效的电源转换，独有的电源监控、缓启动、顺序上电等功能，实时监测整机运行状态，智能调节，深度节能。',
              text3:
                '智能的风扇管理系统：智能风扇设计，支持自动调速及独立分区控制，有效降低转速、减少噪声，同时延长了风扇的使用寿命。',
              text4:
                '支持能效以太网功能，遵循国际标准IEEE 802.3az，有效降低了能源消耗。'
            }
          }
        ]
      },
      {
        label: '产品参数',
        className: 'params-class',
        dataList: [
          {
            title: '项目',
            textObj: {
              text1: 'S6803'
            },
            textObj2: {
              text1: 'S6806'
            },
            textObj3: {
              text1: 'S6810'
            }
          },
          {
            title: '交换容量',
            textObj: {
              text1: '7.2Tbps/45.6Tbps'
            },
            textObj2: {
              text1: '17.92Tbps/81.6Tbps'
            },
            textObj3: {
              text1: '23.68Tbps/153.6Tbps'
            }
          },
          {
            title: '包转发率',
            textObj: {
              text1: '2160Mpps/15120Mpps'
            },
            textObj2: {
              text1: '2880Mpps/25920Mpps'
            },
            textObj3: {
              text1: '3840Mpps/47520Mpps'
            }
          },
          {
            title: '业务槽位数量',
            textObj: {
              text1: '2'
            },
            textObj2: {
              text1: '4'
            },
            textObj3: {
              text1: '8'
            }
          },
          {
            title: 'MAC交换功能',
            textObj: {
              text1: '支持静态配置和动态学习MAC地址',
              text2: '支持查看和清除MAC地址',
              text3: 'MAC地址老化时间可配置',
              text4: '支持MAC地址学习数量限制',
              text5: '支持MAC地址过滤功能',
              text6: '支持黑洞MAC表项',
              text7: '支持IEEE 802.1AE MacSec安全控制'
            }
          },
          {
            title: 'VLAN',
            textObj: {
              text1: '支持4K VLAN表项',
              text2: '支持GVRP',
              text3: '支持1：1和N：1 VLAN Mapping',
              text4: '支持基本QinQ和灵活QinQ功能',
              text5: '支持Private VLAN'
            }
          },
          {
            title: 'STP',
            textObj: {
              text1: '支持802.1D（STP）、802.1W（RSTP）、802.1S（MSTP）',
              text2: '支持BPDU保护、根保护、环路保护'
            }
          },
          {
            title: '组播',
            textObj: {
              text1: '支持IGMP v1/v2/v3',
              text2: '支持IGMP Snooping',
              text3: '支持IGMP Fast Leave',
              text4: '支持组播组策略及组播组数量限制',
              text5: '支持组播流量跨VLAN复制',
              text6: '支持PIM-SM、PIM-DM'
            }
          },
          {
            title: 'IPV4',
            textObj: {
              text1: '支持ICMPv6、DHCPv6、ACLv6、IPv6 Telnet',
              text2: '支持IPv6邻居发现',
              text3: '支持Path MTU发现',
              text4: '支持MLD 、MLD Snooping',
              text5: '支持IPv6静态路由、RIPng、OSPFv3、BGP4+',
              text6: '支持手工隧道、ISATAP隧道、6to4隧道'
            }
          },
          {
            title: 'IPV4',
            textObj: {
              text1: '支持基于L2/L3/L4协议头各字段的流量分类',
              text2: '支持CAR流量限制',
              text3: '支持802.1P/DSCP优先级重新标记',
              text4: '支持SP、WRR、SP+WRR等队列调度方式',
              text5: '支持Tail-Drop、WRED等拥塞避免机制',
              text6: '支持流量监管与流量整形',
              text7:
                '支持Ingress和Egress ACL，支持匹配L2、L3、L4和IP五元组，进行复制、转发、丢弃',
              text8: '支持Hash同源同宿负载均衡，保证流量输出的会话完整性'
            }
          },
          {
            title: '安全特性',
            textObj: {
              text1: '支持基于L2/L3/L4的ACL流识别与过滤安全机制',
              text2: '支持防DDoS攻击、TCP的SYN Flood攻击、UDP Flood攻击等',
              text3: '支持对组播、广播、未知单播报文的抑制功能',
              text4: '支持端口隔离',
              text5: '支持端口安全、IP+MAC+端口绑定',
              text6: '支持DHCP Snooping、DHCP Option 82',
              text7: '支持IEEE 802.1x认证',
              text8: '支持Radius、BDTacacs+认证',
              text9: '支持uRPF',
              text10: '支持命令行分级保护'
            }
          },
          {
            title: '可靠性',
            textObj: {
              text1: '支持双主控冗余（S6803除外）',
              text2: '支持电源1+1备份',
              text3: '支持主控、业务卡热插拔及业务自动恢复',
              text4: '支持静态/LACP方式链路聚合，支持跨业务卡的链路聚合',
              text5: '支持EAPS等环网保护',
              text6: '支持VRRP',
              text7: '支持GR for OSPF、BGP',
              text8: '支持BFD for OSPF、BGP',
              text9: '支持ISSU业务不中断系统升级'
            }
          },
          {
            title: '维护与管理',
            textObj: {
              text1: '支持Console、Telnet、SSH',
              text2: '支持SNMP v1/v2/v3',
              text3: '支持TFTP方式的文件上传、下载管理',
              text4: '支持RMON',
              text5: '支持sFLOW、Netflow流量统计分析'
            }
          },
          {
            title: '绿色节能',
            textObj: {
              text1: '支持IEEE 802.3az绿色能效以太网'
            }
          },
          {
            title: '环境要求',
            textObj: {
              text1: '工作温/湿度：0℃-40℃，10%-90%无凝露',
              text2: '存储温/湿度：-20℃-70℃；5%-95%无凝露'
            }
          },
          {
            title: '电源',
            textObj: {
              text1: 'AC：100V-240V，50Hz±10%',
              text2: 'DC：-48V'
            }
          },
          {
            title: '机箱尺寸mm（宽×深×高）',
            textObj: {
              text1: '482×548×266',
              text2: '6U'
            },
            textObj2: {
              text1: '482×548×399',
              text2: '9U'
            },
            textObj3: {
              text1: '482×548×533',
              text2: '12U'
            }
          },
          {
            title: '整机重量（空配）',
            textObj: {
              text1: '21.5kg'
            },
            textObj2: {
              text1: '26kg'
            },
            textObj3: {
              text1: '30.5kg'
            }
          }
        ]
      }
    ]
  },
  {
    name: 'S3900',
    module: '通讯',
    sortNum: 6,
    devieceData: {
      img: require('@/assets/hardware/communication5.png'),
      zhName: '万兆交换机',
      enName: 'TURBO S3900',
      content:
        'TURBO S3900系列是北京捍御者信息科技有限公司面向下一代IP城域网、大型园区网、企业网推出的新一代汇聚万兆交换机。'
    },
    tabsPane: [
      {
        label: '产品介绍',
        className: 'intro-class',
        dataList: [
          {
            textObj: {
              text1:
                'TURBO S3900系列是北京捍御者信息科技有限公司面向下一代IP城域网、大型园区网、企业网推出的新一代汇聚万兆交换机。该产品采用业界先进的硬件架构，搭载TURBO自主知识产权的BDROS操作系统，在提供高性能的L2/L3/L4线速交换服务基础上，进一步融合了IPv6、MPLS VPN、网络安全、等多种网络业务，结合不间断升级、不间断转发、优雅重启、冗余保护等多种高可靠性技术，从而保证了网络最长时间的不间断通信能力。',
              text2:
                'TURBO S3900系列有S3930、S3938F、S3954、S3954M多种产品形态，支持高达48个千兆+6个万兆端口，能够满足不同规模网络对设备的端口密度及性能要求。'
            }
          }
        ]
      },
      {
        label: '产品特性',
        className: 'property-class',
        dataList: [
          {
            title: '先进的硬件架构设计，业界领先的强大处理能力',
            textObj: {
              text1:
                '在1U盒式交换机上，实现48个千兆+6个万兆的高端口密度，搭载高性能的ASIC交换芯片，可满足各种复杂场景的应用需求。'
            }
          },
          {
            title: '创新的VSS虚拟化集群交换技术',
            textObj: {
              text1:
                'S3900系列支持捍御者创新的BVSS（TURBO Virtual Switch System）虚拟化集群交换技术，可以将多台物理设备虚拟化为逻辑上的一台设备，其虚拟系统的性能、可靠性、及管理相比于独立的物理设备具有无与伦比的优越性。',
              text2:
                '性能翻倍：虚拟化系统可以充分利用物理设备间的每一条链路，避免传统组网模型生成树协议对链路的阻塞，物尽其用，性能翻倍，最大程度保护原有链路投资。',
              text3:
                '高可靠性：基于先进的分布式处理技术，通过高效的跨物理设备链路聚合功能，实现逻辑控制平面、业务控制平面和业务数据平面的三平面分离，提供不间断的三层路由转发，避免了单点故障所造成的业务中断，极大地提高了虚拟系统的可靠性。',
              text4:
                '易管理：整个虚拟系统实现单一IP统一管理，实际物理设备对用户透明，简化了对网络设备和网络拓扑的管理，大大提高了网络运营效率，从而有效降低运营维护成本。'
            }
          },
          {
            title: '运营级的高可靠性',
            textObj: {
              text1:
                '基于HPS（Hitless Protection System）无中断保护系统，S3900系列的关键器件，如电源模块均为双备份、可热插拔，支持故障时无缝切换，无需人工干预。',
              text2:
                'S3900系列支持STP/RSTP/MSTP协议，支持VRRP协议，同时支持环网保护，双上行主备链路保护，LACP链路聚合等简单高效的冗余保护机制。',
              text3:
                '支持ISSU（In-Service Software Upgrade）业务不中断系统升级，保证系统升级时用户数据的不间断转发。',
              text4:
                '超高精度的BFD双向链路检测机制，通过和二、三层协议的联动，实现毫秒级的故障检测及业务恢复，极大程度地提高了网络系统的可靠性。',
              text5:
                '完善的以太网OAM机制，支持802.3ah、802.1ag和ITU-Y.1731，通过对网络运行状态的实时监控，实现对故障的快速检测与定位。',
              text6:
                'S3900系列软硬件的高可靠性设计，满足电信级业务50ms的故障恢复时间要求，真正做到了运营级核心设备5个9（99.999%）的高可靠性。'
            }
          },
          {
            title: '丰富的业务特性',
            textObj: {
              text1:
                '完善的二、三层组播路由协议，满足IPTV、多终端的高清视频监控和高清视频会议的接入需求；',
              text2:
                '齐备的三层路由协议，超大的路由表容量，满足各种类型的网络互联需求，可组建超大型的园区网、企业网和行业用户专网。',
              text3:
                '全面支持二、三层的MPLS VPN，可组建超大型的MPLS VPN核心网络，满足行业专网VPN用户、企业网VPN用户的接入需求。'
            }
          },
          {
            title: '全面的IPv6解决方案',
            textObj: {
              text1:
                '全面支持IPv6协议族，支持IPv6邻居发现、ICMPv6、Path MTU 发现、DHCPv6等IPv6特性。',
              text2:
                '支持基于IPv6的Ping、Traceroute、Telnet、SSH、ACL等，满足纯IPv6网络设备管理及业务控制的需要',
              text3:
                '支持MLD、MLD Snooping等IPv6组播特性，支持IPv6静态路由、RIPng、OSPFv3、BGP4+等IPv6三层路由协议，为用户提供完善的IPv6二、三层解决方案。',
              text4:
                '支持丰富的IPv4向IPv6过渡技术，包括：IPv6手工隧道、自动隧道、6to4隧道、ISATAP隧道等隧道技术，保证IPv4网络向IPv6网络的平滑过渡。'
            }
          },
          {
            title: '完善的安全机制',
            textObj: {
              text1:
                '设备级安全防护：先进的硬件架构设计，硬件实现对报文的分级调度及保护，支持防范DoS、TCP的SYN Flood、UDP Flood、广播风暴、大流量等对设备的攻击；支持命令行分级保护，不同级别用户拥有不同管理权限；',
              text2:
                '完备的安全认证机制：支持IEEE 802.1x、Radius、BDTacacs+等，可为用户提供完备的安全认证机制。',
              text3:
                '增强的业务安全机制：支持相关路由协议的明文或MD5认证，支持uRPF逆向路由查找技术，可有效控制非法业务；硬件级报文深度检测和过滤技术，支持对控制报文和数据报文的深度检测，从而有效隔离非法数据报文，提高网络系统的安全性。'
            }
          },
          {
            title: '创新的绿色环保设计',
            textObj: {
              text1:
                '智能的电源管理系统：S3900系列采用先进的电源系统架构设计，实现高效的电源转换，独有的电源监控、缓启动等功能，实时监测整机运行状态，智能调节，深度节能。',
              text2:
                '智能的风扇管理系统：智能风扇设计，支持自动调速，有效降低转速、减少噪声，同时延长了风扇的使用寿命。',
              text3:
                '支持能效以太网功能，遵循国际标准IEEE 802.3az，有效降低了能源消耗。'
            }
          }
        ]
      },
      {
        label: '产品参数',
        className: 'params-class',
        dataList: [
          {
            title: '项目',
            textObj: {
              text1: 'S3930'
            },
            textObj2: {
              text1: 'S3938F'
            },
            textObj3: {
              text1: 'S3954'
            },
            textObj4: {
              text1: 'S3954M'
            }
          },
          {
            title: '交换容量',
            textObj: {
              text1: '598G/5.98T'
            }
          },
          {
            title: '包转发率',
            textObj: {
              text1: '222M/342M'
            },
            textObj2: {
              text1: '222M/342M'
            },
            textObj3: {
              text1: '252M/342M'
            },
            textObj4: {
              text1: '252M/342M'
            }
          },
          {
            title: 'MAC容量',
            textObj: {
              text1: '32K/64K'
            }
          },
          {
            title: '业务端口',
            textObj: {
              text1: '24千兆电口',
              text2: '6万兆光口'
            },
            textObj2: {
              text1: '24千兆光口',
              text2: '8千兆电口',
              text3: '6万兆光口'
            },
            textObj3: {
              text1: '48千兆电口',
              text2: '6万兆光口'
            },
            textObj4: {
              text1: '24千兆电口',
              text2: '24千兆光口',
              text3: '6万兆光口'
            }
          },
          {
            title: '机箱尺寸',
            textObj: {
              text1: '440*350*44（mm）'
            }
          },
          {
            title: '电源',
            textObj: {
              text1: 'AC：100V-240V，50Hz±10%',
              text2: 'DC：-36V~-72V',
              text3: '热插拔双电源可选'
            }
          },
          {
            title: '环境要求',
            textObj: {
              text1: '工作温/湿度：0℃-50℃，10%-90%无凝露',
              text2: '存储温/湿度：-20℃-70℃；5%-95%无凝露'
            }
          },
          {
            title: 'Mac交换',
            textObj: {
              text1: '支持静态配置和动态学习MAC地址',
              text2: '支持查看和清除MAC地址',
              text3: 'MAC地址老化时间可配置',
              text4: '支持MAC地址学习数量限制',
              text5: '支持MAC地址过滤功能',
              text6: '支持黑洞MAC表项',
              text7: '支持IEEE 802.1AE MacSec安全控制'
            }
          },
          {
            title: 'VLAN',
            textObj: {
              text1: '支持4K VLAN表项',
              text2: '支持GVRP',
              text3: '支持1：1和N：1 VLAN Mapping',
              text4: '支持基本QinQ功能',
              text5: '支持灵活QinQ功能',
              text6: '支持Private VLAN'
            }
          },
          {
            title: 'STP',
            textObj: {
              text1: '支持802.1D（STP）、802.1W（RSTP）、802.1S（MSTP）',
              text2: '支持BPDU保护、根保护、环路保护'
            }
          },
          {
            title: '组播',
            textObj: {
              text1: '支持IGMP v1/v2/v3',
              text2: '支持IGMP Snooping',
              text3: '支持IGMP Fast Leave',
              text4: '支持组播组策略及组播组数量限制',
              text5: '支持组播流量跨VLAN复制',
              text6: '支持PIM-SM、PIM-DM'
            }
          },
          {
            title: 'IPv4',
            textObj: {
              text1: '支持静态路由、RIP v1/v2、OSPF、BGP、IS-IS、BEIGRP',
              text2: '支持策略路由',
              text3: '支持等价路由实现负载均衡',
              text4: '支持BFD  for OSPF、BGP'
            }
          },
          {
            title: 'IPv6',
            textObj: {
              text1: '支持ICMPv6、DHCPv6、ACLv6、IPv6 Telnet',
              text2: '支持IPv6邻居发现',
              text3: '支持Path MTU发现',
              text4: '支持MLD Snooping',
              text5: '支持IPv6静态路由、RIPng、OSPFv3、BGP4+',
              text6: '支持手工隧道、ISATAP隧道、6to4隧道'
            }
          },
          {
            title: 'MPLS VPN',
            textObj: {
              text1: '支持LDP协议',
              text2: '支持MCE',
              text3: '支持MPLS VPN的P/PE功能要求',
              text4: '支持MPLS TE',
              text5: '支持MPLS OAM'
            }
          },
          {
            title: 'QoS',
            textObj: {
              text1: '支持基于L2/L3/L4协议头各字段的流量分类',
              text2: '支持CAR流量限制',
              text3: '支持802.1P/DSCP优先级重新标记',
              text4: '支持SP、WRR、SP+WRR等队列调度方式',
              text5: '支持Tail-Drop、WRED等拥塞避免机制',
              text6: '支持流量监管与流量整形',
              text7:
                '支持Ingress和Egress ACL，支持匹配L2、L3、L4和IP五元组，进行复制、转发、丢弃',
              text8: '支持Hash同源同宿负载均衡，保证流量输出的会话完整性'
            }
          },
          {
            title: '安全特性',
            textObj: {
              text1: '支持基于L2/L3/L4的ACL流识别与过滤安全机制',
              text2: '支持防DDoS攻击、TCP的SYN Flood攻击、UDP Flood攻击等',
              text3: '支持对组播、广播、未知单播报文的抑制功能',
              text4: '支持端口隔离',
              text5: '支持端口安全、IP+MAC+端口绑定',
              text6: '支持DHCP Snooping、DHCP Option 82',
              text7: '支持IEEE 802.1x认证',
              text8: '支持Radius、BDTacacs+认证',
              text9: '支持uRPF',
              text10: '支持命令行分级保护'
            }
          },
          {
            title: '可靠性',
            textObj: {
              text1: '可选支持电源1+1备份',
              text2: '支持静态/LACP方式链路聚合，支持跨业务卡的链路聚合',
              text3: '支持EAPS，ERPS环网保护',
              text4: '支持HSRP，VRRP热备协议',
              text5: '支持GR for OSPF、BGP',
              text6: '支持ISSU业务不中断系统升级'
            }
          },
          {
            title: '管理维护',
            textObj: {
              text1: '支持Console、Telnet、SSH 2.0',
              text2: '支持ZTP零接触配置开通（Zero Touch Provisioning）',
              text3: '支持基于浏览器WEB方式管理',
              text4: '支持SNMP v1/v2/v3',
              text5: '支持TFTP方式的文件上传、下载管理',
              text6: '支持RMON事件历史记录',
              text7: '支持sFLOW等流量统计分析'
            }
          },
          {
            title: '绿色节能',
            textObj: {
              text1: '支持IEEE 802.3az绿色能效以太网'
            }
          }
        ]
      }
    ]
  },
  {
    name: 'S2900',
    module: '通讯',
    sortNum: 7,
    devieceData: {
      img: require('@/assets/hardware/communication7.png'),
      zhName: '万兆准三层交换机',
      enName: 'TURBO S2900',
      content:
        'TURBO S2900系列交换机是北京捍御者信息科技有限公司面向运营商IP城域网、政企网、网吧、以及无盘工作环境推出的新一代智能交换机。'
    },
    tabsPane: [
      {
        label: '产品介绍',
        className: 'intro-class',
        dataList: [
          {
            textObj: {
              text1:
                'TURBO S2900系列交换机是北京捍御者信息科技有限公司面向运营商IP城域网、政企网、网吧、以及无盘工作环境推出的新一代智能交换机。S2900基于新一代的高性能硬件和捍御者自主知识产权的BDROS软件平台，支持强大的ACL、灵活QinQ、1:1和N:1 VLAN交换、以太网OAM，运营级QoS、工业级万兆以太环网等功能，支持三层路由协议。使该系列产品能够满足各种复杂场景的应用需求。',
              text2:
                'TURBO S2900系列交换机有S2928E、S2928EF、S2954三种产品形态，能够满足不同规模网络的应用需求。'
            }
          }
        ]
      },
      {
        label: '产品特性',
        className: 'property-class',
        dataList: [
          {
            title: '创新的VSS虚拟化集群交换技术',
            textObj: {
              text1:
                'S2900系列支持捍御者创新的BVSS（TURBO Virtual Switch System）虚拟化集群交换技术，可以将多台物理设备虚拟化为逻辑上的一台设备，其虚拟系统的性能、可靠性、及管理相比于独立的物理设备具有无与伦比的优越性。',
              text2:
                '性能翻倍：虚拟化系统可以充分利用物理设备间的每一条链路，避免传统组网模型生成树协议对链路的阻塞，物尽其用，性能翻倍，最大程度保护原有链路投资。',
              text3:
                '高可靠性：基于先进的分布式处理技术，通过高效的跨物理设备链路聚合功能，实现逻辑控制平面、业务控制平面和业务数据平面的三平面分离，提供不间断的三层路由转发，避免了单点故障所造成的业务中断，极大地提高了虚拟系统的可靠性。',
              text4:
                '易管理：整个虚拟系统实现单一IP统一管理，实际物理设备对用户透明，简化了对网络设备和网络拓扑的管理，大大提高了网络运营效率，从而有效降低运营维护成本。'
            }
          },
          {
            title: '工业级以太环网功能，零延时，零丢包',
            textObj: {
              text1:
                '支持工业级的以太环网保护协议，保护倒换时间<50ms，历经捍御者在电力、轨道交通、军工等行业多年使用验证，可实现零丢包的高可靠性要求。'
            }
          },
          {
            title: '电信级以太网交换机',
            textObj: {
              text1:
                '支持电信级的以太环网保护协议，保护倒换时间<50ms；同时支持STP/RSTP/MSTP协议，双上行主备链路保护，LACP链路聚合等简单高效的冗余保护机制，满足电信级高可靠性的要求。',
              text2:
                '完善的以太网OAM机制，通过对网络运行状态的实时监控，实现对故障的快速检测与定位。',
              text3:
                '强大的ACL功能，支持基于物理端口、VLAN、MAC、IP、协议端口号等L2~L7层数据的访问和控制，为用户提供了灵活多样的策略控制手段。',
              text4:
                '支持ISSU（In-Service Software Upgrade）业务不中断系统升级，保证系统升级时用户数据的不间断转发。',
              text5:
                '支持丰富的二层组播功能，包括IGMP-Snooping，用户快速离开机制及跨VLAN组播复制功能。'
            }
          },
          {
            title: '运营级的QoS策略',
            textObj: {
              text1:
                '支持基于VLAN、MAC、源地址、目的地址、IP协议、优先级等实现对复杂流的分类功能，并且支持对优先级进行重新标记，为用户对业务的优化提供了可靠有效的手段。',
              text2:
                '提供灵活的带宽控制策略，支持基于端口或者基于流的限速功能，保证各个端口的线速转发，从而有效地保证高品质的语音、视频和数据等网络业务的质量。',
              text3: '每个端口硬件支持8个优先级队列',
              text4: '支持SP、WRR或SP+WRR等多种队列调度算法。'
            }
          },
          {
            title: '全面的IPv6解决方案',
            textObj: {
              text1:
                '全面支持IPv6协议族，支持IPv6邻居发现、ICMPv6、Path MTU 发现等IPv6特性。',
              text2:
                '支持基于IPv6的Ping、Traceroute、Telnet、SSH、ACL等，满足纯IPv6网络设备管理及业务控制的需要。'
            }
          },
          {
            title: '完善的安全机制',
            textObj: {
              text1:
                '￭设备级安全防护：先进的硬件架构设计，硬件实现对报文的分级调度及保护，支持防范DoS、TCP的SYN Flood、UDP Flood、广播风暴、大流量等对设备的攻击；支持命令行分级保护，不同级别用户拥有不同管理权限；',
              text2:
                '完备的安全认证机制：支持IEEE 802.1x、Radius、BDTacacs+等，可为用户提供完备的安全认证机制。',
              text3:
                '支持对广播、组播以及未知单播报文的抑制功能，保证设备在恶劣网络条件下的正常运行.',
              text4: '支持SP、WRR或SP+WRR等多种队列调度算法。',
              text5: '完善的环路检测机制，可以保证网络长时间的稳定运行.',
              text6:
                '提供同一VLAN中端口隔离，及DHCP-Snooping、IP+MAC+端口绑定等安全特性，进一步保证了用户数据安全.'
            }
          },
          {
            title: '灵活方便的管理维护',
            textObj: {
              text1: '支持Console、Telnet、SSH等多种管理方式。',
              text2:
                '支持WEB方式管理，更简单高效，便于工程及维护人员的安装调试。',
              text3: '支持TFTP方式的文件上传与下载管理。',
              text4:
                '支持ISSU（In-Service Software Upgrade）业务不中断系统升级。',
              text5:
                '支持标准的SNMP协议，配合捍御者的“NMS”智能网管平台，可以实现设备自动发现、网络拓扑管理、设备配置管理、性能数据统计分析、故障管理等功能，简化了网络管理，为用户提供全新的使用体验。'
            }
          }
        ]
      },
      {
        label: '产品参数',
        className: 'params-class',
        dataList: [
          {
            title: '项目',
            textObj: {
              text1: 'S2928E'
            },
            textObj2: {
              text1: 'S2928E'
            },
            textObj3: {
              text1: 'S2954'
            }
          },
          {
            title: '交换容量',
            textObj: {
              text1: '336Gbps/3.36Tbps'
            }
          },
          {
            title: '包转发率',
            textObj: {
              text1: '108Mpps'
            },
            textObj2: {
              text1: '108Mpps'
            },
            textObj3: {
              text1: '178Mpps'
            }
          },
          {
            title: 'MAC容量',
            textObj: {
              text1: '16K'
            },
            textObj2: {
              text1: '16K'
            },
            textObj3: {
              text1: '16K'
            }
          },
          {
            title: '端口描述',
            textObj: {
              text1: '24个千兆电口',
              text2: '4个万兆光口'
            },
            textObj2: {
              text1: '24个千兆光口',
              text2: '8个复用千兆电口',
              text3: '4个万兆光口'
            },
            textObj3: {
              text1: '48个千兆电口',
              text2: '6个万兆光口'
            }
          },
          {
            title: '机箱尺寸',
            textObj: {
              text1: '440×180×44'
            },
            textObj2: {
              text1: '440×280×44'
            },
            textObj3: {
              text1: '440×280×44'
            }
          },
          {
            title: '满载功耗',
            textObj: {
              text1: '<30W'
            },
            textObj2: {
              text1: '<40W'
            },
            textObj3: {
              text1: '<40W'
            }
          },
          {
            title: '工作温度',
            textObj: {
              text1: '-10~50°C'
            },
            textObj2: {
              text1: '1-10~50°C'
            },
            textObj3: {
              text1: '-10~50°C'
            }
          },
          {
            title: '湿度',
            textObj: {
              text1: '工作湿度：10%-90%无凝露',
              text2: '存储湿度：5%-95%无凝露'
            }
          },
          {
            title: '电源',
            textObj: {
              text1: 'AC：100V-240V，50Hz±10%',
              text2: 'DC：-36V~-72V （DC机型）'
            }
          },
          {
            title: '虚拟化',
            textObj: {
              text1: '支持bvss虚拟化'
            }
          },
          {
            title: 'Mac交换',
            textObj: {
              text1: '支持静态配置和动态学习MAC地址',
              text2: '支持查看和清除MAC地址',
              text3: 'MAC地址老化时间可配置',
              text4: '支持MAC地址学习数量限制',
              text5: '支持MAC地址过滤功能',
              text6: '支持IEEE 802.1AE MacSec安全控制'
            }
          },
          {
            title: 'VLAN',
            textObj: {
              text1: '支持4K VLAN表项',
              text2: '支持GVRP',
              text3: '支持QinQ功能',
              text4: '支持Private VLAN',
              text5: '支持voice vlan'
            }
          },
          {
            title: '环网保护',
            textObj: {
              text1: '支持802.1D（STP）、802.1W（RSTP）、802.1S（MSTP）',
              text2: '支持BPDU保护、根保护、环路保护',
              text3: '支持EAPS以太网链路自动保护协议',
              text4: '支持ERPS以太网环网保护协议'
            }
          },
          {
            title: '组播',
            textObj: {
              text1: '支持IGMP v1/v2/v3',
              text2: '支持IGMP Snooping',
              text3: '支持IGMP Fast Leave',
              text4: '支持组播流量跨VLAN复制'
            }
          },
          {
            title: 'IP路由',
            textObj: {
              text1: '支持ipv4/ipv6双栈协议',
              text2: '支持静态路由',
              text3: '支持RIP 、OSPF动态路由',
              text4: '支持VRRP虚拟路由'
            }
          },
          {
            title: 'IPv6',
            textObj: {
              text1: '支持ICMPv6、DHCPv6、ACLv6、IPv6 Telnet',
              text2: '支持IPv6邻居发现',
              text3: '支持Path MTU发现',
              text4: '支持MLD v1/v2',
              text5: '支持MLD Snooping'
            }
          },
          {
            title: 'DHCP',
            textObj: {
              text1: '支持DHCP Server',
              text2: '支持DHCP Relay',
              text3: '支持DHCP Client',
              text4: '支持DHCP Snooping'
            }
          },
          {
            title: 'ACL',
            textObj: {
              text1: '支持二层、三层、四层ACL',
              text2: '支持IPv4、IPv6 ACL',
              text3: '支持VLAN ACL'
            }
          },
          {
            title: 'QoS',
            textObj: {
              text1: '支持基于L2/L3/L4协议头各字段的流量分类',
              text2: '支持CAR流量限制',
              text3: '支持802.1P/DSCP优先级重新标记',
              text4: '支持SP、WRR、SP+WRR等队列调度方式',
              text5: '支持Tail-Drop、WRED等拥塞避免机制',
              text6: '支持流量监管与流量整形'
            }
          },
          {
            title: '安全特性',
            textObj: {
              text1: '支持基于L2/L3/L4的ACL流识别与过滤安全机制',
              text2: '支持防DDoS攻击、TCP的SYN Flood攻击、UDP Flood攻击等',
              text3: '支持对组播、广播、未知单播报文的抑制功能',
              text4: '支持端口隔离',
              text5: '支持端口安全、IP+MAC+端口绑定',
              text6: '支持DHCP sooping，DHCP option82',
              text7: '支持IEEE 802.1x认证',
              text8: '支持Radius、BDTacacs+认证',
              text9: '支持命令行分级保护'
            }
          },
          {
            title: '可靠性',
            textObj: {
              text1: '支持静态/LACP方式链路聚合',
              text2: '支持UDLD单向链路检测',
              text3: '支持以太网OAM ',
              text4: '支持ISSU业务不中断系统升级'
            }
          },
          {
            title: '管理维护',
            textObj: {
              text1: '支持Console、Telnet、SSH 2.0',
              text2: '支持ZTP零接触配置开通（Zero Touch Provisioning）',
              text3: '支持基于浏览器WEB方式管理',
              text4: '支持SNMP v1/v2/v3',
              text5: '支持TFTP方式的文件上传、下载管理',
              text6: '支持RMON事件历史记录'
            }
          }
        ]
      }
    ]
  },
  {
    name: 'F5100-10',
    module: '通讯',
    sortNum: 8,
    devieceData: {
      img: require('@/assets/hardware/communication8.png'),
      zhName: 'NGFW下一代防火墙产品',
      enName: 'TURBO F5100-10',
      content:
        'TURBO F5100-10是北京捍御者信息科技有限公司为SMB企业、分支网点等场景设计和推出的一款经济型防火墙产品。'
    },
    tabsPane: [
      {
        label: '产品介绍',
        className: 'intro-class',
        dataList: [
          {
            textObj: {
              text1:
                'TURBO F5100-10是北京捍御者信息科技有限公司为SMB企业、分支网点等场景设计和推出的一款经济型防火墙产品。F5100-10产品基于先进的MIPS嵌入式多核构架，搭载自主研发的BDROS平台系统平台，通过单路径并行处理的应用识别和安全检测引擎，实现对用户、应用和内容的深入分析，可为用户提供高性能、直观、有效的应用层一体化安全防护体系。',
              text2:
                'F5100-10产品支持4层嵌套的带宽管理技术，结合智能路由等全面的网络层支撑以及双机热备高可靠性保障，可灵活的部署在透明、NAT、VPN、多出口、双链路等网络环境中，帮助用户方便安全的开展业务的同时简化网络安全架构，是下一代网络安全防护的最佳选择。'
            }
          }
        ]
      },
      {
        label: '产品特性',
        className: 'property-class',
        dataList: [
          {
            title: '多业务高性能',
            textObj: {
              text1:
                '北京捍御者F5100-10下一代防火墙采用先进的MIPS多核架构,结合捍御者自主知识产权的安全操作系统,采用攻击特征库、病毒库树形存储、流扫描处理、零拷贝并行流处理等高效的防攻击技术,整个解析过程一次拆包,确保开启多重防护功能后依然保证高速度、低时延的安全防护。'
            }
          },
          {
            title: '精细的上网行为管理',
            textObj: {
              text1:
                'F5100-10将用户和应用作为安全防护的核心维度,采用先进的用户识别和应用识别技术,实现对用户和应用的精细可视化展现及有效管控。',
              text2:
                '系统支持IP/MAC绑定,同时可以基于Radius、LDAP认证,Portal认证、微信认证、短信网关等多种身份识别。支持上千种网络应用软件的识别和精确控制,包括主流应用、高风险应用和移动终端热点应用,通过对应用行为和内容的深入分析进行精细化和准确的管控,使网络管理更贴近用户预期。'
            }
          },
          {
            title: '全面的入侵防御',
            textObj: {
              text1:
                '北京捍御者经过多年在网络安全领域沉淀和积累,打造了一支资深的攻击特征库团队和安全服务团队,随时关注业界最新发现的安全漏洞和接收全球用户反馈的攻击特征,并在第一时间做出响应和提供更新,实时完善攻击特征库,提供最及时、最全面的入侵防御。'
            }
          },
          {
            title: '专业的病毒查杀',
            textObj: {
              text1:
                'F5100-10拥有海量病毒特征库,配合先进的防病毒引擎,能够精准识别并清除流行木马和顽固病毒。查杀范围包括病毒、木马、蠕虫、后门、间谍软件、恶意程序以及HTTP、FTP、SMTP、POP3、IMAP等主流应用协议,超过200万病毒特征全库模杀,特征库实时在线更新。针对纯扫描优化的产品架构,无系统监控,不占系统资源,是保护内网资源不受互联网病毒侵扰的最佳选择。'
            }
          },
          {
            title: '遏制带宽滥用',
            textObj: {
              text1:
                'F5100-10能够全面识别互联网常见应用,包括经常造成带宽滥用、工作效率降低的P2P下载、IM即时通讯、在线视频、炒股、游戏等。将捍御者下一代防火墙部署于网络的出口,可以有效地遏制各种应用抢夺宝贵的带宽和IT资源,从而确保网络资源的合理配置和关键业务的服务质量,显著提高网络的整体性能。'
            }
          },
          {
            title: '独立的VPN模块',
            textObj: {
              text1:
                'F5100-10内置专用的硬件VPN模块,支持GRE、IPSec、SSL VPN等多种VPN业务模式。支持多种平台移动终端VPN接入。 支持对VPN隧道内的数据流进行管理,规范VPN隧道内上网行为并消除管理盲区。通过配置我司的集中管理软件,可对零散的VPN分支做集中式管理,可实现统一配置下发、告警集中处理、统一日志上报,有效减少管理员工作量。'
            }
          },
          {
            title: '组网方式灵活',
            textObj: {
              text1:
                'F5100-10支持MCE、IPSEC、802.1Q、GRE、VPN track、反向路由注入等网络特性,并支持路由模式、透明模式、旁路模式和混合模式,可在任意复杂的网络环境中灵活组网。'
            }
          },
          {
            title: '配置维护简洁',
            textObj: {
              text1:
                'F5100-10的安全策略集成用户识别、应用控制,URL过滤、入侵检测、防病毒、VPN等,管理者可以根据不同的管控需求,为不同的用户定制不同的管理策略,六大功能只需一条策略,灵活方便,维护简单,条理清晰,效果良好。在大规模部署时,可通过捍御者集中管理系统对分布部署的下一代防火墙进行统一的配置策略下发、攻击事件监控和攻击事件分析。'
            }
          },
          {
            title: '高度可靠性',
            textObj: {
              text1:
                'F5100-10支持双机热备和软硬件bypass功能,不会成为网络瓶颈和故障点,确保网络高可靠性。当设备CPU、内存等参数高于一定阈值时,自动bypass设备,让整个设备变为纯透明转发,保证业务不中断'
            }
          }
        ]
      },
      {
        label: '产品参数',
        className: 'params-class',
        dataList: [
          {
            title: '网络功能',
            textObj: {
              text1: '支持透明、路由、混合三种工作模式,支持链路捆绑',
              text2: '支持源NAT、目的NAT、端口NAT、静态NAT',
              text3: '支持Vlan、VRF',
              text4: '支持Wlan、3G无线接入',
              text5: '支持DHCP server和DHCP Relay、DNS sever和静态DNS',
              text6: '支持静态路由、策略路由、ISP路由、源接口路由',
              text7: '支持基于应用的路由',
              text8: '支持动态路由OSPF、RIP、OSPFv3',
              text9: '支持路由探测功能',
              text10: '支持IPv6静态路由、路由通告和隧道转换'
            }
          },
          {
            title: '防火墙',
            textObj: {
              text1:
                '支持基于用户、应用、源目的接口、源目的IP、服务和时间的八元组策略',
              text2: '支持地址、服务、时间表、应用、用户资源化',
              text3: '支持基于策略的长连接',
              text4: '支持策略命中次数统计和清零',
              text5: '支持策略优先级调整',
              text6: '支持IPv6安全策略',
              text7: '支持会话统计、监控、临时阻断和全局会话限制'
            }
          },
          {
            title: '安全防护',
            textObj: {
              text1: '支持IP-MAC绑定、ARP欺骗防护和ARP Flood攻击防护',
              text2:
                '支持IPv4异常包攻击防护,包括：Ping of Death、Land-Base、Tear Drop、TCP Flag Winnuke、Smurf、Jolt2等',
              text3: '支持端口扫描防护和IP扫描防护',
              text4:
                '支持基于接口和IP的SYNFlood、UDPFlood、ICMPFlood、DNSFlood攻击防护',
              text5: '支持手动、自动攻击防护的黑名单记录',
              text6:
                '支持IPv6异常包攻击防护,包括：Winnuke、Land-Base、TCP Flag、Fraggle、IP Spoof'
            }
          },
          {
            title: 'VPN',
            textObj: {
              text1: '支持各种标准的IPSecVPN协议及部署方式',
              text2: '支持基于策略和基于路由的IPsecVPN',
              text3: '支持CA中心和X.509格式证书',
              text4: '支持VPN Track',
              text5: '支持SSLVPN隧道模式',
              text6: '支持GREVPN支持VPN隧道下的安全防护和带宽管理'
            }
          },
          {
            title: '病毒防护',
            textObj: {
              text1: '支持基于HTTPFTPSMTPPOP3IMAP协议的病毒过滤',
              text2: '支持禁止特定文件类型通过',
              text3: '支持最大20层的压缩文件查毒',
              text4: '支持病毒库手动和自动更新',
              text5: '支持恶意URL过滤'
            }
          },
          {
            title: '入侵检测',
            textObj: {
              text1: '支持协议自动识别',
              text2: '支持超过3000种预定义的攻击特征',
              text3: '支持特征库的自动和手动更新',
              text4: '支持对蠕虫、木马后门、网络钓鱼等的安全防护'
            }
          },
          {
            title: '上网行为管理',
            textObj: {
              text1: '支持对IM软件登陆和收发消息的控制',
              text2: '支持对P2P、流媒体等影响工作效率的软件控制',
              text3: '支持网络游戏控制',
              text4: '支持股票行情和交易的行为控制',
              text5: '支持电子邮件收发、附件大小过滤',
              text6: '支持URL分类过滤'
            }
          },
          {
            title: '用户管理',
            textObj: {
              text1: '支持防火墙本地用户认证',
              text2: '支持标准的Radius、LDAP等第三方用户认证',
              text3: '支持微信用户认证和短信网关',
              text4: '支持Portal用户认证',
              text5: '支持用户在线监控和超时管理',
              text6: '支持集中用户策略管理'
            }
          },
          {
            title: '流量管理',
            textObj: {
              text1: '支持基于通道、接口、用户、应用的多级嵌套的带宽管理',
              text2: '支持带宽优先级管理',
              text3: '支持最大带宽限制、最小带宽保证和弹性带宽',
              text4: '支持每IP限速',
              text5: '支持排除策略'
            }
          },
          {
            title: '可视化',
            textObj: {
              text1: '支持实时的CPU、内存、接口流量等设备健康统计',
              text2: '支持饼图、柱图、趋势图等多种方式展现用户和应用的流量统计',
              text3: '支持用户和应用排名的实时展现',
              text4: '支持系统事件和安全风险的实时告警'
            }
          },
          {
            title: '系统管理',
            textObj: {
              text1: '支持系统管理员和只读管理员',
              text2: '支持管理员唯一性检查、超时管理',
              text3: '支持系统时间手动设定和NTP同步',
              text4: '支持双系统配置文件备份',
              text5: '支持系统文件手动升级',
              text6: '支持SNMPv1/v2/v3',
              text7: '支持主备、主主两种模式的双机热备',
              text8: '支持接口状态同步和接口状态探测',
              text9: '支持PING、TRACERT、TCPSYN方式进行系统诊断',
              text10: '支持系统信息定期收集'
            }
          }
        ]
      }
    ]
  },
  {
    name: 'F5100-21',
    module: '通讯',
    sortNum: 9,
    devieceData: {
      img: require('@/assets/hardware/communication9.png'),
      zhName: 'NGFW下一代防火墙产品',
      enName: 'TURBO F5100-21',
      content:
        'TURBO F5100-21是北京捍御者信息科技有限公司为SMB企业、分支网点等场景设计和推出的一款经济型防火墙产品。'
    },
    tabsPane: [
      {
        label: '产品介绍',
        className: 'intro-class',
        dataList: [
          {
            textObj: {
              text1:
                'TURBO F5100-21是北京捍御者信息科技有限公司为SMB企业、分支网点等场景设计和推出的一款经济型防火墙产品。F5100-21产品基于先进的MIPS嵌入式多核构架，搭载自主研发的BDROS平台系统平台，通过单路径并行处理的应用识别和安全检测引擎，实现对用户、应用和内容的深入分析，可为用户提供高性能、直观、有效的应用层一体化安全防护体系。',
              text2:
                'F5100-21产品支持4层嵌套的带宽管理技术，结合智能路由等全面的网络层支撑以及双机热备高可靠性保障，可灵活的部署在透明、NAT、VPN、多出口、双链路等网络环境中，帮助用户方便安全的开展业务的同时简化网络安全架构，是下一代网络安全防护的最佳选择。'
            }
          }
        ]
      },
      {
        label: '产品特性',
        className: 'property-class',
        dataList: [
          {
            title: '多业务高性能',
            textObj: {
              text1:
                '北京捍御者F5100-21下一代防火墙采用先进的MIPS多核架构,结合捍御者自主知识产权的安全操作系统,采用攻击特征库、病毒库树形存储、流扫描处理、零拷贝并行流处理等高效的防攻击技术,整个解析过程一次拆包,确保开启多重防护功能后依然保证高速度、低时延的安全防护。'
            }
          },
          {
            title: '精细的上网行为管理',
            textObj: {
              text1:
                'F5100-21将用户和应用作为安全防护的核心维度,采用先进的用户识别和应用识别技术,实现对用户和应用的精细可视化展现及有效管控。',
              text2:
                '系统支持IP/MAC绑定,同时可以基于Radius、LDAP认证,Portal认证、微信认证、短信网关等多种身份识别。支持上千种网络应用软件的识别和精确控制,包括主流应用、高风险应用和移动终端热点应用,通过对应用行为和内容的深入分析进行精细化和准确的管控,使网络管理更贴近用户预期。'
            }
          },
          {
            title: '全面的入侵防御',
            textObj: {
              text1:
                '北京捍御者经过多年在网络安全领域沉淀和积累,打造了一支资深的攻击特征库团队和安全服务团队,随时关注业界最新发现的安全漏洞和接收全球用户反馈的攻击特征,并在第一时间做出响应和提供更新,实时完善攻击特征库,提供最及时、最全面的入侵防御。'
            }
          },
          {
            title: '专业的病毒查杀',
            textObj: {
              text1:
                'F5100-21拥有海量病毒特征库,配合先进的防病毒引擎,能够精准识别并清除流行木马和顽固病毒。查杀范围包括病毒、木马、蠕虫、后门、间谍软件、恶意程序以及HTTP、FTP、SMTP、POP3、IMAP等主流应用协议,超过200万病毒特征全库模杀,特征库实时在线更新。针对纯扫描优化的产品架构,无系统监控,不占系统资源,是保护内网资源不受互联网病毒侵扰的最佳选择。'
            }
          },
          {
            title: '遏制带宽滥用',
            textObj: {
              text1:
                'F5100-21能够全面识别互联网常见应用,包括经常造成带宽滥用、工作效率降低的P2P下载、IM即时通讯、在线视频、炒股、游戏等。将捍御者下一代防火墙部署于网络的出口,可以有效地遏制各种应用抢夺宝贵的带宽和IT资源,从而确保网络资源的合理配置和关键业务的服务质量,显著提高网络的整体性能。'
            }
          },
          {
            title: '独立的VPN模块',
            textObj: {
              text1:
                'F5100-21内置专用的硬件VPN模块,支持GRE、IPSec、SSL VPN等多种VPN业务模式。支持多种平台移动终端VPN接入。 支持对VPN隧道内的数据流进行管理,规范VPN隧道内上网行为并消除管理盲区。通过配置我司的集中管理软件,可对零散的VPN分支做集中式管理,可实现统一配置下发、告警集中处理、统一日志上报,有效减少管理员工作量。'
            }
          },
          {
            title: '组网方式灵活',
            textObj: {
              text1:
                'F5100-21支持MCE、IPSEC、802.1Q、GRE、VPN track、反向路由注入等网络特性,并支持路由模式、透明模式、旁路模式和混合模式,可在任意复杂的网络环境中灵活组网。'
            }
          },
          {
            title: '配置维护简洁',
            textObj: {
              text1:
                'F5100-21的安全策略集成用户识别、应用控制,URL过滤、入侵检测、防病毒、VPN等,管理者可以根据不同的管控需求,为不同的用户定制不同的管理策略,六大功能只需一条策略,灵活方便,维护简单,条理清晰,效果良好。在大规模部署时,可通过捍御者集中管理系统对分布部署的下一代防火墙进行统一的配置策略下发、攻击事件监控和攻击事件分析。'
            }
          },
          {
            title: '高度可靠性',
            textObj: {
              text1:
                'F5100-21支持双机热备和软硬件bypass功能,不会成为网络瓶颈和故障点,确保网络高可靠性。当设备CPU、内存等参数高于一定阈值时,自动bypass设备,让整个设备变为纯透明转发,保证业务不中断'
            }
          }
        ]
      },
      {
        label: '产品参数',
        className: 'params-class',
        dataList: [
          {
            title: '网络功能',
            textObj: {
              text1: '支持透明、路由、混合三种工作模式,支持链路捆绑',
              text2: '支持源NAT、目的NAT、端口NAT、静态NAT',
              text3: '支持Vlan、VRF',
              text4: '支持Wlan、3G无线接入',
              text5: '支持DHCP server和DHCP Relay、DNS sever和静态DNS',
              text6: '支持静态路由、策略路由、ISP路由、源接口路由',
              text7: '支持基于应用的路由',
              text8: '支持动态路由OSPF、RIP、OSPFv3',
              text9: '支持路由探测功能',
              text10: '支持IPv6静态路由、路由通告和隧道转换'
            }
          },
          {
            title: '防火墙',
            textObj: {
              text1:
                '支持基于用户、应用、源目的接口、源目的IP、服务和时间的八元组策略',
              text2: '支持地址、服务、时间表、应用、用户资源化',
              text3: '支持基于策略的长连接',
              text4: '支持策略命中次数统计和清零',
              text5: '支持策略优先级调整',
              text6: '支持IPv6安全策略',
              text7: '支持会话统计、监控、临时阻断和全局会话限制'
            }
          },
          {
            title: '安全防护',
            textObj: {
              text1: '支持IP-MAC绑定、ARP欺骗防护和ARP Flood攻击防护',
              text2:
                '支持IPv4异常包攻击防护,包括：Ping of Death、Land-Base、Tear Drop、TCP Flag Winnuke、Smurf、Jolt2等',
              text3: '支持端口扫描防护和IP扫描防护',
              text4:
                '支持基于接口和IP的SYNFlood、UDPFlood、ICMPFlood、DNSFlood攻击防护',
              text5: '支持手动、自动攻击防护的黑名单记录',
              text6:
                '支持IPv6异常包攻击防护,包括：Winnuke、Land-Base、TCP Flag、Fraggle、IP Spoof'
            }
          },
          {
            title: 'VPN',
            textObj: {
              text1: '支持各种标准的IPSecVPN协议及部署方式',
              text2: '支持基于策略和基于路由的IPsecVPN',
              text3: '支持CA中心和X.509格式证书',
              text4: '支持VPN Track',
              text5: '支持SSLVPN隧道模式',
              text6: '支持GREVPN支持VPN隧道下的安全防护和带宽管理'
            }
          },
          {
            title: '病毒防护',
            textObj: {
              text1: '支持基于HTTPFTPSMTPPOP3IMAP协议的病毒过滤',
              text2: '支持禁止特定文件类型通过',
              text3: '支持最大20层的压缩文件查毒',
              text4: '支持病毒库手动和自动更新',
              text5: '支持恶意URL过滤'
            }
          },
          {
            title: '入侵检测',
            textObj: {
              text1: '支持协议自动识别',
              text2: '支持超过3000种预定义的攻击特征',
              text3: '支持特征库的自动和手动更新',
              text4: '支持对蠕虫、木马后门、网络钓鱼等的安全防护'
            }
          },
          {
            title: '上网行为管理',
            textObj: {
              text1: '支持对IM软件登陆和收发消息的控制',
              text2: '支持对P2P、流媒体等影响工作效率的软件控制',
              text3: '支持网络游戏控制',
              text4: '支持股票行情和交易的行为控制',
              text5: '支持电子邮件收发、附件大小过滤',
              text6: '支持URL分类过滤'
            }
          },
          {
            title: '用户管理',
            textObj: {
              text1: '支持防火墙本地用户认证',
              text2: '支持标准的Radius、LDAP等第三方用户认证',
              text3: '支持微信用户认证和短信网关',
              text4: '支持Portal用户认证',
              text5: '支持用户在线监控和超时管理',
              text6: '支持集中用户策略管理'
            }
          },
          {
            title: '流量管理',
            textObj: {
              text1: '支持基于通道、接口、用户、应用的多级嵌套的带宽管理',
              text2: '支持带宽优先级管理',
              text3: '支持最大带宽限制、最小带宽保证和弹性带宽',
              text4: '支持每IP限速',
              text5: '支持排除策略'
            }
          },
          {
            title: '可视化',
            textObj: {
              text1: '支持实时的CPU、内存、接口流量等设备健康统计',
              text2: '支持饼图、柱图、趋势图等多种方式展现用户和应用的流量统计',
              text3: '支持用户和应用排名的实时展现',
              text4: '支持系统事件和安全风险的实时告警'
            }
          },
          {
            title: '系统管理',
            textObj: {
              text1: '支持系统管理员和只读管理员',
              text2: '支持管理员唯一性检查、超时管理',
              text3: '支持系统时间手动设定和NTP同步',
              text4: '支持双系统配置文件备份',
              text5: '支持系统文件手动升级',
              text6: '支持SNMPv1/v2/v3',
              text7: '支持主备、主主两种模式的双机热备',
              text8: '支持接口状态同步和接口状态探测',
              text9: '支持PING、TRACERT、TCPSYN方式进行系统诊断',
              text10: '支持系统信息定期收集'
            }
          }
        ]
      }
    ]
  },
  {
    name: 'F5500-10',
    module: '通讯',
    sortNum: 10,
    devieceData: {
      img: require('@/assets/hardware/communication10.png'),
      zhName: 'ICG上网行为管理产品',
      enName: 'TURBO F5500-10',
      content:
        'TURBO F5500-10是北京捍御者信息科技有限公司为SMB企业、分支网点等场景设计推出的一款经济型防火墙产品。'
    },
    tabsPane: [
      {
        label: '产品介绍',
        className: 'intro-class',
        dataList: [
          {
            textObj: {
              text1:
                'TURBO F5500-10是北京捍御者信息科技有限公司为SMB企业、分支网点等场景设计推出的一款经济型防火墙产品。F5500-10产品基于先进的MIPS嵌入式多核构架,搭载自主研发的BDROS 平台系统，通过单路径并行处理的应用识别和安全检测引擎，实现对用户、应用和内容的深入分析，可为用户提供高性能、直观、有效的应用层一体化安全防护体系。',
              text2:
                'F5500-10产品支持4层嵌套的带宽管理技术，结合智能路由等全面的网络层支撑以及双机热备高可靠性保障，可灵活的部署在透明、NAT、VPN、多出口、双链路等网络环境中，帮助用户方便安全的开展业务的同时简化网络安全架构，是下一代网络安全防护的最佳选择。'
            }
          }
        ]
      },
      {
        label: '产品特性',
        className: 'property-class',
        dataList: [
          {
            title: '灵活的场景适应能力',
            text1:
              '北京捍御者F5500-10上网行为管理可适应用户各种复杂网络场景。在满足路由、透明、旁路、混合等部署模式同时，配合IPv4/IPv6双协议栈，结合静态路由、动态路由、策略路由、ISP路由、链路负载均衡和服务器负载均衡等，可在802.1Q、GRE 、RIP、OSPF、VRF、多出口等各种复杂的网络环境中灵活组网。'
          },
          {
            title: '全方位的身份认证',
            text1: 'F5500-10系列以用户需求为导向，实现了丰富的身份认证手段：',
            text2: '本地认证：Web 认证、用户名/密码认证、IP/MAC/IP-MAC 绑定；',
            text3: '单点登录：AD域、AAA系统、第三方系统等；',
            text4: '第三方认证：RADIUS、LDAP、IMC、AAS、SAM等',
            text5:
              'APP认证：不需要借助数据中心软件，无需APP修改，避免协调沟通成本；',
            text6:
              '微信认证：连接商家WIFI，自动弹出“一键微信连WIFI”，可强制关注公众号；',
            text7: '混合认证：用户根据需求切换认证方式；'
          },
          {
            title: '精细的行为管理',
            text1:
              'F5500-10产品控制层面不再局限对网络应用的阻断，更能深入识别应用的内置动作。例如对QQ的控制力度不仅仅是登录动作，更可识别到收文件、发文件、语音、视频等动作，对微信也可识别控制多达11种行为动作.',
            text2:
              'F5500-10可快速识别“一拖N”的网络私接行为，精准定位“N”即私接用户数量，并进行有效精细的针对终端类型进行管控，及时发现非法热点，预防个人用户私接路由，通过对应用的更精细化管理让网络更有序。'
          },
          {
            title: 'SSL内容解密',
            text1:
              '北京捍御者F5500提供了HTTPS审计功能，采用特有的加密流量识别技术，能够对主流的加密网站、加密网站搜索记录、加密邮件等进行行为识别。管理员可以采用自定义的方式，定向审计用户和加密网站，保障网络行为清晰的事后审计，有效的防止企业机密外泄。'
          },
          {
            title: '动态带宽管理',
            text1:
              'F5500-10采用智能流控、智能阻断、智能路由等技术，将网络出口带宽划分为逻辑通道，并支持在通道中再划分子通道，完美进行带宽限制和带宽保障。同时支持将类型复杂的网络流量分布到不同的网络出口转发，是企业提升带宽利用率、保护带宽投资的最佳利器。'
          },
          {
            title: '营销增值服务',
            text1:
              'F5500-10创新的将APP缓存在设备本地，当用户下载时直接推送，几十M的文件只要几秒钟，极大的提升了带宽利用率的同时大大加速和提升了用户体验；并且支持iOS和安卓APP的缓存，业界技术领先；在低成本的投入下同时为客户的终端营销推广开辟了新的方向。配合APP身份认证，可强制推广商户的APP，提高商户的APP安装率，拥有更多的可转化潜在用户。',
            text2:
              'F5500-10支持对用户进行广告推送的功能。广告推送作为电子商务营销阶段的应用，具有灵活性、互动性和目标受众准确的特点，极大的降低了广告投放的费用，广告推送为大量的广告主服务，把互联网广告以合适的方式推送给合适的消费者，广告投放的精准性高，转化率高。'
          },
          {
            title: '清晰的内容审计',
            text1:
              '北京捍御者F5500-10支持详细、清晰、易用的日志特性，可以全面记录审计用户上网行为、使用流量、访问网站、所用终端系统及设备类型平台等信息；日志支持定制化过滤器，可根据IP地址、认证用户、访问应用、访问URL、发帖内容等要素进行搜索，让事后审计省时省力；可支持对HTTPS、邮箱类解密策略的配置。同时，F5500系列产品提供丰富美观的报表，以柱状图、饼状图、百分比等形式最直观地体现网络运行状况，让网络管理规划有据可循、有的放矢。'
          },
          {
            title: '配置维护简洁',
            text1:
              'F5500-10除支持本地管理之外，可配合网管平台进行快速上线、集中配置下发和日志收集，极大的释放了运维压力；支持拓展云管理，实现大数据分析；双因子UKey认证提供双重认证方式，极大的提高了网络的安全性；端口镜像、端口漂移、链路服务质量统计、界面抓包等运维工具，助力运维。',
            text2:
              'F5500-10具备多面、简单化和安全的管理特性，简化网络运维，降低安全成本，适用于各种网络场景。'
          },
          {
            title: '高度可靠性',
            text1:
              '北京捍御者的F5500-10上网行为管理支持双机热备和软硬件bypass功能，不会成为网络瓶颈和故障点，确保网络高可靠性。当设备CPU、内存等参数高于一定阈值时，自动bypass设备，让整个设备变为纯透明转发，保证业务不中断。'
          }
        ]
      },
      {
        label: '产品参数',
        className: 'params-class',
        dataList: [
          {
            title: '网络功能',
            textObj: {
              text1: '支持透明网桥、路由、旁路、混合部署',
              text2: '支持VLAN子接口、桥接口、聚合接口',
              text3: '支持DHCP中继、DHCP服务器、DHCP客户端',
              text4:
                '支持源地址、目的地址NAT，支持一对一、一对多、多对多地址转换，支持NAT44',
              text5:
                '支持静态路由、动态路由(RIP、OSPF、BGP)、策略路由、应用路由、ISP路由，支持DDNS',
              text6:
                '支持DES、3DES、AES多种加密算法,支持MD5及SHA-1验证算法，支持数字证书认证',
              text7: '支持IKE主模式及野蛮模式，支持NAT穿越和DPD检测'
            }
          },
          {
            title: '安全特性',
            textObj: {
              text1:
                '支持SYNflood、UDPflood、ICMPflood、DNSflood攻击防护和异常包防护',
              text2:
                '支持与IP地址黑名单联动，禁用地址访问，支持IP-MAC绑定和检查',
              text3: '支持X.509 V3数字证书，支持DER/PEM/PKCS12多种证书编码',
              text4: '支持内置CA，为其他设备或移动用户签发证书',
              text5: '支持本地CA根证书、根私钥的更新',
              text6: '支持在线CRL列表'
            }
          },
          {
            title: '防火墙',
            textObj: {
              text1:
                '支持针对网络社区、P2P、文件传输、电子商务、IM、炒股、网络多媒体、网络游戏、远程控制、HTTPS加密网站、HTTPS加密邮件、HTTPS加密搜索行为等进行审计和控制',
              text2:
                '支持基于用户、IP、应用、行为动作、内容、终端系统及设备类型的审计',
              text3: '支持网站、邮件、论坛发贴、搜索关键字过滤和审计',
              text4: '支持对内置URL分类、恶意URL和自定义URL进行过滤和审计'
            }
          },
          {
            title: '流量管理',
            textObj: {
              text1: '支持虚拟线路和分级带宽管理，可支持4级通道嵌套',
              text2: '支持基于用户、应用、服务、IP、时间的带宽限制',
              text3: '支持每IP限速、带宽保障和多优先级管理'
            }
          },
          {
            title: '会话管理',
            textObj: {
              text1: '支持地址对象的限制；支持源IP的会话数、每秒新建的限制',
              text2:
                '支持当前IP地址的连接数统计，可监控当前连接的状态、协议、应用、会话时长等信息'
            }
          },
          {
            title: '特征库',
            textObj: {
              text1: '支持近千种主流应用，应用特征库及URL库可在线或手动升级'
            }
          },
          {
            title: '日志报表',
            textObj: {
              text1: '基于用户、应用的流量趋势统计、分布统计和TOP排名',
              text2: '基于设备CPU、内存、转发流量、会话数趋势统计',
              text3:
                '基于用户的网络行为、账号、流量统计分析，支持用户行为轨迹跟踪',
              text4:
                '支持IM聊天软件、网络社区、搜索引擎、邮件、文件传输、娱乐股票、网站访问、网络攻击、恶意URL、系统操作等日志记录审计,支持https的解密和审计'
            }
          },
          {
            title: '用户管理',
            textObj: {
              text1:
                '优化的树形结构显示用户组织结构，支持批量导入导出用户或用户组，支持对用户和用户组新建、删除。查找和移动的操作，用户属性支持本地用户、Radius用户、LDAP用户、静态绑定地址用户，扩展了丰富的用户属性，支持自定义用户属性',
              text2:
                '在线用户状态显示（用户名、所属组、登录地址、认证方式、登录时间、冻结时间、状态、在线时长），支持用户注销、冻结、解冻操作',
              text3:
                '支持Radius、LDAP认证服务器和服务器组、微信服务器、短信网关，支持Radius协议分析，认证服务器组支持主备和集群，支持Portal chap认证，支持微信认证强制关注公众号'
            }
          },
          {
            title: '系统管理',
            textObj: {
              text1: 'Web、CLI、远程集中管理，云平台管理',
              text2: '配置文件支持导入导出，双备份配置',
              text3: '支持双机HA、IPSec VPN HA，硬件Bypass和软件Bypass',
              text4: '支持系统软件本地升级和远程升级',
              text5: '支持SNMP代理，支持版本：V1、V2、V3',
              text6: '支持发送Syslog服务器，支持日志级别过滤'
            }
          }
        ]
      }
    ]
  },
  {
    name: 'F5500-21',
    module: '通讯',
    sortNum: 11,
    devieceData: {
      img: require('@/assets/hardware/communication11.png'),
      zhName: 'ICG上网行为管理产品',
      enName: 'TURBO F5500-21',
      content:
        'TURBO F5500-21是北京捍御者信息科技有限公司为SMB企业、分支网点等网络安全场景设计和推出的一款经济型上网行为管理产品。'
    },
    tabsPane: [
      {
        label: '产品介绍',
        className: 'intro-class',
        dataList: [
          {
            textObj: {
              text1:
                'TURBO F5500-21是北京捍御者信息科技有限公司为SMB企业、分支网点等网络安全场景设计和推出的一款经济型上网行为管理产品。F5500-21产品基于先进的MIPS嵌入式多核构架，搭载自主研发的BDROS平台系统平台，通过单路径并行处理的应用识别和安全检测引擎，融合用户上网行为管理、内容审计、上层业务优化等功能，可为用户提供一个综合和完整的解决方案。',
              text2:
                'F5500-21可以工作在路由、桥接、混杂等多种方式部署在网络的关键节点，对应用数据进行L2-L7层的全面检测和分析。深度检测、管控和审计千余种IM即时聊天、P2P下载软件、VOD流媒体、购物、炒股、网游等常见应用，并利用智能流控、一键阻断、智能路由等技术提供有效管理。'
            }
          }
        ]
      },
      {
        label: '产品特性',
        className: 'property-class',
        dataList: [
          {
            title: '灵活的场景适应能力',
            textObj: {
              text1:
                '北京捍御者F5500-21上网行为管理可适应用户各种复杂网络场景。在满足路由、透明、旁路、混合等部署模式同时，配合IPv4/IPv6双协议栈，结合静态路由、动态路由、策略路由、ISP路由、链路负载均衡和服务器负载均衡等，可在802.1Q、GRE 、RIP、OSPF、VRF、多出口等各种复杂的网络环境中灵活组网。'
            }
          },
          {
            title: '精细的上网行为管理',
            textObj: {
              text1:
                'F5100-21将用户和应用作为安全防护的核心维度，采用先进的用户识别和应用识别技术，实现对用户和应用的精细可视化展现及有效管控。',
              text2:
                '系统支持IP/MAC绑定，同时可以基于Radius、LDAP认证，Portal认证、微信认证、短信网关等多种身份识别。支持上千种网络应用软件的识别和精确控制，包括主流应用、高风险应用和移动终端热点应用，通过对应用行为和内容的深入分析进行精细化和准确的管控，使网络管理更贴近用户预期。'
            }
          },
          {
            title: '全方位的身份认证',
            textObj: {
              text1: 'F5500-21系列以用户需求为导向，实现了丰富的身份认证手段：',
              text2:
                '本地认证：Web 认证、用户名/密码认证、IP/MAC/IP-MAC 绑定；',
              text3: '单点登录：AD域、AAA系统、第三方系统等；',
              text4: '第三方认证：RADIUS、LDAP、IMC、AAS、SAM等',
              text5:
                'APP认证：不需要借助数据中心软件，无需APP修改，避免协调沟通成本；',
              text6:
                '微信认证：连接商家WIFI，自动弹出“一键微信连WIFI”，可强制关注公众号；',
              text7: '混合认证：用户根据需求切换认证方式；'
            }
          },
          {
            title: '精细的行为管理',
            textObj: {
              text1:
                'F5500-21产品控制层面不再局限对网络应用的阻断，更能深入识别应用的内置动作。例如对QQ的控制力度不仅仅是登录动作，更可识别到收文件、发文件、语音、视频等动作，对微信也可识别控制多达11种行为动作.',
              text2:
                'F5500-21可快速识别“一拖N”的网络私接行为，精准定位“N”即私接用户数量，并进行有效精细的针对终端类型进行管控，及时发现非法热点，预防个人用户私接路由，通过对应用的更精细化管理让网络更有序。'
            }
          },
          {
            title: 'SSL内容解密',
            textObj: {
              text1:
                '北京捍御者F5500提供了HTTPS审计功能，采用特有的加密流量识别技术，能够对主流的加密网站、加密网站搜索记录、加密邮件等进行行为识别。管理员可以采用自定义的方式，定向审计用户和加密网站，保障网络行为清晰的事后审计，有效的防止企业机密外泄。'
            }
          },
          {
            title: '动态带宽管理',
            textObj: {
              text1:
                'F5500-21采用智能流控、智能阻断、智能路由等技术，将网络出口带宽划分为逻辑通道，并支持在通道中再划分子通道，完美进行带宽限制和带宽保障。同时支持将类型复杂的网络流量分布到不同的网络出口转发，是企业提升带宽利用率、保护带宽投资的最佳利器。'
            }
          },
          {
            title: '营销增值服务',
            textObj: {
              text1:
                'F5500-21创新的将APP缓存在设备本地，当用户下载时直接推送，几十M的文件只要几秒钟，极大的提升了带宽利用率的同时大大加速和提升了用户体验；并且支持iOS和安卓APP的缓存，业界技术领先；在低成本的投入下同时为客户的终端营销推广开辟了新的方向。配合APP身份认证，可强制推广商户的APP，提高商户的APP安装率，拥有更多的可转化潜在用户。',
              text2:
                'F5500-21支持对用户进行广告推送的功能。广告推送作为电子商务营销阶段的应用，具有灵活性、互动性和目标受众准确的特点，极大的降低了广告投放的费用，广告推送为大量的广告主服务，把互联网广告以合适的方式推送给合适的消费者，广告投放的精准性高，转化率高。'
            }
          },
          {
            title: '清晰的内容审计',
            textObj: {
              text1:
                '北京捍御者F5500-21支持详细、清晰、易用的日志特性，可以全面记录审计用户上网行为、使用流量、访问网站、所用终端系统及设备类型平台等信息；日志支持定制化过滤器，可根据IP地址、认证用户、访问应用、访问URL、发帖内容等要素进行搜索，让事后审计省时省力；可支持对HTTPS、邮箱类解密策略的配置。同时，F5500系列产品提供丰富美观的报表，以柱状图、饼状图、百分比等形式最直观地体现网络运行状况，让网络管理规划有据可循、有的放矢。'
            }
          },
          {
            title: '配置维护简洁',
            textObj: {
              text1:
                'F5500-21除支持本地管理之外，可配合网管平台进行快速上线、集中配置下发和日志收集，极大的释放了运维压力；支持拓展云管理，实现大数据分析；双因子UKey认证提供双重认证方式，极大的提高了网络的安全性；端口镜像、端口漂移、链路服务质量统计、界面抓包等运维工具，助力运维。',
              text2:
                'F5500-21具备多面、简单化和安全的管理特性，简化网络运维，降低安全成本，适用于各种网络场景。'
            }
          },
          {
            title: '高度可靠性',
            textObj: {
              text1:
                '捍御者的F5500-21上网行为管理支持双机热备和软硬件bypass功能，不会成为网络瓶颈和故障点，确保网络高可靠性。当设备CPU、内存等参数高于一定阈值时，自动bypass设备，让整个设备变为纯透明转发，保证业务不中断。'
            }
          }
        ]
      },
      {
        label: '产品参数',
        className: 'params-class',
        dataList: [
          {
            title: '网络功能',
            textObj: {
              text1: '支持透明网桥、路由、旁路、混合部署',
              text2: '支持VLAN子接口、桥接口、聚合接口',
              text3: '支持DHCP中继、DHCP服务器、DHCP客户端',
              text4:
                '支持源地址、目的地址NAT，支持一对一、一对多、多对多地址转换，支持NAT44',
              text5:
                '支持静态路由、动态路由(RIP、OSPF、BGP)、策略路由、应用路由、ISP路由，支持DDNS',
              text6:
                '支持DES、3DES、AES多种加密算法,支持MD5及SHA-1验证算法，支持数字证书认证',
              text7: '支持IKE主模式及野蛮模式，支持NAT穿越和DPD检测'
            }
          },
          {
            title: '安全特性',
            textObj: {
              text1:
                '支持SYNflood、UDPflood、ICMPflood、DNSflood攻击防护和异常包防护',
              text2:
                '支持与IP地址黑名单联动，禁用地址访问，支持IP-MAC绑定和检查',
              text3: '支持X.509 V3数字证书，支持DER/PEM/PKCS12多种证书编码',
              text4: '支持内置CA，为其他设备或移动用户签发证书',
              text5: '支持本地CA根证书、根私钥的更新',
              text6: '支持在线CRL列表'
            }
          },
          {
            title: '防火墙',
            textObj: {
              text1:
                '支持针对网络社区、P2P、文件传输、电子商务、IM、炒股、网络多媒体、网络游戏、远程控制、HTTPS加密网站、HTTPS加密邮件、HTTPS加密搜索行为等进行审计和控制',
              text2:
                '支持基于用户、IP、应用、行为动作、内容、终端系统及设备类型的审计',
              text3: '支持网站、邮件、论坛发贴、搜索关键字过滤和审计',
              text4: '支持对内置URL分类、恶意URL和自定义URL进行过滤和审计'
            }
          },
          {
            title: '流量管理',
            textObj: {
              text1: '支持虚拟线路和分级带宽管理，可支持4级通道嵌套',
              text2: '支持基于用户、应用、服务、IP、时间的带宽限制',
              text3: '支持每IP限速、带宽保障和多优先级管理'
            }
          },
          {
            title: '会话管理',
            textObj: {
              text1: '支持地址对象的限制；支持源IP的会话数、每秒新建的限制',
              text2:
                '支持当前IP地址的连接数统计，可监控当前连接的状态、协议、应用、会话时长等信息'
            }
          },
          {
            title: '特征库',
            textObj: {
              text1: '支持近千种主流应用，应用特征库及URL库可在线或手动升级'
            }
          },
          {
            title: '日志报表',
            textObj: {
              text1: '基于用户、应用的流量趋势统计、分布统计和TOP排名',
              text2: '基于设备CPU、内存、转发流量、会话数趋势统计',
              text3:
                '基于用户的网络行为、账号、流量统计分析，支持用户行为轨迹跟踪',
              text4:
                '支持IM聊天软件、网络社区、搜索引擎、邮件、文件传输、娱乐股票、网站访问、网络攻击、恶意URL、系统操作等日志记录审计,支持https的解密和审计'
            }
          },
          {
            title: '用户管理',
            textObj: {
              text1:
                '优化的树形结构显示用户组织结构，支持批量导入导出用户或用户组，支持对用户和用户组新建、删除。查找和移动的操作，用户属性支持本地用户、Radius用户、LDAP用户、静态绑定地址用户，扩展了丰富的用户属性，支持自定义用户属性',
              text2:
                '在线用户状态显示（用户名、所属组、登录地址、认证方式、登录时间、冻结时间、状态、在线时长），支持用户注销、冻结、解冻操作',
              text3:
                '支持Radius、LDAP认证服务器和服务器组、微信服务器、短信网关，支持Radius协议分析，认证服务器组支持主备和集群，支持Portal chap认证，支持微信认证强制关注公众号'
            }
          },
          {
            title: '系统管理',
            textObj: {
              text1: 'Web、CLI、远程集中管理，云平台管理',
              text2: '配置文件支持导入导出，双备份配置',
              text3: '支持双机HA、IPSec VPN HA，硬件Bypass和软件Bypass',
              text4: '支持系统软件本地升级和远程升级',
              text5: '支持SNMP代理，支持版本：V1、V2、V3',
              text6: '支持发送Syslog服务器，支持日志级别过滤'
            }
          }
        ]
      }
    ]
  },
  {
    name: 'GP3600',
    module: '通讯',
    sortNum: 12,
    devieceData: {
      img: require('@/assets/hardware/communication12.png'),
      zhName: '高密度机架式GPON OLT',
      enName: 'TURBO GP3600',
      content:
        'TURBO GP3600系列 OLT是遵循ITU-T G.984/G.988和中华人民共和国通讯行业标准《接入网技术要求——吉比特无源光网络（GPON）》为基础的OLT设备，全面支持CTC2.0标准，自动发现和兼容各厂商ONU，可组建高效的GPON解决方案。'
    },
    tabsPane: [
      {
        label: '产品介绍',
        className: 'intro-class',
        dataList: [
          {
            textObj: {
              text1:
                'TURBO GP3600系列 OLT是遵循ITU-T G.984/G.988和中华人民共和国通讯行业标准《接入网技术要求——吉比特无源光网络（GPON）》为基础的OLT设备，全面支持CTC2.0标准，自动发现和兼容各厂商ONU，可组建高效的GPON解决方案。',
              text2:
                'TURBO GP3600系列 OLT支持下行2.5Gbps/上行1.25Gbps的PON传输速率，高效的带宽利用率和以太网业务支持能力帮助运营商为他们的用户提供可靠的业务质量保证。高达1:128的分光比，支持不同类型ONU的混和组网，使得运营商的投资做到最小化。TURBO GP3600系列OLT采用业界先进的技术，在以太网业务上具有强大的功能和QoS保证，支持SLA和DBA。'
            }
          }
        ]
      },
      {
        label: '产品特性',
        className: 'property-class',
        dataList: [
          {
            title:
              'TURBO GP3600系列OLT是适合目前市场需求的光网络设备，该系列产品拥有以下优势：',
            textObj: {
              text1:
                'GPON: GP3600系列OLT遵循ITU-T G.984/G.988、中华人民共和国通讯行业标准《接入网技术要求——吉比特无源光网络（GPON）》以及中国电信GPON设备技术要求CTC2.0 ；',
              text2: '系统容量: GP3600系列OLT 支持8/16个GPON PON接口；',
              text3:
                '上连接口:标配4个千兆SFP接口+4个千兆combo接口，4个万兆SFP+接口；',
              text4: '设备尺寸: 1U高度，占用机房空间小；',
              text5: '绿色环保：低功耗，降低业务的运营成本；',
              text6:
                '干线光纤保护: GP3600系列OLT支持在光纤发生故障时链路自动保护切换；',
              text7:
                '上连接口:标配4个千兆SFP接口+4个千兆combo接口，4个万兆SFP+接口；',
              text8:
                '电源特性：支持双AC、双DC以及AC/DC混合双电源，电源采用模块化设计，可热插拔，EMC 3级标准。具备同类产品中更强大的环境适应能力。'
            }
          }
        ]
      },
      {
        label: '产品参数',
        className: 'params-class',
        dataList: [
          {
            title: '项目',
            textObj: {
              text1: 'GP3600-08B'
            },
            textObj2: {
              text1: 'GP3600-16B'
            }
          },
          {
            title: '系统容量',
            textObj: {
              text1: '最高1:128分光比',
              text2: '背板带宽205G'
            }
          },
          {
            title: '接口PON',
            textObj: {
              text1: '8个GPON接口'
            },
            textObj2: {
              text1: '16个GPON接口'
            }
          },
          {
            title: '接口上联口',
            textObj: {
              text1: '8个GE接口（4路千兆光口+4路千兆Combo口）'
            },
            textObj2: {
              text1: '16个GPON接口',
              text2: '4个万兆接口'
            }
          },
          {
            title: 'PON接口特性',
            textObj: {
              text1: '下行2.5Gbps/上行1.25Gbps传输速率',
              text2: '支持Class B+、Class C+ 、Class C++GPON模块',
              text3: '安全性: ONU认证机制'
            }
          },
          {
            title: '遵循的标准',
            textObj: {
              text1: 'ITU-T G.984/G.988',
              text2:
                '中华人民共和国通讯行业标准《接入网技术要求——吉比特无源光网络（GPON）》',
              text3: '中国电信GPON设备技术要求CTC2.0',
              text4: 'IEEE 802.1D, Spanning Tree',
              text5: 'IEEE 802.1Q, VLAN',
              text6: 'IEEE 802.1w, RSTP',
              text7: 'IEEE 802.3ad 物理链路静态/动态聚合（LACP）',
              text8: 'Ethernet – II'
            }
          },
          {
            title: '服务质量',
            textObj: {
              text1: '背压式流控（半双工）',
              text2: 'IEEE 802.3x流控（全双工）',
              text3: 'IEEE 802.1p, CoS',
              text4: 'WRR、SP和FIFO队列调度算法',
              text5: '基于每个ONU的上行和下行限速功能',
              text6: '支持DBA和SLA功能'
            }
          },
          {
            title: 'VLAN',
            textObj: {
              text1: '基于端口的VLAN，4K VLAN表项',
              text2: 'QinQ，并支持灵活QinQ'
            }
          },
          {
            title: '组播',
            textObj: {
              text1: 'L2 Multicast',
              text2: 'IGMP Snooping',
              text3: 'MLD Snooping'
            }
          },
          {
            title: '可靠性',
            textObj: {
              text1: '单方向链路检测协议，防止生存树环路',
              text2: '扩展槽GPON光模块热插拔',
              text3: 'GPON光路（b/c类、手拉手等）保护',
              text4: '支持长发光等异常发光ONU的检测功能'
            }
          },
          {
            title: '网络安全',
            textObj: {
              text1: '限制每端口最大用户数',
              text2: '端口隔离',
              text3: '报文风暴控制',
              text4: '基于数据流的ACL 访问控制功能',
              text5: 'PON口传输数据加密'
            }
          },
          {
            title: '配置管理',
            textObj: {
              text1: 'CLI, SNMP, TELNET等多种管理方式。',
              text2: '可通过TFTP进行软件升级',
              text3: '调试输出debug'
            }
          },
          {
            title: '物理特性',
            textObj: {
              text1: '442.5mm(W) x304mm(D) x 44mm(H) ',
              text2: '安装: 标准19”机架',
              text3: '重量：<7Kg'
            }
          },
          {
            title: '环境要求',
            textObj: {
              text1: '工作环境：0℃～45℃；10%～85%无冷凝',
              text2: '存储环境：-40℃～80℃；5%～95%无冷凝'
            }
          }
        ]
      }
    ]
  },
  {
    name: 'GP3600-16B',
    module: '通讯',
    sortNum: 13,
    devieceData: {
      img: require('@/assets/hardware/communication13.png'),
      zhName: '高密度机架式GPON OLT',
      enName: 'TURBO GP3600-16B',
      content:
        'TURBO GP3600系列 OLT是遵循ITU-T G.984/G.988和中华人民共和国通讯行业标准《接入网技术要求——吉比特无源光网络（GPON）》为基础的OLT设备，全面支持CTC2.0标准，自动发现和兼容各厂商ONU，可组建高效的GPON解决方案。'
    },
    tabsPane: [
      {
        label: '产品介绍',
        className: 'intro-class',
        dataList: [
          {
            textObj: {
              text1:
                'TURBO GP3600系列 OLT是遵循ITU-T G.984/G.988和中华人民共和国通讯行业标准《接入网技术要求——吉比特无源光网络（GPON）》为基础的OLT设备，全面支持CTC2.0标准，自动发现和兼容各厂商ONU，可组建高效的GPON解决方案。',
              text2:
                'TURBO GP3600系列 OLT支持下行2.5Gbps/上行1.25Gbps的PON传输速率，高效的带宽利用率和以太网业务支持能力帮助运营商为他们的用户提供可靠的业务质量保证。高达1:128的分光比，支持不同类型ONU的混和组网，使得运营商的投资做到最小化。TURBO GP3600系列OLT采用业界先进的技术，在以太网业务上具有强大的功能和QoS保证，支持SLA和DBA。'
            }
          }
        ]
      },
      {
        label: '产品特性',
        className: 'property-class',
        dataList: [
          {
            title:
              'TURBO GP3600系列OLT是适合目前市场需求的光网络设备，该系列产品拥有以下优势：',
            textObj: {
              text1:
                'GPON: GP3600系列OLT遵循ITU-T G.984/G.988、中华人民共和国通讯行业标准《接入网技术要求——吉比特无源光网络（GPON）》以及中国电信GPON设备技术要求CTC2.0 ；',
              text2: '系统容量: GP3600系列OLT 支持8/16个GPON PON接口；',
              text3:
                '上连接口:标配4个千兆SFP接口+4个千兆combo接口，4个万兆SFP+接口；',
              text4: '设备尺寸: 1U高度，占用机房空间小；',
              text5: '绿色环保：低功耗，降低业务的运营成本；',
              text6:
                '干线光纤保护: GP3600系列OLT支持在光纤发生故障时链路自动保护切换；',
              text7:
                '上连接口:标配4个千兆SFP接口+4个千兆combo接口，4个万兆SFP+接口；',
              text8:
                '电源特性：支持双AC、双DC以及AC/DC混合双电源，电源采用模块化设计，可热插拔，EMC 3级标准。具备同类产品中更强大的环境适应能力。'
            }
          }
        ]
      },
      {
        label: '产品参数',
        className: 'params-class',
        dataList: [
          {
            title: '项目',
            textObj: {
              text1: 'GP3600-08B'
            },
            textObj2: {
              text1: 'GP3600-16B'
            }
          },
          {
            title: '系统容量',
            textObj: {
              text1: '最高1:128分光比',
              text2: '背板带宽205G'
            }
          },
          {
            title: '接口PON',
            textObj: {
              text1: '8个GPON接口'
            },
            textObj2: {
              text1: '16个GPON接口'
            }
          },
          {
            title: '接口上联口',
            textObj: {
              text1: '8个GE接口（4路千兆光口+4路千兆Combo口）'
            },
            textObj2: {
              text1: '16个GPON接口',
              text2: '4个万兆接口'
            }
          },
          {
            title: 'PON接口特性',
            textObj: {
              text1: '下行2.5Gbps/上行1.25Gbps传输速率',
              text2: '支持Class B+、Class C+ 、Class C++GPON模块',
              text3: '安全性: ONU认证机制'
            }
          },
          {
            title: '遵循的标准',
            textObj: {
              text1: 'ITU-T G.984/G.988',
              text2:
                '中华人民共和国通讯行业标准《接入网技术要求——吉比特无源光网络（GPON）》',
              text3: '中国电信GPON设备技术要求CTC2.0',
              text4: 'IEEE 802.1D, Spanning Tree',
              text5: 'IEEE 802.1Q, VLAN',
              text6: 'IEEE 802.1w, RSTP',
              text7: 'IEEE 802.3ad 物理链路静态/动态聚合（LACP）',
              text8: 'Ethernet – II'
            }
          },
          {
            title: '服务质量',
            textObj: {
              text1: '背压式流控（半双工）',
              text2: 'IEEE 802.3x流控（全双工）',
              text3: 'IEEE 802.1p, CoS',
              text4: 'WRR、SP和FIFO队列调度算法',
              text5: '基于每个ONU的上行和下行限速功能',
              text6: '支持DBA和SLA功能'
            }
          },
          {
            title: 'VLAN',
            textObj: {
              text1: '基于端口的VLAN，4K VLAN表项',
              text2: 'QinQ，并支持灵活QinQ'
            }
          },
          {
            title: '组播',
            textObj: {
              text1: 'L2 Multicast',
              text2: 'IGMP Snooping',
              text3: 'MLD Snooping'
            }
          },
          {
            title: '可靠性',
            textObj: {
              text1: '单方向链路检测协议，防止生存树环路',
              text2: '扩展槽GPON光模块热插拔',
              text3: 'GPON光路（b/c类、手拉手等）保护',
              text4: '支持长发光等异常发光ONU的检测功能'
            }
          },
          {
            title: '网络安全',
            textObj: {
              text1: '限制每端口最大用户数',
              text2: '端口隔离',
              text3: '报文风暴控制',
              text4: '基于数据流的ACL 访问控制功能',
              text5: 'PON口传输数据加密'
            }
          },
          {
            title: '配置管理',
            textObj: {
              text1: 'CLI, SNMP, TELNET等多种管理方式。',
              text2: '可通过TFTP进行软件升级',
              text3: '调试输出debug'
            }
          },
          {
            title: '物理特性',
            textObj: {
              text1: '442.5mm(W) x304mm(D) x 44mm(H) ',
              text2: '安装: 标准19”机架',
              text3: '重量：<7Kg'
            }
          },
          {
            title: '环境要求',
            textObj: {
              text1: '工作环境：0℃～45℃；10%～85%无冷凝',
              text2: '存储环境：-40℃～80℃；5%～95%无冷凝'
            }
          }
        ]
      }
    ]
  },
  {
    name: 'GP6606',
    module: '通讯',
    sortNum: 14,
    devieceData: {
      img: require('@/assets/hardware/communication14.png'),
      zhName: '机框式XPON OLT',
      enName: 'TURBO GP6606',
      content:
        'TURBO GP6606系列 OLT是多业务统一承载平台，提供EPON、GPON和10GEPON接入，未来可支持XGPON接入。'
    },
    tabsPane: [
      {
        label: '产品介绍',
        className: 'intro-class',
        dataList: [
          {
            textObj: {
              text1:
                'TURBO GP6606系列 OLT是多业务统一承载平台，提供EPON、GPON和10GEPON接入，未来可支持XGPON接入。可支持FTTH、FTTB、FTTC和POL等各种组网方式，实现家庭用户、企业用户的全业务融合接入，实现一张光纤网络覆盖全业务，简化网络架构，降低OPEX。全系列产品包含中型和大型两种规格产品，满足不同应用场景需求。'
            }
          }
        ]
      },
      {
        label: '产品特性',
        className: 'property-class',
        dataList: [
          {
            textObj: {
              text1:
                '标准：遵循ITU-T G.984/G.988、中华人民共和国通讯行业标准《接入网技术要求——吉比特无源光网络（GPON）》以及中国电信GPON设备技术要求CTC2.0 ；遵循IEEE 802.3ah、IEEE 802.3av、中华人民共和国通讯行业标准（YD/T 1475-2006）以及中国电信EPON设备技术要求CTC3.0；',
              text2: '良好的互通能力：具备自动发现和兼容异厂家ONT设备；',
              text3: '硬件冗余：双主控、双电源冗余设计，确保设备稳妥运行；',
              text4: '热拔插：整机采用模块化设计，各业务板卡均支持热拔插；',
              text5:
                '系统容量: 最大支持128个GPON PON接口；最大支持128个EPON PON接口；最大支持64个10GEPON PON接口；',
              text6: '绿色环保：低功耗，降低业务的运营成本；',
              text7: '干线光纤保护: 支持在光纤发生故障时链路自动保护切换；',
              text8:
                '电源特性：支持双AC、双DC以及AC/DC混合双电源，电源采用模块化设计，可热插拔，EMC 3级标准。具备同类产品中更强大的环境适应能力'
            }
          }
        ]
      },
      {
        label: '产品参数',
        className: 'params-class',
        dataList: [
          {
            title: '项目',
            textObj: {
              text1: 'GP6606-06'
            },
            textObj2: {
              text1: 'GP6606-10'
            }
          },
          {
            title: '系统容量',
            textObj: {
              text1: '最高1:128分光',
              text2: '背板带宽：4T',
              text3: 'MAC地址容量：128K'
            }
          },
          {
            title: 'PON接口特性(GPON)',
            textObj: {
              text1: '下行2.5Gbps/上行1.25Gbps传输速率',
              text2: '支持Class B+、Class C+ 、Class C++ PON模块',
              text3: '安全性: ONU认证机制'
            }
          },
          {
            title: 'PON接口特性(EPON)',
            textObj: {
              text1: '下行1.25Gbps/上行1.25Gbps传输速率',
              text2: '支持PX20+、PX20++  PON模块',
              text3: '安全性: ONU认证机制'
            }
          },
          {
            title: 'PON接口特性(10GEPON)',
            textObj: {
              text1: '下行10Gbps/上行1.25Gbps传输速率（非对称模式）',
              text2: '下行10Gbps/上行10Gbps传输速率（对称模式）',
              text3: '支持PRX30、PR30 PON模块',
              text4: '安全性: ONU认证机制'
            }
          },
          {
            title: '槽位数',
            textObj: {
              text1: '2个主控槽位+4个业务槽位'
            },
            textObj2: {
              text1: '2个主控槽位+8个业务槽位'
            }
          },
          {
            title: '最大万兆端口数',
            textObj: {
              text1: '48'
            },
            textObj2: {
              text1: '96'
            }
          },
          {
            title: '最大GPON接口数',
            textObj: {
              text1: '64'
            },
            textObj2: {
              text1: '128'
            }
          },
          {
            title: '最大EPON接口数',
            textObj: {
              text1: '64'
            },
            textObj2: {
              text1: '128'
            }
          },
          {
            title: '最大10GEPON接口数',
            textObj: {
              text1: '32'
            },
            textObj2: {
              text1: '64'
            }
          },
          {
            title: '最大10GEPON接口数',
            textObj: {
              text1: '32'
            },
            textObj2: {
              text1: '64'
            }
          },
          {
            title: '遵循的标准',
            textObj: {
              text1: 'ITU-T G.984/G.988',
              text2: 'IEEE 802.3ah',
              text3: 'IEEE 802.3av',
              text4:
                '中华人民共和国通讯行业标准《接入网技术要求——吉比特无源光网络（GPON）》中国电信GPON设备技术要求CTC2.',
              text5:
                '中华人民共和国通讯行业标准（YD/T 1475-2006）以及中国电信EPON设备技术要求CTC3.0',
              text6: 'IEEE 802.1D, Spanning Tree',
              text7: 'IEEE 802.1Q, VLAN',
              text8: 'IEEE 802.1w, RSTP',
              text9: 'IEEE 802.3ad 物理链路静态/动态聚合（LACP）',
              text10: 'Ethernet – II'
            }
          },
          {
            title: '服务质量',
            textObj: {
              text1: '背压式流控（半双工）',
              text2: 'IEEE 802.3x流控（全双工）',
              text3: 'IEEE 802.1p, CoS',
              text4: 'WRR、SP和FIFO队列调度算法',
              text5: '基于每个ONU的上行和下行限速功能',
              text6: '支持DBA和SLA功能'
            }
          },
          {
            title: 'VLAN',
            textObj: {
              text1: '基于端口的VLAN，4K VLAN表项',
              text2: 'QinQ，并支持灵活QinQ'
            }
          },
          {
            title: '组播',
            textObj: {
              text1: 'L2 Multicast',
              text2: 'IGMP Snooping',
              text3: 'MLD Snooping'
            }
          },
          {
            title: '可靠性',
            textObj: {
              text1: '单方向链路检测协议，防止生存树环路',
              text2: 'PON光模块热插拔',
              text3: 'PON光路保护',
              text4: '支持长发光等异常发光ONU的检测功能'
            }
          },
          {
            title: '网络安全',
            textObj: {
              text1: '限制每端口最大用户数',
              text2: '端口隔离',
              text3: '报文风暴控制',
              text4: '基于数据流的ACL 访问控制功能',
              text5: 'PON口传输数据加密'
            }
          },
          {
            title: '配置管理',
            textObj: {
              text1: 'CLI, SNMP, TELNET等多种管理方式。',
              text2: '可通过TFTP进行软件升级',
              text3: '调试输出debug'
            }
          },
          {
            title: '物理特性',
            textObj: {
              text1: '399.7mm×482.6mm×548mm',
              text2: '安装: 标准19”机架'
            },
            textObj2: {
              text1: '533.1mm×482.6mm×548mm',
              text2: '安装: 标准19”机架'
            }
          },
          {
            title: '环境要求',
            textObj: {
              text1: '工作环境：0℃～45℃；10%～85%无冷凝',
              text2: '存储环境：-40℃～80℃；5%～95%无冷凝 '
            }
          },
          {
            title: '电源',
            textObj: {
              text1: '输入电压：AC90～264V，DC 36～72V',
              text2: '支持双电源输入、交直流电源混插、以及电源模块热插拔'
            }
          }
        ]
      }
    ]
  },
  {
    name: 'GP1702-4G-M',
    module: '通讯',
    sortNum: 15,
    devieceData: {
      img: require('@/assets/hardware/communication15.png'),
      zhName: '商用级千兆ONU',
      enName: 'TURBO GP1702-4G-M',
      content:
        'TURBO GP1702-4G-M 是捍御者数据通信面向多业务融合性网络推出的智能化ONU终端产品。'
    },
    tabsPane: [
      {
        label: '产品介绍',
        className: 'intro-class',
        dataList: [
          {
            textObj: {
              text1:
                ' TURBO GP1702-4G-M 是捍御者数据通信面向多业务融合性网络推出的智能化ONU终端产品。该产品遵循ITU-T G.9844/988国际标准，中华人民共和国通讯行业标准《接入网技术--吉比特无源光网络》，以及中国电信GPON设备技术要求CTC2.0，具备良好的互通性和操作性，可实现与业界主流厂商OLT设备的互连互通。'
            }
          }
        ]
      },
      {
        label: '产品特性',
        className: 'property-class',
        dataList: [
          {
            title: '优越的接入性能',
            textObj: {
              text1:
                '支持下行2.5Gbps/上行1.25Gbps的PON传输速率，配合OLT设备可实现高达1:128的分光比，网络覆盖半径可达20KM；体现出PON通信的高带宽、高用户接入量、长距离的优越接入能力。'
            }
          },
          {
            title: '安全的业务承载能力',
            textObj: {
              text1:
                '拥有20多年专业研发技术的捍御者，针对性地开发了VLAN、生成树、端口隔离、ACL、QoS、安全过滤、风暴控制等技术，使得该ONU产品具备了安全的业务承载能力。'
            }
          },
          {
            title: '精细化的业务控制能力',
            textObj: {
              text1:
                '支持DBA和Rate-Limit功能，先进的动态带宽分配机制和精确的带宽限制功能，使所有用户更合理的分享2.5Gbps的带宽资源；支持QOS功能，实现可靠的服务质量，确保网络中不同业务的服务优先级和服务质量；'
            }
          },
          {
            title: '丰富的OMCI功能',
            textObj: {
              text1:
                '支持ITU-T定义的标准OMCI，包括配置、告警、性能监控、故障隔离和安全管理等，同时支持捍御者定义的私有OMCI，配合捍御者OLT实现更丰富的业务控制'
            }
          },
          {
            title: '完善的互连互通功能',
            textObj: {
              text1:
                '产品遵循ITU-T G.984/988国际标准，中华人民共和国通讯行业标准《接入网技术--吉比特无源光网络》，中国电信GPON设备技术要求CTC2.0，可轻松实现与业界主流厂商标准OLT设备的互连互通，混合组网，使网络建设费用最小化.'
            }
          },
          {
            title: '先进的绿色节能技术',
            textObj: {
              text1:
                '源于捍御者业界先进的“GreenTouch”架构及“Smart@CHIP”智能芯片技术，深度节能，低碳环保。'
            }
          }
        ]
      },
      {
        label: '产品参数',
        className: 'params-class',
        dataList: [
          {
            title: '用户测接口',
            textObj: {
              text1: '4*GE'
            }
          },
          {
            title: 'PON接口',
            textObj: {
              text1: '下行2.5Gbps/上行1.25Gbps传输速率',
              text2: '网络覆盖半径: 20公里',
              text3: '光接口类型：SC/UPC',
              text4: '接收灵敏度: ≤-28dBm',
              text5: '发送光功率：0.5~5dBm ',
              text6: '安全性: ONU认证机制'
            }
          },
          {
            title: '遵循的标准',
            textObj: {
              text1: 'ITU-T G.984/G.988',
              text2:
                '中华人民共和国通讯行业标准《接入网技术--吉比特无源光网络》',
              text3: 'IEEE 802.1D, Spanning Tree',
              text4: 'IEEE 802.1Q, VLAN',
              text5: 'IEEE 802.1w, RSTP',
              text6: 'ITU-T Y.1291'
            }
          },
          {
            title: 'VLAN',
            textObj: {
              text1: '支持64个VLAN（1~4094）',
              text2: '基于端口的VLAN',
              text3: 'IEEE 802.1Q VLAN',
              text4: '支持CTC2.0定义VLAN'
            }
          },
          {
            title: '组播',
            textObj: {
              text1: '支持IGMP-Snooping',
              text2: '支持CTC定义动态组播功能',
              text3: '支持MLD-Snooping'
            }
          },
          {
            title: '服务质量',
            textObj: {
              text1: '背压式流控（半双工）',
              text2: 'IEEE 802.3x流控（全双工）',
              text3: '防止Head Of Line机制',
              text4: 'IEEE 802.1p, CoS',
              text5: '每端口4个优先级队列',
              text6: 'WR、SP和FIFO队列调度算法',
              text7: '端口限速'
            }
          },
          {
            title: '可靠性',
            textObj: {
              text1: '支持Loopdetect功能',
              text2: '支持Dying-Gasp功能'
            }
          },
          {
            title: '网络安全',
            textObj: {
              text1: '支持端口MAC地址数限制',
              text2: '支持端口保护',
              text3: '支持端口风暴抑制'
            }
          },
          {
            title: '管理配置',
            textObj: {
              text1: 'CLI, Web, SNMP, TELNET等多种管理方式',
              text2: '可通过TFTP和WEB,OMCI等方式进行软件升级',
              text3: '本地或服务器syslog记录系统日志'
            }
          },
          {
            title: '物理尺寸',
            textObj: {
              text1: '170*98*28mm'
            }
          },
          {
            title: '散热性',
            textObj: {
              text1:
                '长时间使用（24小时)，设备发热不会引起性能下降及器件变形损坏。'
            }
          },
          {
            title: '环境要求',
            textObj: {
              text1: '工作环境：0℃～45℃；10%～85%无冷凝',
              text2: '存储环境：-40℃～80℃；5%～95%无冷凝  ',
              text3: '输入额定电压：DC12V/0.5A（外置适配器供电）',
              text4: '功率: 小于6W'
            }
          }
        ]
      }
    ]
  },
  {
    name: 'GP1702-4G-POE',
    module: '通讯',
    sortNum: 16,
    devieceData: {
      img: require('@/assets/hardware/communication16.png'),
      zhName: '千兆POE ONU',
      enName: 'TURBO GP1702-4G-POE',
      content:
        'TURBO GP1702-4G-M 是捍御者数据通信面向多业务融合性网络推出的智能化ONU终端产品。'
    },
    tabsPane: [
      {
        label: '产品介绍',
        className: 'intro-class',
        dataList: [
          {
            textObj: {
              text1:
                ' TURBO GP1702-4G-M 是捍御者数据通信面向多业务融合性网络推出的智能化ONU终端产品。该产品遵循ITU-T G.9844/988国际标准，中华人民共和国通讯行业标准《接入网技术--吉比特无源光网络》，以及中国电信GPON设备技术要求CTC2.0，具备良好的互通性和操作性，可实现与业界主流厂商OLT设备的互连互通。'
            }
          }
        ]
      },
      {
        label: '产品特性',
        className: 'property-class',
        dataList: [
          {
            title: '优越的接入性能',
            textObj: {
              text1:
                '支持下行2.5Gbps/上行1.25Gbps的PON传输速率，配合OLT设备可实现高达1:128的分光比，网络覆盖半径可达20KM；体现出PON通信的高带宽、高用户接入量、长距离的优越接入能力。'
            }
          },
          {
            title: '安全的业务承载能力',
            textObj: {
              text1:
                '拥有20多年专业研发技术的捍御者，针对性地开发了VLAN、生成树、端口隔离、ACL、QoS、安全过滤、风暴控制等技术，使得该ONU产品具备了安全的业务承载能力。'
            }
          },
          {
            title: '精细化的业务控制能力',
            textObj: {
              text1:
                '支持DBA和Rate-Limit功能，先进的动态带宽分配机制和精确的带宽限制功能，使所有用户更合理的分享2.5Gbps的带宽资源；支持QOS功能，实现可靠的服务质量，确保网络中不同业务的服务优先级和服务质量；'
            }
          },
          {
            title: '丰富的OMCI功能',
            textObj: {
              text1:
                '支持ITU-T定义的标准OMCI，包括配置、告警、性能监控、故障隔离和安全管理等，同时支持捍御者定义的私有OMCI，配合捍御者OLT实现更丰富的业务控制'
            }
          },
          {
            title: '完善的互连互通功能',
            textObj: {
              text1:
                '产品遵循ITU-T G.984/988国际标准，中华人民共和国通讯行业标准《接入网技术--吉比特无源光网络》，中国电信GPON设备技术要求CTC2.0，可轻松实现与业界主流厂商标准OLT设备的互连互通，混合组网，使网络建设费用最小化.'
            }
          },
          {
            title: '先进的绿色节能技术',
            textObj: {
              text1:
                '源于捍御者业界先进的“GreenTouch”架构及“Smart@CHIP”智能芯片技术，深度节能，低碳环保。'
            }
          }
        ]
      },
      {
        label: '产品参数',
        className: 'params-class',
        dataList: [
          {
            title: '用户测接口',
            textObj: {
              text1: '4*GE'
            }
          },
          {
            title: 'PON接口',
            textObj: {
              text1: '下行2.5Gbps/上行1.25Gbps传输速率',
              text2: '网络覆盖半径: 20公里',
              text3: '光接口类型：SC/UPC',
              text4: '接收灵敏度: ≤-28dBm',
              text5: '发送光功率：0.5~5dBm ',
              text6: '安全性: ONU认证机制'
            }
          },
          {
            title: '遵循的标准',
            textObj: {
              text1: 'ITU-T G.984/G.988',
              text2:
                '中华人民共和国通讯行业标准《接入网技术--吉比特无源光网络》',
              text3: 'IEEE 802.1D, Spanning Tree',
              text4: 'IEEE 802.1Q, VLAN',
              text5: 'IEEE 802.1w, RSTP',
              text6: 'ITU-T Y.1291'
            }
          },
          {
            title: 'VLAN',
            textObj: {
              text1: '支持64个VLAN（1~4094）',
              text2: '基于端口的VLAN',
              text3: 'IEEE 802.1Q VLAN',
              text4: '支持CTC2.0定义VLAN'
            }
          },
          {
            title: '组播',
            textObj: {
              text1: '支持IGMP-Snooping',
              text2: '支持CTC定义动态组播功能',
              text3: '支持MLD-Snooping'
            }
          },
          {
            title: '服务质量',
            textObj: {
              text1: '背压式流控（半双工）',
              text2: 'IEEE 802.3x流控（全双工）',
              text3: '防止Head Of Line机制',
              text4: 'IEEE 802.1p, CoS',
              text5: '每端口4个优先级队列',
              text6: 'WR、SP和FIFO队列调度算法',
              text7: '端口限速'
            }
          },
          {
            title: '可靠性',
            textObj: {
              text1: '支持Loopdetect功能',
              text2: '支持Dying-Gasp功能'
            }
          },
          {
            title: '网络安全',
            textObj: {
              text1: '支持端口MAC地址数限制',
              text2: '支持端口保护',
              text3: '支持端口风暴抑制'
            }
          },
          {
            title: '管理配置',
            textObj: {
              text1: 'CLI, Web, SNMP, TELNET等多种管理方式',
              text2: '可通过TFTP和WEB,OMCI等方式进行软件升级',
              text3: '本地或服务器syslog记录系统日志'
            }
          },
          {
            title: '物理尺寸',
            textObj: {
              text1: '170*98*28mm'
            }
          },
          {
            title: '散热性',
            textObj: {
              text1:
                '长时间使用（24小时)，设备发热不会引起性能下降及器件变形损坏。'
            }
          },
          {
            title: '环境要求',
            textObj: {
              text1: '工作环境：0℃～45℃；10%～85%无冷凝',
              text2: '存储环境：-40℃～80℃；5%～95%无冷凝  ',
              text3: '输入额定电压：DC12V/0.5A（外置适配器供电）',
              text4: '功率: 小于6W'
            }
          }
        ]
      }
    ]
  },
  {
    name: 'GP1704-4GV',
    module: '通讯',
    sortNum: 17,
    devieceData: {
      img: require('@/assets/hardware/communication17.png'),
      zhName: '商用级千兆HGU',
      enName: 'TURBO GP1704-4GV',
      content:
        'TURBO GP1704-4GV系列 HGU是捍御者数据通信面向多业务融合性网络推出的智能化家庭网关终端产品，集成无线和语音功能（GP1704-4GVC-S内置CATV光机模块）。'
    },
    tabsPane: [
      {
        label: '产品介绍',
        className: 'intro-class',
        dataList: [
          {
            textObj: {
              text1:
                'TURBO GP1704-4GV系列 HGU是捍御者数据通信面向多业务融合性网络推出的智能化家庭网关终端产品，集成无线和语音功能（GP1704-4GVC-S内置CATV光机模块）。该产品遵循ITU-T G.9844/988国际标准，中华人民共和国通讯行业标准《接入网技术--吉比特无源光网络》，以及中国电信GPON设备技术要求CTC2.0，具备良好的互通性和操作性，可实现与业界主流厂商OLT设备的互连互通。'
            }
          }
        ]
      },
      {
        label: '产品特性',
        className: 'property-class',
        dataList: [
          {
            title: '优越的接入性能',
            textObj: {
              text1:
                '支持下行2.5Gbps/上行1.25Gbps的PON传输速率，配合OLT设备可实现高达1:128的分光比，网络覆盖半径可达20KM；体现出PON通信的高带宽、高用户接入量、长距离的优越接入能力。'
            }
          },
          {
            title: '安全的业务承载能力',
            textObj: {
              text1:
                '拥有20多年专业研发技术的捍御者，针对性地开发了VLAN、生成树、端口隔离、ACL、QoS、安全过滤、风暴控制等技术，使得该ONU产品具备了安全的业务承载能力。'
            }
          },
          {
            title: '精细化的业务控制能力',
            textObj: {
              text1:
                '支持DBA和Rate-Limit功能，先进的动态带宽分配机制和精确的带宽限制功能，使所有用户更合理的分享2.5Gbps的带宽资源；支持QOS功能，实现可靠的服务质量，确保网络中不同业务的服务优先级和服务质量；'
            }
          },
          {
            title: '丰富的OMCI功能',
            textObj: {
              text1:
                '支持ITU-T定义的标准OMCI，包括配置、告警、性能监控、故障隔离和安全管理等，同时支持捍御者定义的私有OMCI，配合捍御者OLT实现更丰富的业务控制'
            }
          },
          {
            title: '完善的互连互通功能',
            textObj: {
              text1:
                '产品遵循ITU-T G.984/988国际标准，中华人民共和国通讯行业标准《接入网技术--吉比特无源光网络》，中国电信GPON设备技术要求CTC2.0，可轻松实现与业界主流厂商标准OLT设备的互连互通，混合组网，使网络建设费用最小化.'
            }
          },
          {
            title: '丰富的OMCI功能',
            textObj: {
              text1:
                '源于捍御者业界先进的“GreenTouch”架构及“Smart@CHIP”智能芯片技术，深度节能，低碳环保.'
            }
          }
        ]
      },
      {
        label: '产品参数',
        className: 'params-class',
        dataList: [
          {
            title: '项目',
            textObj: {
              text1: 'GP1704-4GV'
            },
            textObj2: {
              text1: 'GP1704-4GV-22A'
            },
            textObj3: {
              text1: 'GP1704-4GVC-S'
            }
          },
          {
            title: '用户测接口',
            textObj: {
              text1: '4*GE',
              text2: '2*FXS'
            }
          },
          {
            title: '用户测接口',
            textObj: {
              text1: 'WIFI接口 （802.11b/g/n，外置双天线）'
            },
            textObj2: {
              text1: 'WIFI接口 （802.11b/g/n+802.11ac，外置4天线）'
            },
            textObj3: {
              text1: 'WIFI接口 （802.11b/g/n，外置双天线）'
            }
          },
          {
            title: '用户测接口',
            textObj: {
              text1: 'N/A'
            }
          },
          {
            title: '用户测接口',
            textObj: {
              text1: '1N/A'
            }
          },
          {
            title: '用户测接口',
            textObj: {
              text1: '英制RF接口，输出电平≥70dbuv'
            }
          },
          {
            title: 'PON接口',
            textObj: {
              text1: '下行2.5Gbps/上行1.25Gbps传输速率',
              text2: '网络覆盖半径: 20公里',
              text3: '接收灵敏度: ≤-28dBm',
              text4: '发送光功率：0.5~5dBm '
            }
          },
          {
            title: 'PON接口',
            textObj: {
              text1: '光接口类型：SC/UPC'
            },
            textObj2: {
              text1: '光接口类型：SC/UPC'
            },
            textObj3: {
              text1: '光接口类型：SC/UPC'
            }
          },
          {
            title: 'CATV光接收AGC范围',
            textObj: {
              text1: 'N/A'
            },
            textObj2: {
              text1: 'N/A'
            },
            textObj3: {
              text1: '-15~-5dbm'
            }
          },
          {
            title: '遵循的标准',
            textObj: {
              text1: 'ITU-T G.984/G.988',
              text2:
                '中华人民共和国通讯行业标准《接入网技术--吉比特无源光网络》',
              text3: 'IEEE 802.1D, Spanning Tree',
              text4: 'IEEE 802.1Q, VLAN',
              text5: 'IEEE 802.1w, RSTP',
              text6: 'ITU-T Y.1291',
              text7: 'Q/CT 1923',
              text8: 'Q/CT 1927'
            }
          },
          {
            title: '遵循的标准',
            textObj: {
              text1: 'ITU-T G.984/G.988',
              text2:
                '中华人民共和国通讯行业标准《接入网技术--吉比特无源光网络》',
              text3: 'IEEE 802.1D, Spanning Tree',
              text4: 'IEEE 802.1Q, VLAN',
              text5: 'IEEE 802.1w, RSTP',
              text6: 'ITU-T Y.1291',
              text7: 'Q/CT 1923',
              text8: 'Q/CT 1927'
            }
          },
          {
            title: '数据转发',
            textObj: {
              text1: '支持路由工作模式、桥接工作模式、路由+桥接混杂工作模式'
            }
          },
          {
            title: 'DHCP',
            textObj: {
              text1: '支持DHCP server功能'
            }
          },
          {
            title: '组播',
            textObj: {
              text1: '支持IGMP-Snooping',
              text2: '支持CTC定义动态组播功能',
              text3: '支持MLD-Snooping'
            }
          },
          {
            title: '语音功能',
            textObj: {
              text1: '支持SIP、H.248'
            }
          },
          {
            title: 'WIFI',
            textObj: {
              text1: '支持802.11b/g/n',
              text2: '支持MIMO，最大速率达300Mbps',
              text3: '多SSID',
              text4: '支持SSID加密'
            }
          },
          {
            title: '服务质量',
            textObj: {
              text1: '背压式流控（半双工）',
              text2: 'IEEE 802.3x流控（全双工）',
              text3: '防止Head Of Line机制',
              text4: 'IEEE 802.1p, CoS',
              text5: '每端口4个优先级队列',
              text6: 'WR、SP和FIFO队列调度算法',
              text7: '端口限速'
            }
          },
          {
            title: '可靠性',
            textObj: {
              text1: '支持Loopdetect功能',
              text2: '支持Dying-Gasp功能'
            }
          },
          {
            title: '网络安全',
            textObj: {
              text1: '支持端口MAC地址数限制',
              text2: '支持端口保护',
              text3: '支持防DOS攻击',
              text4: '支持防止端口扫描'
            }
          },
          {
            title: '管理配置',
            textObj: {
              text1: 'CLI, Web, TELNET,TR069等多种管理方式',
              text2: '可通过TFTP和WEB或者TR069、OLT进行软件升级',
              text3: '本地或服务器syslog记录系统日志'
            }
          },
          {
            title: '物理尺寸',
            textObj: {
              text1: '230*140*34.9mm（不含天线）'
            }
          },
          {
            title: '散热性',
            textObj: {
              text1:
                '长时间使用（24小时)，设备发热不会引起性能下降及器件变形损坏。'
            }
          },
          {
            title: '物理尺寸',
            textObj: {
              text1: '工作环境：0℃～45℃；10%～90%无冷凝',
              text2: '输入额定电压：DC12V/1.5A（外置适配器供电）',
              text3: '功率: 小于15W'
            }
          }
        ]
      }
    ]
  },
  {
    name: 'GP1704-4GV-22A',
    module: '通讯',
    sortNum: 18,
    devieceData: {
      img: require('@/assets/hardware/communication18.png'),
      zhName: '商用级千兆HGU',
      enName: 'TURBO GP1704-4GV-22A',
      content:
        'TURBO GP1704-4GV系列 HGU是捍御者数据通信面向多业务融合性网络推出的智能化家庭网关终端产品，集成无线和语音功能（GP1704-4GVC-S内置CATV光机模块）。'
    },
    tabsPane: [
      {
        label: '产品介绍',
        className: 'intro-class',
        dataList: [
          {
            textObj: {
              text1:
                'TURBO GP1704-4GV系列 HGU是捍御者数据通信面向多业务融合性网络推出的智能化家庭网关终端产品，集成无线和语音功能（GP1704-4GVC-S内置CATV光机模块）。该产品遵循ITU-T G.9844/988国际标准，中华人民共和国通讯行业标准《接入网技术--吉比特无源光网络》，以及中国电信GPON设备技术要求CTC2.0，具备良好的互通性和操作性，可实现与业界主流厂商OLT设备的互连互通。'
            }
          }
        ]
      },
      {
        label: '产品特性',
        className: 'property-class',
        dataList: [
          {
            title: '优越的接入性能',
            textObj: {
              text1:
                '支持下行2.5Gbps/上行1.25Gbps的PON传输速率，配合OLT设备可实现高达1:128的分光比，网络覆盖半径可达20KM；体现出PON通信的高带宽、高用户接入量、长距离的优越接入能力。'
            }
          },
          {
            title: '安全的业务承载能力',
            textObj: {
              text1:
                '拥有20多年专业研发技术的捍御者，针对性地开发了VLAN、生成树、端口隔离、ACL、QoS、安全过滤、风暴控制等技术，使得该ONU产品具备了安全的业务承载能力。'
            }
          },
          {
            title: '精细化的业务控制能力',
            textObj: {
              text1:
                '支持DBA和Rate-Limit功能，先进的动态带宽分配机制和精确的带宽限制功能，使所有用户更合理的分享2.5Gbps的带宽资源；支持QOS功能，实现可靠的服务质量，确保网络中不同业务的服务优先级和服务质量；'
            }
          },
          {
            title: '丰富的OMCI功能',
            textObj: {
              text1:
                '支持ITU-T定义的标准OMCI，包括配置、告警、性能监控、故障隔离和安全管理等，同时支持捍御者定义的私有OMCI，配合捍御者OLT实现更丰富的业务控制'
            }
          },
          {
            title: '完善的互连互通功能',
            textObj: {
              text1:
                '产品遵循ITU-T G.984/988国际标准，中华人民共和国通讯行业标准《接入网技术--吉比特无源光网络》，中国电信GPON设备技术要求CTC2.0，可轻松实现与业界主流厂商标准OLT设备的互连互通，混合组网，使网络建设费用最小化.'
            }
          },
          {
            title: '丰富的OMCI功能',
            textObj: {
              text1:
                '源于捍御者业界先进的“GreenTouch”架构及“Smart@CHIP”智能芯片技术，深度节能，低碳环保.'
            }
          }
        ]
      },
      {
        label: '产品参数',
        className: 'params-class',
        dataList: [
          {
            title: '项目',
            textObj: {
              text1: 'GP1704-4GV'
            },
            textObj2: {
              text1: 'GP1704-4GV-22A'
            },
            textObj3: {
              text1: 'GP1704-4GVC-S'
            }
          },
          {
            title: '用户测接口',
            textObj: {
              text1: '4*GE',
              text2: '2*FXS'
            }
          },
          {
            title: '用户测接口',
            textObj: {
              text1: 'WIFI接口 （802.11b/g/n，外置双天线）'
            },
            textObj2: {
              text1: 'WIFI接口 （802.11b/g/n+802.11ac，外置4天线）'
            },
            textObj3: {
              text1: 'WIFI接口 （802.11b/g/n，外置双天线）'
            }
          },
          {
            title: '用户测接口',
            textObj: {
              text1: 'N/A'
            }
          },
          {
            title: '用户测接口',
            textObj: {
              text1: '1N/A'
            }
          },
          {
            title: '用户测接口',
            textObj: {
              text1: '英制RF接口，输出电平≥70dbuv'
            }
          },
          {
            title: 'PON接口',
            textObj: {
              text1: '下行2.5Gbps/上行1.25Gbps传输速率',
              text2: '网络覆盖半径: 20公里',
              text3: '接收灵敏度: ≤-28dBm',
              text4: '发送光功率：0.5~5dBm '
            }
          },
          {
            title: 'PON接口',
            textObj: {
              text1: '光接口类型：SC/UPC'
            },
            textObj2: {
              text1: '光接口类型：SC/UPC'
            },
            textObj3: {
              text1: '光接口类型：SC/UPC'
            }
          },
          {
            title: 'CATV光接收AGC范围',
            textObj: {
              text1: 'N/A'
            },
            textObj2: {
              text1: 'N/A'
            },
            textObj3: {
              text1: '-15~-5dbm'
            }
          },
          {
            title: '遵循的标准',
            textObj: {
              text1: 'ITU-T G.984/G.988',
              text2:
                '中华人民共和国通讯行业标准《接入网技术--吉比特无源光网络》',
              text3: 'IEEE 802.1D, Spanning Tree',
              text4: 'IEEE 802.1Q, VLAN',
              text5: 'IEEE 802.1w, RSTP',
              text6: 'ITU-T Y.1291',
              text7: 'Q/CT 1923',
              text8: 'Q/CT 1927'
            }
          },
          {
            title: '遵循的标准',
            textObj: {
              text1: 'ITU-T G.984/G.988',
              text2:
                '中华人民共和国通讯行业标准《接入网技术--吉比特无源光网络》',
              text3: 'IEEE 802.1D, Spanning Tree',
              text4: 'IEEE 802.1Q, VLAN',
              text5: 'IEEE 802.1w, RSTP',
              text6: 'ITU-T Y.1291',
              text7: 'Q/CT 1923',
              text8: 'Q/CT 1927'
            }
          },
          {
            title: '数据转发',
            textObj: {
              text1: '支持路由工作模式、桥接工作模式、路由+桥接混杂工作模式'
            }
          },
          {
            title: 'DHCP',
            textObj: {
              text1: '支持DHCP server功能'
            }
          },
          {
            title: '组播',
            textObj: {
              text1: '支持IGMP-Snooping',
              text2: '支持CTC定义动态组播功能',
              text3: '支持MLD-Snooping'
            }
          },
          {
            title: '语音功能',
            textObj: {
              text1: '支持SIP、H.248'
            }
          },
          {
            title: 'WIFI',
            textObj: {
              text1: '支持802.11b/g/n',
              text2: '支持MIMO，最大速率达300Mbps',
              text3: '多SSID',
              text4: '支持SSID加密'
            }
          },
          {
            title: '服务质量',
            textObj: {
              text1: '背压式流控（半双工）',
              text2: 'IEEE 802.3x流控（全双工）',
              text3: '防止Head Of Line机制',
              text4: 'IEEE 802.1p, CoS',
              text5: '每端口4个优先级队列',
              text6: 'WR、SP和FIFO队列调度算法',
              text7: '端口限速'
            }
          },
          {
            title: '可靠性',
            textObj: {
              text1: '支持Loopdetect功能',
              text2: '支持Dying-Gasp功能'
            }
          },
          {
            title: '网络安全',
            textObj: {
              text1: '支持端口MAC地址数限制',
              text2: '支持端口保护',
              text3: '支持防DOS攻击',
              text4: '支持防止端口扫描'
            }
          },
          {
            title: '管理配置',
            textObj: {
              text1: 'CLI, Web, TELNET,TR069等多种管理方式',
              text2: '可通过TFTP和WEB或者TR069、OLT进行软件升级',
              text3: '本地或服务器syslog记录系统日志'
            }
          },
          {
            title: '物理尺寸',
            textObj: {
              text1: '230*140*34.9mm（不含天线）'
            }
          },
          {
            title: '散热性',
            textObj: {
              text1:
                '长时间使用（24小时)，设备发热不会引起性能下降及器件变形损坏。'
            }
          },
          {
            title: '物理尺寸',
            textObj: {
              text1: '工作环境：0℃～45℃；10%～90%无冷凝',
              text2: '输入额定电压：DC12V/1.5A（外置适配器供电）',
              text3: '功率: 小于15W'
            }
          }
        ]
      }
    ]
  },
  {
    name: 'HYZ-EL',
    module: '教育',
    sortNum: 1,
    devieceData: {
      img: require('@/assets/hardware/education1.png'),
      zhName: '智慧教育云节点主机',
      enName: 'HYZ-EL-9000',
      content:
        '教学云节点主机为教育行业提供的一体化智慧教室主机。该产品是基于嵌入式系统开发的新一代多功能智慧教室设备，融合分布式视音频编解码、视频矩阵、数字音频处理、数字功放、智能中控、时序电源的能力，实现教室智能化场景的统一处理。是节约空间、统一场景，高度融合、高度智能的融合一体化主机。'
    },
    introData: {
      label: '产品介绍',
      textObj: {
        text1:
          '一体化主机，同时内置多路全高清编解码器、视频混合矩阵、数字音频处理、数字功放、智能中控、时序电源等等模块，因此一般多媒体教室无需购买额外的部件、转接器、线缆和设备空间，无需厂家驻场服务，也端到端大大地降低了设备功耗和维护成本。',
        text2:
          '一体化主机同时支持RS-232、RS-485、RELAY、IO、USB、红外、功放、强电继电器、支持幻象供电的凤凰端子、3.5mm音频、DVI-I（兼容VGA、HDMI、DVI-D、YPbPr）、HDMI（1.3含音频）和网络等丰富的接口类型，轻松兼容适应各种主流视音频外设和环境外设。',
        text3:
          '一体化主机集成超强性能的DSP和视音频矩阵，单台设备可以“同时”实现多个教学应用：本地多媒体教学、远程互动教学、远程直播教学、远程巡课等等应用，针对每个应用预留足够的、独立的高清视音频处理资源，无需担心同时应用时因而存在各种复杂的限制和约束；',
        text4:
          '一体化主机支持对每路视频独立编解码，最高支持6路1080P60编码或解码，或者12路1080P30；最高支持H.264 HP Level 4.2，向下兼容老的H.264 BP和MP，支持YUV色彩空间4:4:4无损采集，实现广电级画质编解码、色彩无损还原；不仅可以实现1080P60全高清，还节省了50%的网络带宽；',
        text5:
          '一体化主机综合使用超强纠错(SEC)、丢包重传(ARQ)、视频FEC（前向纠错）3种抗丢包处理技术，在网络丢包率达20%环境下仍可保障视频流畅；',
        text6:
          '一体化主机采用智能调速（IRC）技术，根据带宽受限导致丢包的情况下，预测并动态调整视频呼叫时的码流带宽，尽量使得音视频体验达到最佳；',
        text7:
          '一体化主机具备最大可达1000ms的抗网络抖动能力，保证网络存在的延时抖动基本不影响互动呼叫的顺利进行；',
        text8:
          '一体化主机支持DiffServ(DSCP)和IP Precedence两种路由器QoS策略，保证视音频码流和网管信令等等传输更可靠；',
        text9:
          '一体化主机内置混合视频矩阵、拼接器和录播客户端，支持各种视频切换、分配、拼接、字幕叠加、图像参数调节，内置本地录播及存储功能，支持网络直播点播和推送功能，极大丰富了各种播放课件手段；',
        text10:
          '一体化主机内置多媒体分布式系统控制服务器，支持图像预览可视化控制和及分布式上屏显示，作为分布式的输入输出接口机使用；',
        text11:
          '一体化主机采用一体化单板设计，板间采用工业级连接器、板间无飞线，并非小厂家的硬件小板拼凑方案，整机MTBF达到10万小时；',
        text12:
          '采用嵌入式操作系统，提供比Windows等操作系统更高的安全性及病毒免疫能力；',
        text13:
          '通讯协议：SIP、BFCP双流、RTSP、RTMP、TCP/IP、HTTP、FTP、NTP、RTP、RTCP、Telnet；'
      }
    },
    paramsData: [
      {
        title: '产品规格',
        childrenList: [
          {
            label: '视频输入:',
            value:
              '4路SDI接口、2路DVI-I接口、1路HDMI输入接口、最大4路IP网络摄像机输入；'
          },
          { label: '视频输出:', value: '1路DVI-I、1路HDMI、1路VGA；' },
          {
            label: '音频输入:',
            value: '3路3.81mm凤凰端子输入、2路3.5mm接口输入；'
          },
          {
            label: '音频输出:',
            value: '2路3.81mm凤凰端子输出、2路100W@8欧姆的数字功放输出；'
          },
          {
            label: '控制口:',
            value: '4路RS-232/RS-485、2路RELAY、1路I/O、2路红外、2路时序电源；'
          },
          {
            label: '音频处理:',
            value:
              '自动回声消除（AEC）、自动增益控制（AGC）、自动噪声消除（ANC）、自动反馈消除（AFC）、混音矩阵（AM）、丢包补偿（PLC）；'
          },
          {
            label: '',
            value: '支持录制文件内容管理（下载/导出/编辑/删除），内置固态硬盘；'
          },
          {
            label: '',
            value: '支持U盘自动拷贝,U盘系统强制升级并恢复初始化配置；'
          },
          {
            label: '可维护性:',
            value:
              '软件升级、OLED显示信息、指示灯、红外学习接收器、最小系统、日志管理、串口调试、telnet远程调试；',
            col: 4
          }
        ]
      }
    ],
    otherData: {
      descList: [
        {
          label: '产品优势',
          textObj: {
            text1:
              '云节点主机，同时内置多路全高清编解码器、视频混合矩阵、数字音频处理、数字功放、智能中控、时序电源等等模块',
            text2:
              '云节点主机同时支持RS-232、RS-485、RELAY、IO、USB、红外、功放、强电继电器、支持幻象供电的凤凰端子、3.5mm音频、DVI-I（兼容VGA、HDMI、DVI-D、YPbPr)、HDMI（1.3含音频）和网络等丰富的接口类型，轻松兼容适应各种主流视音频外设和环境外设。'
          }
        }
      ]
    }
  },
  {
    name: 'HYZ-TV',
    module: '教育',
    sortNum: 2,
    devieceData: {
      img: require('@/assets/hardware/education2.png'),
      zhName: '音频处理器',
      enName: 'TV-650YZ',
      content:
        '音频处理器实现对音频信号的数字化输入输出处理，实现增益调节、均衡调节、降噪处理、相位转换等功能。'
    },
    introData: {
      label: '产品介绍',
      textObj: {
        text1:
          'TV-650YZ提供可配置的I/O、可配置的信号处理、但丁/AES67音频以及高带宽、容错的数字音频总线。',
        text2:
          'TV-650YZ具有开放式架构，可通过 HiQnet 完全配置™音频架构师。丰富的处理和逻辑对象调色板和"拖放"配置方法提供了简单而熟悉的设计环境。',
        text3:
          '此处理器具有但丁 / AES67 音频，具有主端和次要端口的故障容差，并通过单独的以太网端口进行控制。以及，处理器还包括支持但丁 4.0 固件与但丁域管理器集成。TV-650YZ 还具有 256 个频道的低延迟、容错数字音频总线，使用标准的 5e 类布线，使兼容设备之间的距离达到 100米。光纤介质转换器可用于将设备之间的距离增加到 40 公里以上。',
        text4:
          '四个卡槽可促进许多不同的设备 I/O 配置。每个插槽可以容纳六张可用的 I/O 卡中的任何一张，包括模拟输入卡、模拟输出卡、数字输入卡、数字输出卡、AEC 输入卡和电话混合卡。每张卡支持四个通道。',
        text5:
          '模拟输入卡在 6dB 步骤中提供软件可配置收益，每个通道高达 +48dB，每个通道提供可选软件幻影电源。数字输入卡和数字输出卡处理 AES/EBU 和/或 S/PDIF 音频，并提供各种计时和同步选项。AEC 输入卡和电话混合卡主要用于电话会议应用程序的专业处理和接口。（有关 I/O 卡的更多信息，可在专用数据表上找到）',
        text6:
          '幻影电源、同步、信号当前和剪辑信息每个通道都很容易访问，无需 PC，可从清晰的前面板 LED指示。设备特定信息，如设备名称、设备类型、固件版本编号、时间、IP 地址和子网面膜，可从前面板显示屏上获取。双向定位功能允许从 HiQnet 音频架构师中和内部识别设备。12 个控制输入和 6 个逻辑输出允许将 TV-650YZ 与 GPIO 兼容设备集成。'
      }
    },
    otherData: {
      descList: [
        {
          label: '产品优势',
          textObj: {
            text1: '四个输入/输出卡插槽',
            text2: '可配置输入/输出',
            text3: '模拟输入（每个通道具有幻影电源）',
            text4: '模拟输出',
            text5: '数字输入（AES/EBU 和 S/PDIF）',
            text6: '数字输出（AES/EBU 和 S/PDIF）',
            text7: 'AEC 输入（每个通道具有幻影电源）',
            text8: '电话混合卡',
            text9: '可配置信号处理',
            text10: '丰富的处理和逻辑对象调色板',
            text11: 'AES67 和但丁音频，支持但丁域管理器',
            text12: '256 通道，低延迟，故障容忍数字音频总线',
            text13: '清晰的前面板 LED 指示',
            text14: '信息丰富的前面板显示屏',
            text15: '双向定位功能',
            text16: 'GPIO 集成的 12 个控制输入和 6 个逻辑输出',
            text17: '声网伦敦接口套件，用于第三方控制系统集成（文档）',
            text18: '高网设备',
            text19: '来自 HiQnet 音频架构师的配置、控制和监控',
            text20: 'AES67 兼容',
            text21: 'EN 54-16 符合生命安全应用要求'
          }
        }
      ]
    }
  },
  {
    name: 'HYZ-PI',
    module: '教育',
    sortNum: 3,
    devieceData: {
      img: require('@/assets/hardware/education3.png'),
      zhName: '专业功放',
      enName: 'TS-1200PI-4',
      content:
        '专业功放实现在教室、会议室、报告厅等场景下音频信号的放大、扩音效果。设计有较好的保护电路设计和散热效果，支持连续长时间播放场景。'
    },
    specialParamsData: [
      {
        title: '输出功率：双模式 - 所有通道驱动',
        childrenList: [
          {
            label: 'CDI驱动器核心模型',
            value1: '渠道',
            value2: '2Ω',
            value3: '4Ω',
            value4: '8Ω',
            value5: '-65',
            value6: '70虚拟机',
            value7: '100Vrms'
          },
          {
            label: '2|300/ 2|300BL',
            value1: '2',
            value2: '150W',
            value3: '300W',
            value4: '300W',
            value5: '150W',
            value6: '300W',
            value7: '300W'
          },
          {
            label: '4|300/ 4|300BL',
            value1: '4',
            value2: '150W',
            value3: '300W',
            value4: '300W',
            value5: '150W',
            value6: '300W',
            value7: '300W'
          },
          {
            label: '2|600/ 2|600BL',
            value1: '2',
            value2: '300W',
            value3: '600W',
            value4: '600W',
            value5: '300W',
            value6: '600W',
            value7: '600W'
          },
          {
            label: '4|600/4|600BL',
            value1: '4',
            value2: '300W',
            value3: '600W',
            value4: '600W',
            value5: '300W',
            value6: '600W',
            value7: '600W'
          },
          {
            label: '2|1200/ 2|1200BL',
            value1: '2',
            value2: '850W',
            value3: '12106',
            value4: '12106',
            value5: '600W',
            value6: '12106',
            value7: '12106'
          },
          {
            label: '4|1200 / 4|1200BL',
            value1: '4',
            value2: '850W',
            value3: '12106',
            value4: '12106',
            value5: '600W',
            value6: '12106',
            value7: '12106'
          }
        ]
      },
      {
        title: '输出功率：桥单声道模式 - 所有通道驱动',
        childrenList: [
          {
            label: 'CDI驱动器核心模型',
            value1: '4Ω',
            value2: '8Ω',
            value3: '-65',
            value4: '140Vrms',
            value5: '200Vrms'
          },
          {
            label: '2|300/ 2|300BL',
            value1: '300W',
            value2: '600W',
            value3: '600W',
            value4: '600W',
            value5: '600W'
          },
          {
            label: '4|300/ 4|300BL',
            value1: '300W',
            value2: '600W',
            value3: '600W',
            value4: '600W',
            value5: '600W'
          },
          {
            label: '2|600/ 2|600BL',
            value1: '600W',
            value2: '12106',
            value3: '12106',
            value4: '12106',
            value5: '12106'
          },
          {
            label: '4|600/4|600BL',
            value1: '600W',
            value2: '12106',
            value3: '12106',
            value4: '12106',
            value5: '12106'
          },
          {
            label: '2|1200/ 2|1200BL',
            value1: '12106',
            value2: '2400W',
            value3: '2400W',
            value4: '2400W',
            value5: '2400W'
          },
          {
            label: '4|1200 / 4|1200BL',
            value1: '12106',
            value2: '2400W',
            value3: '2400W',
            value4: '2400W',
            value5: '2400W'
          }
        ]
      }
    ],
    otherData: {
      descList: [
        {
          label: '产品优势',
          textObj: {
            text1:
              'HYZ-1200PI-4采用我们的旗舰安装声音放大器 （TS-PI安装系列） 技术，提供简化的功能，可在几乎任何大型安装（如电影院、礼拜堂、酒店、游轮等）中工作。专为各种应用中的易用性而设计，是一款 4 通道放大器，包括网络控制/监控、高级 DSP、前面板接口以及无需变压器即可驱动高达 100Vrms 扬声器负载的支持。'
          }
        }
      ]
    }
  },
  {
    name: 'HYZ-DM',
    module: '教育',
    sortNum: 4,
    devieceData: {
      img: require('@/assets/hardware/education4.png'),
      zhName: '指向型吊麦',
      enName: 'TV-650DM',
      content:
        '指向吊麦适用于教室、会议室的吊装式拾音工作，通过指向性收音束音，抑制环境噪声，取得更好的拾音效果。'
    },
    introData: {
      label: '产品介绍',
      textObj: {
        text1: 'TV-650DM话筒使用直流11V至52V幻象供电操作。',
        text2:
          '统一的90°收音角度，能在一個狭窄的區域中提供一个良好的平衡收音。话筒安装时，应先决定最前及最后的声源位置，而话筒应瞄准两者之间的中央位置，然后提升话筒高度，使前后声源趋向平衡；但却可能会增加普景嗓音声及回声，所以调校话筒时，应避免最后的声源距离长于最前声源的两倍，以维持前后平衡。',
        text3:
          '而与其他话筒的宽度距离，应以最前声源的大致1.5倍距离为最佳设定，这样做既可覆盖每点声源，又可避免话筒的相位互相抵消情况出现。',
        text4:
          '话筒附有一条悬挂弹簧，可将收音头固定调校到任何正确位置。（把收音顺时针转动，话筒会向右转；反时针转动时，话筒会左转）',
        text5:
          '低阻抗的平衡音频输出，话筒音频信号最终以卡农公头的2号及3号针脚输出，而1号针脚则为地线（屏蔽）连接。输出相位将以正相位电平|设于2号针脚。',
        text6:
          '内置高质量高通滤波电路，颜率响应，开可由平直的频率响应，开启为于8Hz以下衰减的收音效果，应高通滤波器可减低收音环境中低频噪声，房间中的回声及机械性的震动。'
      }
    },
    paramsData: [
      {
        title: '产品规格',
        childrenList: [
          { label: '收音头:', value: '固定充电背板，' },
          { label: '静电型电容式指向特性:', value: '超指向性' },
          { label: '频率响应:', value: '30-20,000 Hz' },
          { label: '高通滤波:', value: '80 Hz,18 dB/octaye' },
          { label: '开通灵敏度:', value: '-35dB(17,7 m/)以1V于1Pa' },
          { label: '阻抗:', value: '250欧姆' },
          { label: '高最大承受声压:', value: '135dB声压级，1kH2于3%TH.D' },
          { label: '动态范围（典型）:', value: '115,1Hz于最高声压级' },
          { label: '信嗓比:', value: '74 dB,1 kHz于1Pa' },
          { label: '幻象供电:', value: '直流11-521,耗龟2mA费型平直，高通滤发' },
          { label: '开关:', value: '平直，高通滤波' },
          { label: '重量:', value: '话筒：30克 供电模组:81克' },
          { label: '外形尺寸:', value: '话简：156.0mm长，12.2mm外径' },
          {
            label: '输出端子（供电模组）:',
            value: '供电模组：92.9mm长，18.9mm外径 内置式3针卡农公少'
          },
          {
            label: '连接线:',
            value:
              '7.6m长（固定装置于收音头中），3.2mm直径连接线，2蕊线连屏蔽电线，以TA3F插头连接'
          },
          {
            label: '可转换收音头:',
            value: 'UE-C心形指向（120)  UE-H超心形指向（109） UE-0全方向性26071'
          }
        ]
      }
    ],
    otherData: {
      descList: [
        {
          label: '产品优势',
          textObj: {
            text1:
              '设计于悬挂式安装，提供高质量收音给合唱、乐团、及舞台话剧等高要求的收音应用。',
            text2: 'UniLine超指向收音头，提供窄角度90°的收音角度。',
            text3: '良好的隔音设计，在最大增益时仍能避免啸叫声的出现。',
            text4:
              'UniGuard射频干扰（RFI)屏敝技术，提供杰出的防止射频干扰能力，避免收音时受到如手提电话等的干扰。',
            text5:
              'UniSteep?高通滤波器，提供了一个高效能的高通滤波，把低频噪声作出衰减而无损话音的收音质量。',
            text6:
              '可选配适合的收音头更换配合实际的应用，收音角度可由90°至360°。',
            text7: '不显眼的设计，并涂上低反光的颜色涂料。',
            text8: '设有两种颜色选择：黑色（TV-650VM），及白色（TV-650DM）。'
          }
        }
      ]
    }
  },
  {
    name: 'HYZ-TH',
    module: '教育',
    sortNum: 5,
    devieceData: {
      img: require('@/assets/hardware/education5.png'),
      zhName: '智能中控主机',
      enName: 'HYZ-EL-9003',
      content:
        'HYZ-EL-9003集成控制器是一款可编程网络设备，专门设计用于使用多种模拟和数字格式控制 AV 和构建技术。HYZ-EL-9003结合了高性能、落后兼容性和广泛的网络安全功能，为未来提供了一个可扩展的平台。HYZ-EL-9003是中型客房和多房间应用的控制和自动化的理想之选。'
    },
    introData: {
      label: '产品介绍',
      textObj: {
        text1:
          'EL Master 安全固件非常适合企业、教育和政府政策，这些政策要求通过计算机网络进行全通信。',
        text4: '符合 IT 行业对企业运行的任何设备所期望的安全标准。',
        text2:
          'HYZ-EL-9003非常适合中型会议室或教室、演讲厅、家庭影院、酒店客房和建筑环境系统。',
        text3:
          'HYZ-EL-9003具有增强的安全功能（如 Dual NIC），为中型 AV 系统、暖通空调、灯光、安全、电源管理以及许多需要经济控制且物理端口有限的类似专业应用提供安全控制和监控。'
      }
    },
    functionData: {
      label: '产品功能',
      textObj: {
        text1:
          '广覆盖半径，可实现城市5KM,郊区15KM的广域覆盖。支持PoE供电，IEEE 802.3af/at兼容class4，48V。支持全双工上下型，最大可支持16个上行通道，2个下行发送通道。支持节点漫游，节点在网络范围内可自由移、动，支持有效过滤非法节点。具有丰富的企业网软件特性，内嵌标准的LORAWAN协议，支持WebUI配置和管理，网络路由和防火墙功能，支持MQTT Bridge。'
      }
    },
    paramsData: [
      {
        title: '产品规格',
        childrenList: [
          {
            label: '尺寸（硬件)：',
            value:
              '1 4/5" x 17" x 9 1/8" （45.15 毫米 x 431.80 毫米 x 231.64 毫米）'
          },
          { label: '重量：', value: '6.08 磅（2.758 千克）' },
          {
            label: '记忆：',
            value:
              '存储卡：8 GB SD，NVRAM：1 MB，DDRAM：512 MB，注意：支持外部USB固态硬盘'
          },
          { label: '功耗：', value: '主动功耗： 4.2 W' },
          {
            label: '链接指示器：',
            value: 'AxLink LED（绿色）表示AxLink端口的状态'
          },
          {
            label: 'RS-232/422/485 端口：',
            value:
              '10 位置 3.5 毫米螺丝终端，NetLinx 端口 1，XON/XOFF（发射/传输关闭），CTS/R（明确发送/准备发送），300 - 115，200 baud'
          },
          {
            label: 'RS-232 端口：',
            value:
              '2 位置 3.5 毫米螺丝终端，NetLinx 端口 2-4，XON/XOFF（传输开/传输关闭），CTS/RTS（明确发送/准备发送)'
          },
          {
            label: '串行指示器：',
            value: 'LED 集（红色/黄色）表示串行端口 1 - 4 何时传输和接收数据'
          },
          {
            label: 'IR/串行：',
            value:
              '2 位置 3.5mm 螺丝终端、4 IR 传输 / 单向串行端口、NetLinx 端口 11-14、支持高达 1.142 MHz 的高频载荷、可同时生成 4 个 IR/串行数据信号'
          },
          {
            label: 'IR/串行指标：',
            value: 'LED（红色）表示每个红外/串行端口（11-14）何时传输控制数据'
          },
          {
            label: 'I/O 频道：',
            value:
              '一个 6 位置 3.5 毫米螺丝终端，4 通道二进制 I/O 端口，用于接触关闭，每个输入都能够电压感应，NetLinx 端口 22，通道 1-4'
          },
          {
            label: 'I/O 指标：',
            value: 'LED（黄色）表示每个 I/O 通道 （1-4） 处于活动状态'
          },
          {
            label: '继电器：',
            value:
              '一个 8 位置 3.5 毫米螺丝终端， 单抛继电器， NetLinx 端口 21， 通道 1-4， 每个继电器可以切换到 24 VDC 或 28 VAC @ 1 A， 每个继电器是独立控制的',
            col: 2
          },
          {
            label: '继电器指标：',
            value: 'LED（红色）表示每个中继通道（1-4）何时处于活动状态（关闭）',
            col: 2
          }
        ]
      }
    ],
    otherData: {
      descList: [
        {
          label: '产品优势',
          textObj: {
            text1:
              '双 NIC – 局域网端口用于将主端连接到外部网络，ICSLAN 端口连接到与主网络隔离的第三方 A/V 设备，提供坚如磐石的安全性',
            text2:
              'IPv6–支持互联网协议版本6（IPv6），该通信协议为网络上的计算机提供身份识别和定位系统，并在互联网上传输流量',
            text3:
              'IEEE 802.1X – 支持基于端口的网络访问控制的 IEEE 标准，能够授予或拒绝希望基于与设备绑定的凭据而不是连接到用户的凭据连接到局域网的设备的网络访问权限',
            text4: 'X.509证书服务–NX支持客户提供的证书，以便访问受保护的网络',
            text5:
              '完整的LDAP集成–支持多个用户定义的登录组访问主机，并为AMX程序员提供要求网络登录以访问触摸面板某些区域的功能',
            text6:
              '高性能架构、灵活编程平台（RPM、NetLinx 和 Java） - 易于扩展，可支持今天和明天的各种应用',
            text7:
              '全线兼容（后向和交叉兼容） - 标准化端口编号和新配置进出口工具意味着更少的编码更改',
            text8:
              'TLS/SSH客户端口–为NetLinx程序员提供使用远程设备或服务器管理安全端口 TLS 和SSH 通信的能力',
            text9:
              '加密支持– 根据 FIPS 140-2，美国政府计算机安全标准用于认证加密模块以保护敏感或有价值的数据',
            text10: '网络Syslog–支持标准设备登录到syslog服务器',
            text11:
              '在串行端口和 IR 端口上增强诊断– 当串行端口和 IR 端口断开连接或有线不当时，提供实时错误反馈',
            text12:
              '文件导入/导出从USB驱动器–备份和恢复配置和程序数据，并从标准USB闪存驱动器更新固件',
            text13:
              '专为 24/7/365 操作而构建的硬件 /软件– 提供出色的可靠性和改进的诊断',
            text14: '超快 1600 MIPS 处理器',
            text15: '512 MB 机载内存',
            text16: '1 M 非挥发性内存',
            text17: '8 GB SDHC闪存',
            text18: '1 RU',
            text19: '1 个 AX 链接接口',
            text20: '1 10/100 局域网界面',
            text21: '4 数字 I/O 端口',
            text22: '1 RS232/422/485 端口',
            text23: '3 个 RS232 专用端口',
            text24: '4 个 IR/串行输出端口',
            text25: '4 个继电器端口'
          }
        }
      ]
    }
  },
  {
    name: 'HYZ-WB',
    module: '教育',
    sortNum: 6,
    devieceData: {
      img: require('@/assets/hardware/education6.png'),
      zhName: '智慧电子白板一体机',
      enName: 'HYZ-WB-9000',
      content:
        '会议平板多媒体教学一体机触控触摸显示器电子白板86英寸Windows i5/i7；为您提供前端硬件产品与应用软件、中端数据与外设对接、后端管理平台、云端算法服务等一站式智慧教室解决方案。白板一体机支持本地投屏、远程投屏以及与智能教室管理系统、录直播系统、远程视频会议系统等的高度集成'
    },
    introData: {
      label: '产品介绍',
      textObj: {
        text1:
          '智能书写白板：智能书写白板，可实现多点书写、手心手背擦除、四方投屏，手机操控平板，赋能教师授课得心应手。',
        text2:
          '多点触控技术同屏答题批注：顺滑书写体验，最大可支持20点同时触控书写，支持多人同屏手绘、批注、擦除等触摸手势。',
        text3:
          '异地教学，资源共享：老师可通过教学一体机实现云端授课，两端同步授课，解决学生异地学习需求。',
        text4:
          '互动仿真实验：集合多种仿真3D实验，模拟真实课堂，乐学乐教，有效解决课堂枯燥乏味感。',
        text5:
          '智能批注方便快捷：智能批注并将内容截图保存，可直接在内容资料中进行批注并保存，实时批注，无需抄写。',
        text6:
          '无线投屏分享互动：可将电脑、平板、手机画面实时传输至会议平板，多人同屏共享互动，分享不受“线”。'
      }
    },
    paramsData: [
      {
        title: '产品规格',
        childrenList: [
          { label: '端口参数：', value: 'USB2.0接口' },
          { label: '功耗参数：', value: '100-220v' },
          { label: '待机功率：', value: '0.5W' },
          { label: '电源功率：', value: '350W' },
          {
            label: '规格参数含底座尺寸（宽*高*厚）:',
            value: '1957mmx1176mmx137mm',
            col: 2
          },
          {
            label: '单屏尺寸（宽*高*厚）:',
            value: '1957mmx1176mmx100mm',
            col: 2
          },
          { label: '含底座重量(kg) :', value: '75' },
          { label: '外包装尺寸（宽*高*厚）:', value: '2110mmx1285mmx236mm' },
          { label: '含外包装重量(kg)：', value: '96' },
          { label: '外观设计：', value: '边框宽窄  窄' },
          { label: '屏占比：', value: '95%>N290%' },
          { label: '安装孔距：', value: '800*400' },
          { label: '边框材质:', value: '铝合金/钣金' },
          { label: '单屏重量:', value: '75kg' }
        ]
      },
      {
        title: '核心参数',
        childrenList: [
          { label: 'CPU核数：', value: '四核心' },
          { label: 'WIFI频段：', value: '2.4+5G' },
          { label: '语音控制:', value: '不支持语音功能' },
          { label: '运行内存/RAM：', value: '4G' },
          { label: '存储内存：', value: '32GB' },
          { label: '智能语音助手：', value: '不支持语音功能' },
          { label: 'GPU：', value: 'Mali G51' },
          { label: '背光方式：', value: '直下式/DLED' },
          { label: '操作系统：', value: 'Android', col: 4 }
        ]
      },
      {
        title: '主体参数',
        childrenList: [
          { label: '产品类型：', value: '教育电视：智能电视' },
          { label: '产品颜色：', value: '银色' },
          { label: '认证型号:', value: 'LE86V30TC' },
          { label: '摄像头：', value: '无摄像头' },
          { label: '是否触摸屏：', value: '触摸屏' },
          { label: '显示类型：', value: 'LED' },
          { label: '产品型号：', value: 'HYZ-' },
          { label: '音频参数：', value: '多声道功能 多声道' }
        ]
      },
      {
        title: '显示参数',
        childrenList: [
          { label: '刷屏率：', value: '60HZ' },
          { label: '支持格式（高清）：', value: '2160p' },
          { label: '屏幕尺寸:', value: '86英寸' },
          { label: '屏幕分辨率：', value: '超高清4K' },
          { label: '响应时间：', value: '6ms' },
          { label: 'HDR显示：', value: '支持' },
          { label: '屏幕比例：', value: '16:9' },
          { label: '色域标准：', value: 'NTSC' }
        ]
      }
    ]
  },
  {
    name: 'HYZ-CC',
    module: '教育',
    sortNum: 7,
    devieceData: {
      img: require('@/assets/hardware/education7.png'),
      zhName: '电子班牌',
      enName: 'HYZ-CC-9000',
      content:
        '用于显示班级信息、班级活动信息以及学校的通知信息，为学生和老师提供新颖的师生交流及校园服务平台'
    },
    paramsData: [
      {
        title: '产品参数',
        childrenList: [
          { label: '触摸屏：', value: '10点式电容IPS面板' },
          { label: '分辨率：', value: '1920*1080' },
          { label: '可视区域：', value: '257.8(H)×146mm(V)' },
          { label: '对比度：', value: '1000' },
          { label: '亮度：', value: '280cd/m2' },
          { label: '屏幕比例：', value: '16:9' },
          { label: 'Wifi：', value: '802.11b/g/n' },
          { label: '以太网：', value: '100M/1000M网口' },
          {
            label: '接口：',
            value: 'sd插槽×1，USB×2，以太网口×1，HDMI×1，3.5mm音频接口×1'
          },
          { label: '电源：', value: 'DC电源12V输入' },
          {
            label: '媒体格式：',
            value:
              'MPEG-1,MPEG-2,MPEG-4,H.263,H.264,VC1,RV etc.,支持最大到4K视频MP3/WMA/AAC etc.jpeg/png'
          },
          { label: '喇叭：', value: '2×2w' },
          { label: '工作温度：', value: '0~40℃' },
          { label: '认证：', value: '3C/ISO9001-2015' },
          {
            label: '附件：',
            value: '使用说明书x1，适配器x1，壁挂架×1，保修卡×1',
            col: 2
          }
        ]
      }
    ]
  },
  {
    name: 'HYZ-CM',
    module: '教育',
    sortNum: 8,
    devieceData: {
      img: require('@/assets/hardware/education8.png'),
      zhName: '高清跟踪抓拍摄像机',
      enName: 'HYZ-CM-9001',
      content:
        '高清跟踪摄像机用于智慧教室的录直播，与智慧教室调度系统高度集成，实现自动跟踪、场景化跟踪、多路直播、高清直播的功能。摄像机支持4K高清录播、10位色深，高速编解码，提供对清晰度、直播实时性要求等不同的场景的支持。'
    },
    otherData: {
      descList: [
        {
          label: '全高清图像：',
          textObj: {
            text1:
              '采用1/2.5英寸高品质图像传感器,镜头像素500万，分辨率可达1920x1080，输出帧率高达60帧/秒。'
          }
        },
        {
          label: '自动聚焦技术：',
          textObj: {
            text1:
              '自动聚焦算法使得镜头快速、准确、稳定地完成自动聚焦，低噪声高信噪比（基于2D﹠运动估计3D-NM数字降噪算法，信噪比>55dB），超清晰多层次画质的感受(使用支持WDR的CMOS传感器与基于人眼模式的双增宽动态技术曝光算法)'
          }
        },
        {
          label: '参数：',
          textObj: {
            text1:
              '20倍光学变焦f＝4.5～110mm（支持自动快速聚焦），54.5度广角，照度0.5Lux(F1.8, AGC ON)，支持自动/自动跟踪/一键/手动/ Indoor/ Outdoor白平衡，支持背光补偿，自动光圈 & 电子快门'
          }
        },
        {
          label: '低噪声高信噪比：',
          textObj: {
            text1:
              '低噪声CMOS有效地保证了视频的超高信噪比。采用 的2D、3D降噪技术，进一步降低了噪声，同时又能确保图像清晰度。'
          }
        },
        {
          label: '音频输入接口：',
          textObj: {
            text1:
              '支持16000、32000、44100、48000采样频率，支持AAC、MP3、PCM音频编码。'
          }
        },
        {
          label: '超级静音云台：',
          textObj: {
            text1:
              '采用高精度步进电机以及精密电机驱动控制器，确保云台低速运行平稳，并且无噪声。'
          }
        },
        {
          label: '多种视频输出接口：',
          textObj: {
            text1:
              '支持HDMI，3G-SDI，HDBaseT(可选)和有线LAN，3G-SDI支持在1080P60格式下传输100米。'
          }
        },
        {
          label: '多种音视频压缩标准：',
          textObj: {
            text1:
              '支持H.265/H.264视频压缩，支持AAC、MP3、PCM音频压缩；支持高达1920x1080分辨率60帧/秒压缩；支持2路1920x1080分辨率30帧/秒压缩；'
          }
        },
        {
          label: '多种网络协议：',
          textObj: {
            text1:
              '支持ONVIF、GB/T28181、RTSP、RTMP协议，同时支持RTMP推送模式，轻松链接流媒体服务器(Wowza、FMS)'
          }
        },
        {
          label: '多种遥控器：',
          textObj: {
            text1:
              '用户可以根据所使用的环境条件，选择红外遥控器或无线遥控器。2.4G无线遥控器不受角度、距离、红外干扰影响。支持遥控器信号透传功能，方便后端设备使用，遥控器可同时或分别控制四套（设置的遥控器地址， 可设置>=4个），程度减少设备冗余。'
          }
        },
        {
          label: '低功耗休眠功能：',
          textObj: {
            text1: '支持低功耗休眠/唤醒，休眠时功耗低于400mW。'
          }
        },
        {
          label: '像素：',
          textObj: {
            text1: '大于或等于1000电视线'
          }
        },
        {
          label: '多种控制协议：',
          textObj: {
            text1:
              '支持VISCA、PELCO-D、PELCO-P协议，支持自动识别协议。支持网络全命令VISCA控制协议。'
          }
        },
        {
          label: 'RS232级联：',
          textObj: {
            text1: '支持RS232级联，方便工程安装使用。'
          }
        },
        {
          label: '多预置位：',
          textObj: {
            text1:
              '精密步进电机驱动式宽范围、高速低噪云台(水平转动±170度, 俯仰转动-30度～+90度，控制速度水平0.1 -180°/秒、俯仰0.1-80°/秒)'
          }
        },
        {
          label: '云台参数：',
          textObj: {
            text1: '大于或等于1000电视线'
          }
        },
        {
          label: '多格式输出：',
          textObj: {
            text1:
              '全高清多格式视频输出（支持1080P60/50/30/25、1080P59.94/29.97、720P60/50、1080I60/50/95.94等多种高清视频制式，提供DVI/HDMI接口、广播级3G SDI接口——支持1080P60信号使用单根同轴电缆传输100米以上）'
          }
        },
        {
          label: '控制协议：',
          textObj: {
            text1:
              '支持多种控制协议和方式（提供RS-232C/RS485接口支持国内主流视讯终端远程控制设定以及平移/俯仰/缩放操作，支持RS-232级联，一个接口可同时支持VISCA和Pelco-D/Pelco-P协议，并支持协议自动识别），支持多地址设置及多预设位设定。'
          }
        }
      ]
    }
  },
  {
    name: 'HYZ-CM2',
    module: '教育',
    sortNum: 9,
    devieceData: {
      img: require('@/assets/hardware/education9.png'),
      zhName: '跟踪定位摄像机',
      enName: 'HYZ-EL-9005',
      content:
        '定位摄像机能智能分析场景的变化，根据场景的需求，触发智能化自动录播调度，实现智慧教室的无感调度功能。'
    },
    paramsData: [
      {
        title: '产品规格',
        childrenList: [
          { label: '电源：', value: 'DC 12V 1A' },
          {
            label: '网口:',
            value: '1路千兆网口，可通过网口传输跟踪控制和导播命令'
          },
          { label: 'HD-SDI：', value: '输出1080p@30画面' },
          { label: '网络协议:', value: 'Rtsp' },
          {
            label: 'COM1：',
            value: 'RS232云台摄像机控制接口，通过串口连接云台摄像机',
            col: 2
          },
          {
            label: 'COM2:',
            value: 'RS232录播通信接口，可传输导播切换命令至录播主机',
            col: 2
          }
        ]
      }
    ],
    otherData: {
      descList: [
        {
          label: '老师跟踪模块',
          textObj: {
            text1:
              '无需跟踪主机，无需辅助摄像头、LIUNX架构，智能前置化，跟踪算法内嵌至全景摄像机；',
            text2:
              '系统使用1080P@30fps高清信号作为分析画面，可以覆盖教室所有可视范围，在同等环境下，跟踪效果更加准确，避免因距离等原因造成无法跟踪的现象；',
            text3: '特写模式下，系统保持在教学全程给予老师特写拍摄画面；',
            text4:
              '稳定跟踪模式下，教师移动时拍摄讲台全景画面，教师小范围移动或站立不动时给予特写拍摄画面；',
            text5:
              '全景切换模式下，无需云台摄像机参与，可预设教师中景，当教师移动时，系统立即切换教师中景画面，避免出现镜头晃动等效果。',
            text6:
              '系统内置VGA信号分析算法，无需在教师电脑上安装任何插件及设备，系统自动分析教师电脑画面变化幅度，并自动完成VGA信号导播切换。',
            text7:
              '系统具备自适应跟踪算法，可根据目标运动规律自动调整摄像机跟踪速度，避免以固定速度跟踪不同目标造成的画面丢失、跟踪延迟等问题。',
            text8:
              '教师区可设置多个屏蔽区域，屏蔽区域形状可自定义调整，不受教学多媒体设备（投影、电子白板、一体机）、光线、背景、窗帘、温度、湿度等因素干扰。',
            text9:
              '支持教师身高自适应，不同身高、不同姿式均能自动调整拍摄角度，实现最佳输出画面。',
            text10:
              '状态保持机制，通过设置现有状态保护时长，避免动作过快而导致全景与特写频繁切换，减少垃圾镜头。'
          }
        },
        {
          label: '学生跟踪模块',
          textObj: {
            text1:
              '无需跟踪主机，无需辅助摄像头、LIUNX架构，智能前置化，跟踪算法内嵌至全景摄像机；',
            text2:
              '系统使用1080P@30fps高清信号作为分析画面，可以覆盖教室所有可视范围，在同等环境下，跟踪效果更加准确，避免因距离等原因造成无法跟踪的现象；',
            text3:
              '特写模式下，系统自动识别学生起立动作，并根据预设策略给予学生特写拍摄画面；',
            text4:
              '稳定跟踪模式下，当系统识别到学生站立动作时，系统先切换学生全景，待特写摄像机完成跟踪并稳定画面后给予特写拍摄画面。',
            text5:
              '全景切换模式下，无需云台摄像机参与，可预设学生全景，当学生站立时，系统立即切换教师中景画面，避免出现镜头晃动等效果。',
            text6:
              '系统具备自适应跟踪算法，可根据目标运动规律自动调整摄像机跟踪速度，避免以固定速度跟踪不同目标造成的画面丢失、跟踪延迟等问题。',
            text7:
              '学生区可设置多个屏蔽区域，屏蔽区域形状可自定义调整，不受学生小幅度动作（举手等）、灯光、窗户、温度、湿度等因素干扰。',
            text8:
              '支持多人起立，依次特写后给到多人的中景画面，最多可支持三人同时起立。',
            text9:
              '状态保持机制，通过设置现有状态保护时长，避免动作过快而导致全景与特写频繁切换，减少垃圾镜头。'
          }
        }
      ]
    }
  }
];

// 软件产品模块数据
export const SOFTWARES = [
  {
    name: 'utoss',
    module: '管廊',
    sortNum: 1,
    devieceData: {
      img: require('@/assets/software/utoss.png'),
      zhName: '综合管廊运维支撑系统',
      enName: 'TURBO-UTOSS',
      content:
        '捍御者是国内领先的管廊运维支撑管理软件和解决方案提供商，在管廊运维和运营支撑领域，具备强大的技术储备和业务储备，拥有丰富的服务经验。该产品针对管廊的特点定制开发，是一款管廊一体化支撑专用软件，对管廊环境监控、智能运维、日常巡更、集中监控、故障处理、应急通信、安全防范、人员定位等具备完整的功能支撑。'
    },
    otherData: {
      descList: [
        {
          label: '优势特点',
          textObj: {
            text1:
              '产品使用SPRING CLOUD架构，跨平台、易实施、易集成，分布式体系结构，根据企业信息化环境，部署方式可采用普通模式 、 分布式采集机（PROBE）和分布式服务器（SERVER）部署以及服务器级联模式等多种部署方案。',
            text2:
              '丰富的监控能力：支持管廊内部温度、湿度、甲烷、硫化氢等各种传感器指标监控，支持风机、水泵等各种设备状态接入监控。需求快速响应：采用微服务架构，新需求迭代开发简单方便，能快速响应。多元化集成方式：能够与国内外其它第三方产品深度集成，实现统一的数据处理和展现。'
          }
        }
      ]
    }
  },
  {
    name: 'app',
    module: '管廊',
    sortNum: 2,
    devieceData: {
      img: require('@/assets/software/app.png'),
      zhName: '手机客户端软件',
      enName: 'TURBO-APP',
      content:
        '捍御者手机客户端软件，采用H5技术开发，适用于所有智能手机及其他终端，为巡更人员及来访人员提供身份认证、巡更任务管理、巡更人员管理、IP电话、视频管理、即时通信等功能。'
    },
    otherData: {
      descList: [
        {
          label: '优势特点',
          textObj: {
            text1:
              '入廊管控：提供入廊申请、审批功能，方便查看廊内环境信息，比如温度、有害气体含量等，与后台联动，自动打开门禁系统。在线巡更：后台设置巡更路径，在线实时监控巡更人员，通过高精度定位确保巡更人员到达指定巡更点，支持巡更轨迹回放。',
            text2:
              '终端控制：与后台联动，实现对水泵、风机、门禁、电子井盖等自动控制。跨平台：采用H5开发，跨平台支持安卓、IOS等系统。易迭代：采用MVVM模式开发，低耦合、可重用、独立开发，能快速响应需求。'
          }
        }
      ]
    }
  },
  {
    name: 'pipe',
    module: '管廊',
    sortNum: 3,
    devieceData: {
      img: require('@/assets/software/pipe.png'),
      zhName: '综合管廊监控管理平台',
      enName: 'TURBO-UTOSS',
      content:
        'TURBO-UTOSS软件，是捍御者公司的综合管廊监控管理的专业软件平台，用于地下综合管廊的的智能化统一管理。产品可单独用于一条智慧管廊项目，实现统一监控、监测、安防、通信、运维；亦可同时用于多条管廊的统一运维管理，如城市内所有管廊或者业主管理下的全国所有管廊项目。'
    },
    otherData: {
      descList: [
        {
          label: '产品描述',
          textObj: {
            text1:
              '服务器软件（TURBO-Server）为监控中心提供后台支撑，实现接入服务、数据通信服务、数据加工处理服务、系统管理服务，支持手机APP和前台展现。探针软件（TURBO-Probe ），嵌入式系统，针对管廊的传感器、摄像头、风机、水泵等终端设备信息及其他指标采集， 对风机、水泵、门禁等远程控制。',
            text2:
              '控制台软件（TURBO-Console ）为监控中心提供统一监控服务，实现告警处理、环境监控、作业计划、视频回放、即时通信、巡更任务、管廊终端设备控制和重要界面、指标定制化呈现的监控大屏。软件分为控制台软件（TURBO-Console ）、服务器软件（TURBO-Server）、探针软件（TURBO-Probe）'
          }
        },
        {
          label: '产品特性',
          textObj: {
            text1:
              '通过vuex页面实现单数据流开发，便于掌控页面状态的变化。SPA页面架构，提供良好的用户体验，内容的改变不需要重新加载整个页面。灵活部署：产品使用 spring cloud 架构，跨平台、易实施、易集成，分布 式体系结构快速需求响应：采用微服务架构，对新需求的修改、迭代开发简单方便，能 快速响应',
            text2:
              '前后端分离开发，通过REST接口完全实现松耦合。 支持Virtual Dom，提供性能上开发优化。系统管理：支持菜单和数据的权限分组，角色、用户、部门管理，用户账号进行人脸识别采集。前端采用渐进式框架 VUE 开发，提供了响应式（Reactive）和组件化 （Composable）的视图',
            text3:
              '高效虚拟化：Docker 容器内核级的虚拟化，运行不需要hypervisor 额外支持，实现更高的性能和效率。兼容性：Docker 容器可以在任意的平台上运行，包括物理机、虚拟机、 公有云、私有云、个人电脑、服务器等，方便平台级迁移',
            text4:
              '软件自管理：远程云化一键式升级维护，提供相关类型字典配置、告警/资源等字典表和设备指令配置，方便系统扩展和个性化修改'
          }
        },
        {
          label: '产品功能',
          textObj: {
            text1:
              '概况监控：基于GIS地图实现对管廊区域整体监控呈现、快速定位和基于地理位置的告警信息呈现。告警处理：提供告警流水窗口、历史告警，提供告警标准化、告警过滤、告警联合分析、告警通知和告警工单派发等告警处理规则，实现办公工单、告警工单派发，电子化流程实现管廊各类工作工单的有效流转，完成工作任务闭环处理',
            text2:
              '资源信息：提供管廊的基础数据、管线资料、地理信息，管廊环境监控、安防监控可基于这些资源信息，实现系统的智能化联动。即时通信：实现管廊配备各区间人员、现场人员与监控中心之间保持通信与信息同步，确保前端巡检人员信息及时上报，监控中心命令及时下达环境监控：通过统一设备面板查看舱内拓扑、设备状态、环境传感器的读数，可进行设备控制和环境参数的趋势分析，提供廊内设备电子履历卡',
            text3:
              '廊内广播：控制全廊/区域的实时或录音播放广播    安防监控：授权人员身份识别、定位和防身份冒充、发现多点入侵；识别人员入侵和正常作业的视频记录和轨迹记录任务工作计划：实现按入廊申请及审批后进行手环（身份卡）分配，可以合法在指定时间段进入申请区域，可复核身份；报告巡更、巡检、维护工作的每日执行情况，模板可灵活定制和调度'
          }
        },
        {
          label: '软件环境',
          textObj: {
            text1:
              '数据库、中间件：Oracle/Mysql、spring boot。操作系统：Linux操作系统。安装环境：应用服务器1台， 8核32G内存1T硬盘；DB服务器1台，8核32G内存2T硬盘；视频播放服务器1台：8核32G内存8T硬盘，带独立显卡'
          }
        }
      ]
    }
  },
  {
    name: 'store',
    module: '仓储',
    sortNum: 1,
    devieceData: {
      img: require('@/assets/software/store.png'),
      zhName: '智能仓储管理系统',
      enName: 'TURBO-SYSTEM',
      content:
        '捍御者智能仓储管理系统，使用RFID电子标签技术，通过对货物入库、出库、移库、维护保养等日常作业流程监控以及货物流转过程智能监控，实现货物全生命周期管理；通过盘点机器人对货物进行自动盘点，生成盘点报表；通过门禁探头全天候监控货物出入情况，自动甄别货物出入是否为非法，并生成告警；使用规则引擎、流程引擎和计划引擎，对订单进行自动拆单、合单；监控货物流转执行情况，对耗时超长环节进行提示和告警。'
    },
    otherData: {
      descList: [
        {
          label: '优势特点',
          textObj: {
            text1:
              '自动盘点：通过机器人对货物进行自动盘点，无需人工干预，自动生成盘点报表，统计货物盘盈、盘亏、错位存放信息。出入库监测：通过探头对货物出入门禁进行自动监测，智能甄别货物是否非法出入库房，对货物非法出入库进行声光告警。',
            text2:
              '自动盘点：应用业务规则引擎、计划引擎技术，系统自动拆单、合单，自动分配货位；将业务逻辑从硬代码中解耦，业务人员通过可视化界面对业务规则进行优化调整，无需修改和升级程序。货物全生命周期管理：通过使用门禁、手持终端、盘点机器人等技术手段，对货物入库、移库、出库等进行全程自动监控，实现对货物流转全生命周期管理。'
          }
        }
      ]
    }
  },
  {
    name: 'ivmp',
    module: '视频',
    sortNum: 1,
    devieceData: {
      img: require('@/assets/software/ivmp.png'),
      zhName: '视频管理平台',
      enName: 'TURBO-IVMP',
      content:
        '捍御者视频管理平台TURBO-IVMP与业界的软件平台产品相比，特点鲜明，差异化明显，系统采用分布式架构，可支持前端摄像头数量的无限水平扩展。平台支持多种先进的视频流协议，可支持多视频源和多视频服务请求的高密度并发，大幅度降低视频传输/存储容量，独特的算法可实现多种人体动作的智能识别。'
    },
    otherData: {
      descList: [
        {
          label: '产品描述',
          textObj: {
            text1:
              'URBO-IVMP软件平台主要用于园区、楼宇、管廊、地铁、高速、隧道及其它场景的视频和图片的实时数据/历史数据的分析、处理，具备前端网络摄像头数据采集、NVR服务和流媒体管理能力，支持视频监控、显示屏轮询、视频整体管理、流媒体服务和视频智能分析等功能。',
            text2:
              '从智能建筑的安防监控、车位引导、水牌、电梯间视频、大屏监控，到地铁与高速公路的安防监控、信息屏、人脸识别与车辆识别，再到综合管廊的安防监控、身份识别，涉及到城市基础设施智能化系统视频采集、分析、管理的功能，均可采用捍御者自主研发的视频管理与分析的平台软件TURBO-IVMP 。'
          }
        },
        {
          label: '产品特性',
          textObj: {
            text1:
              '便捷易用：支持云台控制、焦距缩放、全网页面配置。基于H5的浏览器播放，无需安装任何插件，减少资源消耗。Web页面观看监控画面、历史回放，平台基于Docker容器安装配置，一键式安装升级。多媒体呈现多样：视频管理平台信息发布模块支持HTML5和Flash插件，具备支持播放PPT和PDF文件插件，多媒体文件格式更加丰富，支持面向拼缝大屏、电梯屏、信息发布屏、电子水牌的多媒体播放',
            text2:
              '存储空间大幅降低：采用H.265编解码，视频存储轻量化改进，使后台视频存储量降低至传统方案的50%以上。场景识别多样：基于神经网络“人体骨架”算法实现人体动作识别，用于实时视频分析，将数据处理量压缩到传统方案的百分之一，可实现区域监控、人数统计、人群聚集、人员摔倒、斗殴、电梯逆行、可疑物品遗留等场景识别和人员动作识别',
            text3:
              '协议先进：支持RTMP、RTSP、Flash Video，RTMP协议比传统方案使用的RTSP协议占用带宽更低、更稳定，尤其是视频信息发布场景优势显著。其中，FLV视频文件所需存储只占传统方案的1/15～1/3，一部1.4GB的电影使用FLV视频，只需要100MB'
          }
        },
        {
          label: '产品功能',
          textObj: {
            text1:
              '自有GPU服务器，通过GPU集群管理，消除了计算性能瓶颈，提升数据处理效率。支持FLV、RTSP、RTMP等多种视频流协议，可基于Web、Android、IOS、微信小程序的流媒体播放。支持NVR、网络IP摄像头等不同类型前端视频流源的分区管理，自由选择视频流的管理、推流、轮播、上墙功能。',
            text2:
              '支持远程抓拍和本地抓拍，支持实时视频流的18类场景分析，场景事件触发式推流和回放，异常场景及时告警及智能定位。支持视频的剪辑、导出、添加书签等等功能；支持视频数据的分布式存储管理，可根据书签、时间、事件、区域等信息快速查找历史视频。',
            text3:
              '统一的视频源管理，统一的视频流管理，统一的媒体文件管理，统一推流、检索、回放服务，统一的视频流/图片智能分析，统一的权限控制。'
          }
        },
        {
          label: '软件环境',
          textObj: {
            text1:
              '数据库、中间件：Oracle/Mysql、spring boot。操作系统：Linux操作系统。安装环境：GPU服务器1台， 16核64G内存1T硬盘；通用服务器1台(8核64G内存2T硬盘）+磁盘阵列或存储服务器1台；存储容量根据需求确定'
          }
        }
      ]
    }
  },
  {
    name: 'building',
    module: '建筑',
    sortNum: 1,
    devieceData: {
      img: require('@/assets/software/building.png'),
      zhName: '智能建筑管理平台',
      enName: 'TURBO-BOSS',
      content:
        '软件定义建筑 Software Define Building，弱化前端设备功能，减少网络层次，统一通信标准，集中部署软件平台，强化软件能力，基于统一的软件建模，定义建筑智能化中的相关组件，以面向对象的编程思想统一实现各项建筑智能化的功能。'
    },
    otherData: {
      descList: [
        {
          label: '产品描述',
          textObj: {
            text1:
              'Turbo-BOSS智能建筑管理平台是捍御者公司的智能建筑管理的专业软件平台，用于智能建筑的智能化统一管理。Turbo-BOSS部署在捍御者自主研发的服务器群组中，用于实现对智能建筑的统一监控、维护、自动化控制，涵盖了传统方案中的BAS、VAV、出入口控制、智能照明、智能抄表、网络管理、自监控等子系统的上层软件功能，内置了数据采集平台、资源管理平台、告警管理平台、自动化控制平台、综合呈现平台、统计分析平台、信息发布平台、工作协同平台、运维管理APP、客户用微信小程序。',
            text2:
              'Turbo-BOSS引入微服务和容器技术，将建筑智能化不同场景中的各个功能封装成多个相对独立运行的软件容器，以微服务的方式将相应的功能交付给用户使用。将传统方案中硬件实现的功能和业务逻辑，均通过容器以建筑功能虚拟化（BFV）的方式来实现，引入负载均衡和高可用技术以及Zookeeper技术，搭建统一的Server Pool实现系统的高健壮性及一致性服务，实现建筑管理的“All-Time Driving”。'
          }
        },
        {
          label: '产品特性',
          textObj: {
            text1:
              '“统一呈现平台”：作为基础支撑平台的最上层，基于组件化定制，便于使用者在任何时间、任何地点均可以无缝接入、感知、使用智能建筑管理平台， 平台分为：二维展现界面，三维立体呈现，手机客户端，小程序。“统一告警处理平台”：告警平台实现对智能化设备、网络设备统一监控、运维人员统一管控、分控中心统一接入、运营对象统一管理，实现了建筑智能化不同需求的分视图的可视化告警管理。',
            text2:
              '“统一自动化控制平台”：采用一视图、多拓扑结构实现对建筑环境、人员、安防、联动控制的呈现，对设备性能、故障、通断性等采取全网物联、联动执行实现自动化控制。“统一协同工作”：统一流程引擎式开发，通过抽象的表单引擎、视图引擎、流程引擎、规则引擎、报表引擎，用可视化的形式进行设置组合，实现对象抽象、物资闭环、数据闭环、业务闭环、管理闭环的流程化管理。',
            text3:
              '统一权限管理”：引入统一权限体系完成给数据、模型、管理、人员的权限的融合管理，提供涵盖应用运行、运维、运营的不同权限管理支撑，不同使用人都可以采用统一的规范接入到智能建筑管理平台。'
          }
        },
        {
          label: '产品功能',
          textObj: {
            text1:
              '“统一资源管理”：通过统一的资源建模方式，把智能建筑空间的各个专业的资源进行统一。在资源统一基础上，可以实现资源间数据共享和数据的统一分析，再通过日常各个专业数据的搜集分析结果进一步优化相关算法，可以实现资源配置细致化管控和调节，为建筑的绿色节能提供数据支撑。',
            text2:
              '“统一信息发布”：将信息发布的各种显示屏幕的对接结构和接口方式标准化，标准化接入拼缝大屏、电梯屏、信息发布屏、电子水牌、引导屏等信息发布媒介，内置多媒体播放软件，将信息发布方式统一化。“统一数据采集”：基于SDB（软件定义建筑）和BFV（建筑功能虚拟化）的理念，在软件层面融合多种通讯方式和协议规约，通过自有的分布式采集及控制器设备对接多种线缆接线方式，统一接入采集数据。',
            text3:
              '“手机端APP”：实现智能建筑的物业管理、设备管理、事件管理等工作任务电子化，尤其是对针对建筑设备的巡检维护工作实现了移动终端化。“微信小程序”：通过微信小程序及卡包程序，将访客出入、车位查找、路径引导、物业及停车费用缴纳、联系客服中心等功能便捷化、智能化。'
          }
        },
        {
          label: '软件环境',
          textObj: {
            text1:
              '数据库：MYSQL-E5。操作系统：企业版Linux操作系统。 安装环境：TURBO-BC服务器4台，弹性负载均衡'
          }
        }
      ]
    }
  }
];
